import { SearchRfqAccessorial, SearchAccessorial, SaveableAddressNewUi, AddressAccessorial } from '@/app/model'
import { UuidV4 } from '@lazr/types'
import {
    AccessorialType,
    TransportType,
    AccessorialLocationType,
    AddressType,
} from '@lazr/openapi-client'
import { AddressAttributes } from '@/app/model/Address'
import { CompleteOrderInformation } from '@/app/ui-new/definitions/MarketPlace'
import { addDefaultAccessorials, isCountryInCaUSPrVi } from '@lazr/utilities'

export interface SearchAccessorialCard extends SearchAccessorial {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
    group?: 'Main' | 'Appointment' | 'SpecialHandling'
}

export interface AccessorialCard extends SearchRfqAccessorial {
    selected?: boolean
    isMostPopular?: boolean
}

export const isAddressComplete = (address: AddressAttributes | SaveableAddressNewUi): boolean => {
    let countryCode
    if(address?.country?.hasOwnProperty('code')) {
        countryCode = (address as SaveableAddressNewUi).country?.code
    } else {
        countryCode = (address as AddressAttributes).country
    }
    const isStateRequired = isCountryInCaUSPrVi(countryCode)
    if (
        !address.country
        || !address.city
        || (isStateRequired ? !address.state : false)
        // || !address.postalCode
        || !address.addressType
        || !address.companyName
    ) {
        return false
    }
    if (address.isBilling && (!address.billingContactName 
        // || !address.billingContactPhone
        //  || !address.billingContactEmails
    ))
    {
        return false
    }

    return !(address.isShipping && (!address.shippingContactName         
        || !address.openTime
        || !address.closeTime
        // || !address.shippingContactPhone 
        // || !address.shippingContactEmails
    ))
}

export const getAddressBookAccessorials =
    (address: Partial<CompleteOrderInformation>,
        originAccessorialList: SearchRfqAccessorial[],
        destinationAccessorialList: SearchRfqAccessorial[]): AddressAccessorial[] => {
        const addressType = (address.addressType ?? address.addressBookAddress?.addressType) ?? AddressType.BUSINESS
        const addressBookOriginAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.ORIGIN) ?? []
        const addressBookDestinationAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? []
        const addressBookAnyAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.ANY) ?? []

        const newOriginAcessorials = addDefaultAccessorials(addressType, addressBookOriginAccessorial, originAccessorialList)
        const newDestinationAcessorials = addDefaultAccessorials(addressType, addressBookDestinationAccessorial, destinationAccessorialList)

        return newOriginAcessorials.concat(newDestinationAcessorials).concat(addressBookAnyAccessorial)
    }
