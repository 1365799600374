/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BillingChargeType {
    ACC = 'ACC',
    ADDCHRG = 'ADDCHRG',
    ADDCOR = 'ADDCOR',
    AFTRHR = 'AFTRHR',
    AIS = 'AIS',
    ASWGHT = 'ASWGHT',
    BCROADCLOSURE = 'BCROADCLOSURE',
    BORDCROSS = 'BORDCROSS',
    BRK = 'BRK',
    BRS = 'BRS',
    BYC = 'BYC',
    CAPACITY = 'CAPACITY',
    CARBFEE = 'CARBFEE',
    CDW = 'CDW',
    CHAINS = 'CHNC',
    CNV = 'CNV',
    COMMINVRMV = 'COMMINVRMV',
    CON = 'CON',
    CORRECTEDBOLFEE = 'CORRECTEDBOLFEE',
    CRANE = 'CRNC',
    CRBNTRL = 'CRBNTRL',
    CUSTINSP = 'CUSTINSP',
    DETENTION = 'DETENTION',
    DDUOVSD = 'DDUOVSD',
    DEF = 'DEF',
    DELC = 'DELC',
    DELCONF = 'DELCONF',
    DIMWGHT = 'DIMWGHT',
    DOCFEE = 'DOCFEE',
    DRYICE = 'DRYICE',
    DSC = 'DSC',
    ELS = 'ELS',
    EXLIAB = 'EXLIAB',
    EXA = 'EXA',
    EXPEDITE = 'EXPEDITE',
    EXPLIC = 'EXPLIC',
    EXTRACARE = 'XCARE',
    FERRY = 'FERRY',
    FREIGHT = 'GFC',
    FUEL_SURCHARGE = 'FSC',
    GOV = 'GOV',
    GS10_30 = 'GS10_30',
    GST = 'GST',
    HCD = 'HCD',
    HCP = 'HCP',
    HCSER = 'HCSER',
    HDAYPU = 'HDAYPU',
    HMLAND = 'HMLAND',
    HST = 'HST',
    INT = 'INT',
    ISC = 'ISC',
    KHS = 'KHS',
    ITEM = 'ITEM',
    LABEL = 'LABEL',
    LAZR_COVERAGE = 'LZRC',
    LCDC = 'LCDC',
    LGSER = 'LGSER',
    LH = 'LH',
    LRGPCK = 'LRGPCK',
    LTDACC = 'LTDACC',
    LZFEE = 'LZFEE',
    LZSHARE = 'LZSHARE',
    MIN = 'MIN',
    NAVCN = 'NAVCN',
    NCNTGST = 'NCNTGST',
    NFC = 'NFC',
    NONCONV = 'NONCONV',
    NOSTACK = 'NOSTACK',
    MARINE = 'MARINE',
    OFSC = 'OFSC',
    OTHER = 'OTHER',
    OVSP = 'OVSP',
    PAPERCOMMINV = 'PAPERCOMMINV',
    PFS = 'PFS',
    PNMAIL = 'PNMAIL',
    PRIVATEHOUSE = 'PRIVATEHOUSE',
    PRLBL = 'PRLBL',
    PSTQST = 'PSTQST',
    PUC = 'PUC',
    REDELIVERY = 'REDELIVERY',
    REG = 'REG',
    RIDCIP = 'RIDCIP',
    RRCOIP = 'RRCOIP',
    RTNSERV = 'RTNSERV',
    RURAL = 'RURAL',
    SAADJ = 'SAADJ',
    SATINTL = 'SATINTL',
    SDC = 'SDC',
    SHIPCONF = 'SHIPCONF',
    SSBS = 'SSBS',
    STORAGE = 'STORAGE',
    TAILINT = 'TAILINT',
    TARP = 'TRPC',
    TAX = 'TAX',
    TOLL = 'TOLL',
    TOTALACC = 'TOTALACC',
    TSD = 'TSD',
    UPSSEDF = 'UPSSEDF',
    WGHT = 'WGHT',
    WAIT = 'WAIT',
    WEIGHT_INSPECTION = 'WEIGHT_INSPECTION',
    SCCO = 'SCCO',
    INSPECTION_FEE = 'INSPECTION_FEE',
    REWEIGH = 'REWEIGH',
    EXRFEE = 'EXRFEE',
    ETD = 'ETD',
    PGADIS = 'PGADIS',
    ENTPREP = 'ENTPREP',
    BONDFEE = 'BONDFEE',
    MERPROFEE = 'MERPROFEE',
    ATTPUFEE = 'ATTPUFEE',
    FIR = 'FIR',
    FTR = 'FTR',
    GEOGRAPHIC_SURCHARGE = 'GEOGRAPHIC_SURCHARGE',
    EXSHIP = 'EXSHIP',
    STASER = 'STASER',
    THREEDS = 'THREEDS',
    WEXSA = 'WEXSA',
    SUSUR = 'SUSUR',
    CIPS = 'CIPS',
    RITOS = 'RITOS',
    MISIN = 'MISIN',
    DUTY = 'DUTY',
    WAHOFEES = 'WAHOFEES',
    DSBFEE = 'DSBFEE',
    VAT = 'VAT',
    STDEL = 'STDEL',
    ANCILLARY_FEE = 'ANCILLARY_FEE',
}