import * as types from '@/app/ui-new/redux/constants'
import { AccessorialLocationType, AccessorialType, PaymentTypeOption, UuidV4 } from '@lazr/openapi-client'
import { AnyAction, Reducer } from 'redux'
import { PreparedSearch } from '@/app/model/PreparedSearch'
import { ValidatedAddress } from '@/app/ui-new/definitions/ValidatedAddress'
import { initialMarketPlace } from '@/app/ui-new/redux/store/InitialState'

import {
    MarketPlace,
    MarketPlaceStep,
    MarketPlaceErrors,
    InvalidationDialog,
    Collect,
} from '@/app/ui-new/definitions/MarketPlace'

import {
    Address,
    PackageType,
    Search,
    SearchRfq,
    SearchRfqAccessorial,
    CoverageCommodityType,
    Lane, SearchRfqHandlingUnit, SearchRfqCustomsDetail, Country,
} from '@/app/model'
import { AddressSearchOption } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar'


const reducer: Reducer = (state: MarketPlace = initialMarketPlace, action: AnyAction) => marketPlace({
    ...state,
    fillMarketPlaceTrigger: setFillMarketPlaceTrigger(state, action),
    order: orderInformation(state.order, action),
    handlingUnitAccessorialList: handlingUnitAccessorialList(state.handlingUnitAccessorialList, action),
    originAccessorialList: accessorialList(state.originAccessorialList, action, AccessorialLocationType.ORIGIN),
    destinationAccessorialList: accessorialList(state.destinationAccessorialList, action, AccessorialLocationType.DESTINATION),
    serviceAccessorialList: serviceAccessorialList(state.serviceAccessorialList, action),
    packageTypeList: packageTypeList(state.packageTypeList, action),
    coverageCommodityTypeList: coverageCommodityTypeList(state.coverageCommodityTypeList, action),
    originFullAddress: placesAutocomplete(state.originFullAddress, action, 'originFullAddress'),
    destinationFullAddress: placesAutocomplete(state.destinationFullAddress, action, 'destinationFullAddress'),
    dutyBillFullAddress: placesAutocomplete(state.dutyBillFullAddress, action, 'dutyBillFullAddress'),
    customsBrokerFullAddress: placesAutocomplete(state.customsBrokerFullAddress, action, 'customsBrokerFullAddress'),
    lastCompletedStep: marketPlaceLastCompletedStep(state.lastCompletedStep, action),
    currentStep: marketPlaceStep(state.currentStep, action),
    isCoverageExclusionsAccepted: isCoverageExclusionsAccepted(state.isCoverageExclusionsAccepted, action),
    isQuoteFetchingComplete: quoteFetchingComplete(state.isQuoteFetchingComplete, action),
    quoteFetchingResultsCompleted: quoteFetchingResultsCompleted(state.quoteFetchingResultsCompleted, action),
    quoteFetchingSearchProgress: quoteFetchingSearchProgress(state.quoteFetchingSearchProgress, action),
    marketPlaceErrors: marketPlaceErrors(state.marketPlaceErrors, action),
    invalidationDialog: invalidationDialog(state.invalidationDialog, action),
    promptModalLeave: setPromptModalLeave(state.promptModalLeave, action),
    originAddressBookAddress: originAddressBookAddress(state.originAddressBookAddress, action),
    destinationAddressBookAddress: destinationAddressBookAddress(state.destinationAddressBookAddress, action),
    originSaveToAddressBook: setOriginSaveToAddressBook(state.originSaveToAddressBook, action),
    destinationSaveToAddressBook: setDestinationSaveToAddressBook(state.destinationSaveToAddressBook, action),
    selectedValidatedOriginAddress: setOriginAddressValidated(state.selectedValidatedOriginAddress, action),
    selectedValidatedDestinationAddress: setDestinationAddressValidated(state.selectedValidatedDestinationAddress, action),
    openReasonsModal: toggleReasonsModal(state.openReasonsModal, action),
    openSameDayGroundShipmentModal: toggleSameDayGroundShipmentModal(state.openSameDayGroundShipmentModal, action),
    openWarningGroundShipmentModal: setToggleWarningdShipmentModal(state.openWarningGroundShipmentModal, action),
    paymentType: paymentType(state.paymentType, action),
    collect: collect(state.collect, action),
    userLanes: userLanes(state.userLanes, action),
    selectedLaneId: selectedLane(state.selectedLaneId, action),
    rescheduledPickupDate: rescheduledPickupDate(state.rescheduledPickupDate, action),
    countryList: countryList(state.countryList, action),
    promptUnsavedChangesDialog: setPromptUnsavedChangesDialog(state.promptUnsavedChangesDialog, action),
}, action)

const marketPlace = (state: MarketPlace, action: AnyAction): MarketPlace => {
    switch (action.type) {
        case types.SET_MARKET_PLACE:
            return {
                ...state,
                ...action.payload.marketPlace,
            }
        case types.PRELOAD_MARKET_PLACE:
            return {
                ...state,
                isPrepared: true,
            }
        case types.SET_PRICE_RESET_TRIGGERED:
            return {
                ...state,
                priceResetTriggered: action.payload.priceResetTriggered,
            }
        default:
            return state
    }
}

const setFillMarketPlaceTrigger = (state: MarketPlace, action: AnyAction): boolean | null => {
    if (action.type === types.FILL_MARKETPLACE_TRIGGER) {
        return action.payload
    }

    return state.fillMarketPlaceTrigger
}

const orderInformation = (state: Partial<Search>, action: AnyAction): Partial<Search> => {
    if (state.rfq) {
        state.rfq = rfq(state.rfq, action)
    }
    switch (action.type) {
        case types.SET_MARKET_PLACE_ORDER_INFORMATION:
            return {
                ...state,
                ...action.payload.order,
                rfq: {
                    ...state.rfq,
                    ...action.payload.order.rfq,
                },
            }
        case types.SET_MARKET_PLACE_BILLING_CURRENCY:
            return {
                ...state,
                billingCurrency: action.payload,
            }
        case types.PRELOAD_MARKET_PLACE:
            return {
                ...state,
                ...(action.payload.preparedSearch as PreparedSearch).toSearch(),
            }
        default:
            return state
    }
}

const rfq = (state: Partial<SearchRfq>, action: AnyAction): Partial<SearchRfq> | undefined => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_TRANSPORT_INFORMATION:
            return {
                ...state,
                ...action.payload.rfq,
            }
        case types.SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE:
            return {
                ...state,
                isBookedWithCoverage:  action.payload.isBookedWithCoverage,
            }
        case types.SET_MARKET_PLACE_ADDRESS_INFORMATION:
        case types.SET_MARKET_PLACE_RFQ_INFORMATION:
            return {
                ...state,
                ...action.payload.rfq,
            }

        case types.SET_MARKET_PLACE_HANDLING_UNIT: {
            if (state.handlingUnits) {
                if (!state.handlingUnits[action.payload.index]) {
                    state.handlingUnits.push(action.payload.handlingUnit as Partial<SearchRfqHandlingUnit>)
                } else {
                    state.handlingUnits[action.payload.index] = action.payload.handlingUnit
                }
            }

            return {
                ...state,
            }
        }
        case types.DELETE_MARKET_PLACE_HANDLING_UNIT: {
            state?.handlingUnits?.splice(action.payload as number, 1)

            return {
                ...state,
            }
        }
        case types.ADD_MARKET_PLACE_CUSTOMS_DETAIL: {
            if (!state.customsDetails) {
                state.customsDetails = []
            }
            state.customsDetails.push(action.payload.initialCustomsDetails as SearchRfqCustomsDetail)

            return {
                ...state,
            }
        }

        case types.DELETE_MARKET_PLACE_CUSTOMS_DETAIL: {
            state?.customsDetails?.splice(action.payload.index as number, 1)

            return {
                ...state,
            }
        }

        case types.MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL: {
            const index = action.payload.index
            const isSelected = action.payload.isSelected

            if (state?.customsDetails) {
                const handlingUnitCustomsDetail = state.customsDetails[index]

                if (isSelected){
                    handlingUnitCustomsDetail.handlingUnitIds.push(action.payload.handlingUnitId as string)
                } else {
                    handlingUnitCustomsDetail.handlingUnitIds =
                        handlingUnitCustomsDetail.handlingUnitIds.filter((id) =>
                            id !== action.payload.handlingUnitId) ?? null
                }
            }

            return {
                ...state,
            }
        }

        case types.SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL: {
            if (!state.customsDetails) {
                state.customsDetails = []
            }

            state.customsDetails[action.payload.index] = action.payload.handlingUnitCustomsDetail

            return {
                ...state,
            }
        }
        case types.RESET_MARKET_PLACE_CUSTOMS_DETAILS: {
            state.customsDetails = []

            return {
                ...state,
            }
        }
        case types.RESET_MARKET_PLACE_HAZMAT_DETAIL:
            return {
                ...state,
                ...action.payload.initialHazmatDetail,
            }
        case types.RESET_MARKET_PLACE_CUSTOMS_INFO:
            return {
                ...state,
                ...action.payload.initialCustomsInfo,
            }
        case types.RESET_MARKET_PLACE_DUTY_BILL_ADDRESS:
            return {
                ...state,
                ...action.payload.initialDutyBillAddress,
            }
        case types.RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS:
            return {
                ...state,
                ...action.payload.initialCustomsBrokerAddress,
            }
        case types.RESET_MARKET_PLACE_CARGO: {
            state.handlingUnits = []

            return {
                ...state,
            }
        }
        case types.SET_MARKET_PLACE_CARGO_ACCESSORIALS: {
            const newRfqReducedState = state
            if (newRfqReducedState?.handlingUnits && newRfqReducedState.handlingUnits[action.payload.index]) {
                newRfqReducedState.handlingUnits[action.payload.index].accessorials = action.payload.accessorials
            }

            return {
                ...newRfqReducedState,
            }
        }
        case types.SET_MARKET_PLACE_SELECTED_QUOTE:
            return {
                ...state,
                selectedQuote: action.payload.selectedQuote,
                customQuoteRequested: false,
            }
        case types.SET_MARKET_PLACE_COMMODITY_TYPE:
            return {
                ...state,
                coverageCommodityType: action.payload,
            }
        case types.SET_MARKET_PLACE_SCHEDULE_PICKUP:
            return {
                ...state,
                schedulePickup: action.payload,
            }
        case types.SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE:
            return {
                ...state,
                shipmentCoverageValue: action.payload.shipmentCoverageValue,
            }
        case types.SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE:
            return {
                ...state,
                originalShipmentCoverageValue: action.payload.originalShipmentCoverageValue,
            }
        case types.SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE_CURRENCY:
            return {
                ...state,
                originalShipmentCoverageValueCurrency: action.payload.originalShipmentCoverageValueCurrency,
            }
        case types.SET_MARKET_PLACE_ADDRESS_ACCESSORIALS: {
            let newAccessorialsState = state.accessorials ?? []

            const oldNonRemovableAccessorials = state.accessorials?.filter((accessorial) => !accessorial.isRemovable) ?? []

            const oldOriginRemovableAccessorials = state.accessorials?.filter((rfqAccessorial) =>
                rfqAccessorial.isRemovable &&
                (rfqAccessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
                    rfqAccessorial.accessorial.type === AccessorialType.SERVICE))

            const oldDestinationRemovableAccessorials = state.accessorials?.filter((rfqAccessorial) =>
                rfqAccessorial.isRemovable && rfqAccessorial.accessorial.locationType === AccessorialLocationType.DESTINATION)

            const newRemovableAccessorials = action.payload.accessorials
                .filter((accessorial: SearchRfqAccessorial) => accessorial.isRemovable)

            if (action.payload.locationType === AccessorialLocationType.ORIGIN) {
                newAccessorialsState = newRemovableAccessorials.concat(oldDestinationRemovableAccessorials)
            } else if (action.payload.locationType === AccessorialLocationType.DESTINATION) {
                newAccessorialsState = newRemovableAccessorials.concat(oldOriginRemovableAccessorials)
            }

            return {
                ...state,
                accessorials: newAccessorialsState.concat(oldNonRemovableAccessorials),
            }
        }
        case types.SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS: {
            let newAccessorialsState = state.accessorials ?? []

            const oldOriginAccessorials = state.accessorials?.filter((rfqAccessorial) =>
                rfqAccessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
                rfqAccessorial.accessorial.type === AccessorialType.SERVICE)

            const oldDestinationAccessorials = state.accessorials?.filter((rfqAccessorial) =>
                rfqAccessorial.accessorial.locationType === AccessorialLocationType.DESTINATION)

            if (action.payload.locationType === AccessorialLocationType.ORIGIN) {
                newAccessorialsState = action.payload.accessorials.concat(oldDestinationAccessorials)
            } else if (action.payload.locationType === AccessorialLocationType.DESTINATION) {
                newAccessorialsState = action.payload.accessorials.concat(oldOriginAccessorials)
            }

            return {
                ...state,
                accessorials: newAccessorialsState,
            }
        }
        case types.SET_MARKET_PLACE_SHIPMENT_COVERAGE:
            return {
                ...state,
                shipmentCoverageValue: action.payload.shipmentCoverageValue,
                coverageCommodityType: action.payload.commodityType,
            }
        default:
            return state
    }
}


const originAddressBookAddress = (state: Address | null, action: AnyAction): Address | null => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS:
            return action.payload.address
        default:
            return state
    }
}

const destinationAddressBookAddress = (state: Address | null, action: AnyAction): Address | null => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS:
            return action.payload.address
        default:
            return state
    }
}

const isCoverageExclusionsAccepted = (state: boolean | undefined, action: AnyAction): boolean | undefined => {
    switch (action.type) {
        case types.SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED:
            return action.payload
        default:
            return state
    }
}

const marketPlaceStep = (state: MarketPlaceStep, action: AnyAction): MarketPlaceStep => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_MARKET_PLACE_STEP:
            return action.payload
        default:
            return state
    }
}

const marketPlaceLastCompletedStep = (state: MarketPlaceStep, action: AnyAction): MarketPlaceStep => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_COMPLETED_STEP:
            return action.payload
        default:
            return state
    }
}

const handlingUnitAccessorialList = (state: SearchRfqAccessorial[], action: AnyAction): SearchRfqAccessorial[] => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST:
            return action.payload
        default:
            return state
    }
}

const serviceAccessorialList = (state: SearchRfqAccessorial[], action: AnyAction): SearchRfqAccessorial[] => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST:
            return action.payload
        default:
            return state
    }
}

const packageTypeList = (state: PackageType[], action: AnyAction): PackageType[] => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_PACKAGE_TYPE_LIST:
            return action.payload
        default:
            return state
    }
}

const coverageCommodityTypeList = (state: CoverageCommodityType[], action: AnyAction): CoverageCommodityType[] => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST:
            return action.payload
        default:
            return state
    }
}

const accessorialList = (
    state: SearchRfqAccessorial[],
    action: AnyAction, locationType: AccessorialLocationType,
): SearchRfqAccessorial[] => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ACCESSORIAL_LISTS:
            switch (locationType) {
                case AccessorialLocationType.ORIGIN:
                    return action.payload.originAccessorialList
                case AccessorialLocationType.DESTINATION:
                    return action.payload.destinationAccessorialList
                default:
                    return state
            }
        default:
            return state
    }
}
const countryList = (
    state: Country[],
    action: AnyAction,
): Country[] => {
    switch (action.type) {
        case types.SET_COUNTRY_LIST:
            return action.payload
        default:
            return state
    }
}

const quoteFetchingComplete = (isQuoteFetchingComplete: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE:
            return action.payload
        default:
            return isQuoteFetchingComplete
    }
}
const quoteFetchingResultsCompleted = (state:  Record<string, boolean>, action: AnyAction):  Record<string, boolean> => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_QUOTE_FETCHING_RESULTS_COMPLETED:
            return action.payload
        default:
            return state
    }
}
const quoteFetchingSearchProgress = (state: number, action: AnyAction): number => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_QUOTE_FETCHING_SEARCH_PROGRESS:
            return action.payload
        default:
            return state
    }
}

const marketPlaceErrors = (state: MarketPlaceErrors, action: AnyAction): MarketPlaceErrors => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ERRORS:
            return action.payload
        case types.SET_MARKET_PLACE_ERRORS_IS_SUBMITTED:
            return {
                ...state,
                isSubmitted: action.payload,
            }
        case types.DELETE_MARKET_PLACE_HANDLING_UNIT:{
            const newCargoIsDirty = state.cargoIsDirty
            newCargoIsDirty.splice(action.payload as number, 1)
            const newCargoErrors = state.cargoErrors
            newCargoErrors.splice(action.payload as number, 1)

            return {
                ...state,
                cargoErrors: newCargoErrors,
                cargoIsDirty: newCargoIsDirty,
            }
        }
        case types.RESET_MARKET_PLACE_CARGO: {
            return {
                ...state,
                cargoErrors: [],
                cargoIsDirty: [],
            }
        }
        case types.RESET_MARKET_PLACE_CUSTOMS_DETAILS: {
            return {
                ...state,
                customsDetailErrors: [ {} ],
                customsDetailIsDirty: [ {} ],
            }
        }
        case types.RESET_MARKET_PLACE_CUSTOMS_INFO: {
            return {
                ...state,
                rfqCustomsErrors: {},
                rfqCustomsIsDirty: {},
            }
        }
        case types.RESET_MARKET_PLACE_DUTY_BILL_ADDRESS: {
            return {
                ...state,
                rfqCustomsErrors: {
                    billDutiesToParty: state.rfqCustomsErrors.billDutiesToParty,
                    importExport: state.rfqCustomsErrors.importExport,
                },
                rfqCustomsIsDirty: {
                    billDutiesToParty: state.rfqCustomsIsDirty.billDutiesToParty,
                    importExport: state.rfqCustomsIsDirty.importExport,
                },
            }
        }
        default:
            return state
    }
}

const placesAutocomplete = (
    state: AddressSearchOption | null,
    action: AnyAction,
    field: keyof MarketPlace,
): AddressSearchOption | null => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_PLACES_AUTOCOMPLETE:
            if (action.payload.field === field) {
                return action.payload.newPlace
            }

            return state
        default:
            return state
    }
}

const invalidationDialog = (state: InvalidationDialog, action: AnyAction): InvalidationDialog => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN:
            return {
                ...state,
                isInvalidationDialogOpen: action.payload,
            }
        case types.SET_MARKET_PLACE_INVALIDATION_DIALOG:
            return action.payload
        default:
            return state
    }
}

const setPromptModalLeave = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_PROMPT_MODAL_LEAVE:
            return action.payload
        default:
            return state
    }
}

const setPromptUnsavedChangesDialog = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_PROMPT_UNSAVED_CHANGES_DIALOG:
            return action.payload
        default:
            return state
    }
}

const setOriginSaveToAddressBook = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK:
            return action.payload
        default:
            return state
    }
}

const setDestinationSaveToAddressBook = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK:
            return action.payload
        default:
            return state
    }
}

const setOriginAddressValidated = (state: ValidatedAddress | null, action: AnyAction): ValidatedAddress | null => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED:
            return action.payload
        default:
            return state
    }
}

const setDestinationAddressValidated = (state: ValidatedAddress | null, action: AnyAction): ValidatedAddress | null => {
    switch (action.type) {
        case types.SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED:
            return action.payload
        default:
            return state
    }
}

const toggleReasonsModal = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.TOGGLE_REASONS_MODAL:
            return !state
        default:
            return state
    }
}

const toggleSameDayGroundShipmentModal = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.TOGGLE_SAME_DAY_GROUND_SHIPMENT_MODAL:
            return !state
        default:
            return state
    }
}

const setToggleWarningdShipmentModal = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_TOGGLE_WARNING_GROUND_SHIPMENT_MODAL:
            return !state
        default:
            return state
    }
}

const paymentType = (state: PaymentTypeOption, action: AnyAction): PaymentTypeOption => {
    switch (action.type) {
        case types.SET_PAYMENT_TYPE:
            return action.payload
        default:
            return state
    }
}

const collect = (state: Partial<Collect> | null, action: AnyAction): Partial<Collect> | null => {
    const clearCollect = action.type === types.SET_COLLECT_INFORMATION && !action.payload
    switch (action.type) {
        case types.SET_COLLECT_INFORMATION:
            if (clearCollect) {
                return null
            }

            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

const userLanes = (state: Lane[] | null, action: AnyAction): Lane[] | null => {
    switch (action.type) {
        case types.SET_USER_LANES:
            return action.payload
        default:
            return state
    }
}

const selectedLane = (state: UuidV4 | null, action: AnyAction): UuidV4 | null => {
  
    switch (action.type) {
        case types.SET_USER_SELECTED_LANE:
            return action.payload
        default:
            return state
    }
}


const rescheduledPickupDate = (state = '', action: AnyAction): string => {
    switch (action.type) {
        case types.SET_NEW_PICKUP_DATE:
            return action.payload
        default:
            return state
    }
}

export default reducer
