import React from 'react'
import { CheckIcon, StyledListItem } from './ListItem.styled'
import { ListItemProps, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface Props extends ListItemProps {
    nested?: boolean
    primary?: string
    secondary?: string
    isNestedSubMenuOpen?: boolean
    disabled?: boolean
    isFlex?: boolean
}

const getText = (props: Props): JSX.Element => {
    const subtitle = props.secondary ? <span className='lzr-subtitle'>{` (${props.secondary})`}</span> : null

    return <span>{props.primary}{subtitle}</span>
}

const getNestedIcon = (props: Props): JSX.Element | null => {
    if (props.isNestedSubMenuOpen === undefined || props.selected) {
        return null
    }

    if (props.isNestedSubMenuOpen) {
        return <FontAwesomeIcon icon={[ 'fal', 'chevron-up' ]}/>
    }

    return <FontAwesomeIcon icon={[ 'fal', 'chevron-down' ]}/>
}

const ListItem: React.FunctionComponent<Props> = (props: Props) => {
    const areChildrenTypeOfString = typeof props.children === 'string'
    const isFlex = props?.isFlex===false ? false  : true
    return (

        <StyledListItem
            {...props}
            isFlex = {isFlex}
        >
            {!areChildrenTypeOfString ?
                props.children
                :
                <Typography variant='body1'>
                    {props.primary ? getText(props) : props.children}
                </Typography>}
            {props.selected ? <CheckIcon icon={[ 'far', 'check-circle' ]} /> : null}
            {getNestedIcon(props)}
        </StyledListItem>
    )}

export default ListItem
