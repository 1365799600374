import styled from 'styled-components'
import {
    Checkbox as MuiCheckbox,
    CheckboxProps,
    Radio as MuiRadio,
    RadioProps,
    ButtonBase,
    ButtonBaseProps,
    IconButton as MuiIconButton,
    TypographyProps,
    Typography,
    Grid as MuiGrid,
    Box as MuiBox,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system';
import React from 'react'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StyledSelectionCard from '@/app/ui-new/components/SelectionCard/SelectionCardV3'

export const StyledContainer = styled.div<{
    $width?: string | number
    $height?: string | number
    $unexpandedHeight?: string | number
    $canExpand?: boolean
}>`
    ${(props): string => props.$width ? `width: ${props.$width};` : ''}
    ${(props): string => props.$height ? `height: ${props.$height};` : ''}
    overflow: hidden;
    position: relative;
    transition: width 300ms ease-out 0ms, height 300ms ease-out 0ms;
    border: 1px solid ${(props): string => props.theme.palette.neutral400.main};
    border-radius: 4px;

    :hover {
        border-color: ${(props): string => props.theme.palette.black.main};
        box-shadow: 0 4px 20px ${(props): string => transparentize(0.9, props.theme.palette.accentSecondary700.main)};
    }
    :has(input:checked){
        border-color: ${(props): string => props.theme.palette.accentPrimary600.main};

        ::before {
            border-bottom-width: 4px;
            border-color: ${(props): string => props.theme.palette.accentPrimary600.main};
        }
        border-bottom-width: 1px;
    }

    ::before {
        content: "";
        position: absolute;
        left: -1px;
        right: -1px;
        border-width: 0px;
        z-index: 1;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: -1px;
        border-style: solid;
        border-color: ${(props): string => props.theme.palette.black.main};
        transition: all 100ms ease-out 0ms;
    }
    :hover::before {
        border-bottom-width: 4px;
    }
    ${(props): string => props.$canExpand ? `
        ::after {
            content: "";
            position: absolute;
            left: 0px;
            right: 0px;
            border-bottom-width: 0px;
            border-top-width: 0px;

            ${props.$unexpandedHeight ? `top: calc(${props.$unexpandedHeight} - 5px);` : ''}
            border-style: solid;

            width: 0%;
            transition: all 300ms ease-out 0ms;
        }
        &.lzr-selection-card-container.lzr-selection-card-container-expanded {
            ::after {
                border-color: ${props.theme.palette.accentPrimary600.main};
                border-radius: 0;
                width: 100%;
                border-bottom-width: 4px;
            }
            :has(input:checked){
                ::before {
                    border-bottom-width: 0px;
                }
                :hover {
                    box-shadow: unset;
                }
            }
        }
        &.lzr-selection-card-container.lzr-selection-card-container-expanded.lzr-selection-card-container-error {
            ::after {
                border-color: ${props.theme.palette.error900.main};
                :has(input:checked){
                    border-color: ${props.theme.palette.error900.main};
                }
            }
        }
        `
        :
        ''}
    &.lzr-selection-card-container-expanded.lzr-selection-card-container-error {
        border-color: ${(props): string => props.theme.palette.error900.main};
        :has(input:checked){
            border-color: ${(props): string => props.theme.palette.error900.main};
        }
    }
`

export const StyledMuiCheckbox: React.FC<CheckboxProps> = styled(MuiCheckbox) <CheckboxProps>`
    display: none;
`

export const StyledMuiRadio: React.FC<RadioProps> = styled(MuiRadio) <RadioProps>`
    display: none;
`

export const StyledCloseIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    color: ${(props): string => props.theme.palette.text.primary};
`

export const StyledCloseIconContainer = styled(MuiIconButton)`
    width: 24px;
    height: 24px;
    right: 12px;
    position: absolute;
    top: 12px;
    z-index: 1;
`
export const StyledCheckIcon = styled(FontAwesomeIcon)`
    font-size: 1.42857143rem;
`

export const StyledUncheckedIcon = styled.div`
    width: 24px;
    height: 24px;
    right: 12px;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledCheckIconContainer = styled(StyledUncheckedIcon)`
    color: ${(props): string => props.theme.palette.accentPrimary600.main};
    &.lzr-check-icon-error {
        color: ${(props): string => props.theme.palette.error900.main};
    }
`
export const ExpandedContent = styled.div`
    background: ${(props): string => props.theme.palette.white.main};

    &.lzr-expanded-content.lzr-expanded-content-expanded {}
`
export const StyledTypographyH6: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.143rem;
    font-weight: 500;
    line-height: 140%;
`
export const StyledTypographySubtitle1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
`
export const StyledTypographyBody2 = styled(Typography)<{ component?: React.ElementType<React.HTMLAttributes<HTMLElement>> }>`
    font-size: 0.857rem;
    font-weight: 400;
    line-height: 140%;
`

export const AbsoluteGrid = styled(MuiGrid)`
    position: absolute;
    bottom: 16px;
    left: 16px;
`

export const TrashContainer = styled.div`
    position: absolute;
    top: 18px;
    right: 16px;
    color: ${(props): string => props.theme.palette.accentPrimary900.main};
    cursor: pointer;
`

export const StyledTooltipBox = styled(MuiBox)`
    .custom-popper {
        background: none; !important;
        max-width: 520px;
    }
    .custom-tooltip {
        background: none; !important;
        max-width: 520px;
    }
    .tooltip-placement-left {
        margin: ${(props): string => props.theme.spacing(-1.5)}px 0 ${(props): string => props.theme.spacing(2)}px 0;
    }
    .tooltip-placement-left-coverage {
        margin: ${(props): string => props.theme.spacing(-1.5)}px
        ${(props): string => props.theme.spacing(-5)}px ${(props): string => props.theme.spacing(2)}px 0;
    }
    [class*="MuiTooltip-arrow-"] {
        display: none;
    }

`

const getBorderStyle = (props: any) => {
    if (props.$isFirst && props.$isLast) {
        return `border: 1px solid ${(): string => props.theme.palette.neutral400.main};
                border-radius: 4px;`
    }
    if (props.$isFirst) {
        return `border: 1px solid ${(): string => props.theme.palette.neutral400.main};
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;`
    }
    if (props.$isLast) {
        return `border-top: 1px solid ${(): string => props.theme.palette.neutral400.main};
                border-right: 1px solid ${(): string => props.theme.palette.neutral400.main};
                border-bottom: 1px solid ${(): string => props.theme.palette.neutral400.main};
                border-left: none;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;`
    }
    return `border-top: 1px solid ${(): string => props.theme.palette.neutral400.main};
            border-right: 1px solid ${(): string => props.theme.palette.neutral400.main};
            border-bottom: 1px solid ${(): string => props.theme.palette.neutral400.main};
            border-left: none;
            border-radius: unset;`
}

export const SelectionCard = styled(styled(StyledSelectionCard)(MuiSpacing))<{$isFirst?: boolean, $isLast?: boolean}>`
    & label{
        height: 100%;
        align-items: start;
        padding: 8px;

        & [class*='lzr-check-icon'] {
            bottom: 4px;
        }

        & [class^='MuiTypography-root'] {
            font-size: 14px;
            margin-left: -8px;
            margin-top: 2px;
        }
    }

    ${(props): string => getBorderStyle(props)}
    // border: 1px solid ${(props): string => props.theme.palette.neutral400.main};
    // border-radius: 4px;

`