import React, { useEffect, useState } from 'react'
import { Controller, Control, FieldValues } from 'react-hook-form'
import { Typography, Grid } from '@material-ui/core'
import { Checkbox } from '../Checkbox/Checkbox'
import { GridCheckbox } from './GroupCheckbox.styled'
import Tooltip, { CustomTooltipProps } from '../Tooltip/Tooltip'
import { QuestionIcon } from '../Tooltip/Tooltip.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortObjectByOrder } from '../Form/helpers'

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import type { Active } from '@dnd-kit/core'
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { DragHandle, SortableItem } from '../SortableList/SortableItem'
import LabelledOutlineBox from '../Form/LabelledOutLineBox/LabelledOutLineBox'
import { SortableOverlay } from '../SortableList/SortableOverlay'
import { FieldConfig } from '../Form/FormSimple'

interface GroupCheckboxProps {
    namePrefix: string
    control: Control<FieldValues>
    errors: any
    labelName?: string
    additionalDescription?: string
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange?: (value?: any) => void
    groupCheckbox?: Record<string, any>
    tooltip?: CustomTooltipProps
    defaultValue?: any
    values?: any
    hasOrder?: boolean
    getOrder?: FieldConfig['getOrder']
    hideOutBox?: boolean
}

const GroupCheckbox: React.FC<GroupCheckboxProps> = ({
    namePrefix,
    control,
    errors,
    labelName,
    setValue,
    debouncedHandleOnChange,
    additionalDescription,
    groupCheckbox,
    defaultValue,
    tooltip,
    values,
    hasOrder,
    getOrder,
    hideOutBox,
}) => {
    const fieldName = `${namePrefix}`
    //const [sortedCols, setSortedCols] = useState<any>(groupCheckbox)

    // // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    // useEffect(() => {
    //     if (hasOrder) {
    //         setSortedCols(sortObjectByOrder(groupCheckbox))
    //     }
    // }, [groupCheckbox])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(fieldName, values?.split(','))
        debouncedHandleOnChange?.({[fieldName]: values?.split(',')})
    }, [values])

    const arrayWithOrder = Object.keys(sortObjectByOrder(groupCheckbox)).map((item, index) => {
        return {
            id: index + 1,
            item: item,
        }
    })

    const [items, setItems] = useState(arrayWithOrder)

    const [active, setActive] = useState<Active | null>(null)

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    return (
        <>
            <LabelledOutlineBox labelName={labelName ? labelName : namePrefix} tooltip={tooltip} hideOutBox={hideOutBox}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name={fieldName}
                            control={control}
                            defaultValue={defaultValue?.split(',')}
                            render={({ field }) => {
                                return (
                                    <DndContext
                                        sensors={sensors}
                                        onDragStart={({ active }) => {
                                            hasOrder && setActive(active)
                                        }}
                                        onDragEnd={({ active, over }) => {
                                            if (hasOrder) {
                                                if (over && active.id !== over?.id) {
                                                    const activeIndex = items.findIndex(({ id }) => id === active.id)
                                                    const overIndex = items.findIndex(({ id }) => id === over.id)
                                                    const itemsArray = arrayMove(items, activeIndex, overIndex)
                                                    setItems(itemsArray)
                                                    getOrder?.(itemsArray)
                                                }
                                                setActive(null)
                                            }
                                        }}
                                        onDragCancel={() => {
                                            if (hasOrder) {
                                                setActive(null)
                                            }
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <SortableContext items={items}>
                                                {groupCheckbox &&
                                                    items.map((key, index) => (
                                                        <Grid item key={key.item} {...groupCheckbox[key.item]?.layoutConfig}>
                                                            <Grid container spacing={2} alignItems='center'>
                                                                <Grid item xs={12}>
                                                                    <GridCheckbox>
                                                                        <SortableItem id={key.id} hasOrder={hasOrder}>
                                                                            {hasOrder && <DragHandle disabled={!field.value.includes(key.item)}/>}
                                                                            <Checkbox
                                                                                checked={field.value.includes(key.item)}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    const isChecked = e.target.checked
                                                                                    const currentValue = field.value
                                                                                    const updatedValue = isChecked
                                                                                        ? [...currentValue, key.item]
                                                                                        : currentValue.filter(
                                                                                              (item: string) => item !== key.item,
                                                                                          )
                                                                                    setValue(fieldName, updatedValue)
                                                                                    debouncedHandleOnChange?.({[fieldName]: updatedValue})
                                                                                }}
                                                                            />

                                                                            <GridCheckbox>
                                                                                <Typography variant='body1'>
                                                                                    {groupCheckbox[key.item]?.label || key}
                                                                                </Typography>
                                                                                {groupCheckbox[key.item]?.tooltip && (
                                                                                    <Tooltip {...groupCheckbox[key.item]?.tooltip}>
                                                                                        <QuestionIcon>
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    groupCheckbox[key.item]?.tooltip?.icon
                                                                                                        ? groupCheckbox[key.item]?.tooltip
                                                                                                              ?.icon
                                                                                                        : ['far', 'question-circle']
                                                                                                }
                                                                                            />
                                                                                        </QuestionIcon>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </GridCheckbox>
                                                                        </SortableItem>
                                                                    </GridCheckbox>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </SortableContext>
                                            <SortableOverlay>
                                                
                                            </SortableOverlay>
                                        </Grid>
                                    </DndContext>
                                )
                            }}
                        />
                    </Grid>
                    {errors[namePrefix] && (
                        <Typography variant='caption' color='error'>
                            {errors[namePrefix]?.message}
                        </Typography>
                    )}
                    {additionalDescription && (
                        <Typography variant='caption' color='primary'>
                            {additionalDescription}
                        </Typography>
                    )}
                </Grid>
            </LabelledOutlineBox>
        </>
    )
}

export default GroupCheckbox
