import React from 'react'
import { StyledSwitch } from './Switch.styled'
import { SwitchProps } from '@material-ui/core/Switch'


const Switch: React.FunctionComponent<SwitchProps> = (props: SwitchProps) => (
    <StyledSwitch
        {...props}
    />
)


export default Switch
