// biome-ignore lint/style/useImportType: <explanation>
import React from 'react'
import { useEffect, useState } from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './AddressForm.i18n'
import { z } from 'zod'
import FormSimple, { type FieldConfig, type OnError } from '@/app/ui-new/components/Form/FormSimple'
import type { SearchAddressType } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar'
import { Grid } from '@material-ui/core'
import type { Carrier, Country } from '@/app/model'
import { validationMinQtdSchema } from '@/app/ui-new/components/Form/schema'
import type { CountryCode } from 'libphonenumber-js'

export interface Field {
    value?: string | string[]
    label?: string
    placeholder?: string
    schema?: z.ZodTypeAny
    layoutConfig?: FieldConfig['layoutConfig']
    hide?: boolean
    required?: boolean
    order?: FieldConfig['order']
}

export interface GoogleAddressField {
    description: string
    isAddressBook: boolean
    type: SearchAddressType.ADDRESS
    mainText: string
}

export interface Address {
    companyName?: Field
    contactName?: Field
    contactPhone?: Field
    contactPhoneExtension?: Field
    googleAddress?: GoogleAddressField
    street?: Field
    street2?: Field
    street3?: Field
    city?: Field
    country?: Field
    postalCode?: Field
    addressType?: Field
    state?: Field
    contactEmails?: Field
}
export interface AddressFormProps {
    nameID: string
    countries?: Country[]
    address?: Address
    userCountry?: CountryCode
    isHalfScreen?: boolean
    getFormValues?: (data: any) => void
    getFormOnError?: (data: OnError) => void
    profile?: 'full-address' | 'only-phone' | 'only-address' | 'only-emails'
    forceSubmit?: boolean
}
const AddressForm: React.FunctionComponent<AddressFormProps> = ({
    nameID,
    address,
    countries,
    getFormValues,
    getFormOnError,
    userCountry,
    isHalfScreen,
    profile = 'FullAddress',
    forceSubmit,
}) => {

    const { t } = useI18n(i18n)
    const [expanded, setIsExpanded] = useState<boolean>(true)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [companyName, setCompanyName] = useState<Field>({
        value: '',
        required: profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.companyName,
    })
    const [contactName, setContactName] = useState<Field>({
        value: '',
        required: profile === 'only-address' || profile === 'only-emails' ? false : true,
        hide: profile === 'only-address' || profile === 'only-emails' ? true : false,
        ...address?.contactName,
    })
    const [contactEmails, setContactEmails] = useState<Field>({
        value: [],
        required: profile === 'only-address' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-address' || profile === 'only-phone' ? true : false,
        ...address?.contactEmails,
    })
    const [contactPhone, setContactPhone] = useState<Field>({
        value: '',
        required: profile === 'only-address' || profile === 'only-emails' ? false : true,
        hide: profile === 'only-address' || profile === 'only-emails' ? true : false,
        ...address?.contactPhone,
    })
    const [contactPhoneExtension, setContactPhoneExtension] = useState<Field>({
        value: '',
        required: false,
        hide: profile === 'only-address' || profile === 'only-emails' ? true : false,
        ...address?.contactPhoneExtension,
    })

    const [street, setStreet] = useState<Field>({
        value: '',
        required: profile === 'only-address' || profile === 'only-emails' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.street,
    })
    const [street2, setStreet2] = useState<Field>({
        value: '',
        required: false,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.street2,
    })
    const [street3, setStreet3] = useState<Field>({
        value: '',
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.street3,
    })
    const [city, setCity] = useState<Field>({
        value: '',
        required: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.city,
    })
    const [country, setCountry] = useState<Field>({
        value: '',
        required: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.country,
    })
    const [postalCode, setPostalCode] = useState<Field>({
        value: '',
        required: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.postalCode,
    })
    const [addressType, setAddressType] = useState<Field>({
        value: '',
        required: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.addressType,
    })
    const [state, setState] = useState<Field>({
        value: '',
        required: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? false : true,
        hide: profile === 'only-phone' || profile === 'only-emails' || profile === 'only-phone' ? true : false,
        ...address?.state,
    })

    const [googleAddress, setGoogleAddress] = useState<GoogleAddressField | null | undefined>(address?.googleAddress || null)

    const handleExpansion = () => {
        setIsExpanded((prevExpanded) => !prevExpanded)
    }

    useEffect(() => {
        if (address?.contactName?.value) {
            setContactName((val) => {
                return { ...val, value: address?.contactName?.value || '' }
            })
        }

        if (address?.contactEmails) {
            setContactEmails((val: any) => {
                return { ...val, value: address?.contactEmails?.value || [] }
            })
        }

        if (address?.contactPhone?.value) {
            setContactPhone((val) => {
                return { ...val, value: address?.contactPhone?.value || '' }
            })
        }

        if (address?.contactPhoneExtension?.value) {
            setContactPhoneExtension((val) => {
                return { ...val, value: address?.contactPhoneExtension?.value || '' }
            })
        }

        if (address?.companyName?.value) {
            setCompanyName((val) => {
                return { ...val, value: address?.companyName?.value || '' }
            })
        }

        if (address?.addressType?.value) {
            setAddressType((val) => {
                return { ...val, value: address?.addressType?.value || '' }
            })
        }

        if (address?.street?.value) {
            setStreet((val) => {
                return { ...val, value: address?.street?.value || '' }
            })
        }

        if (address?.street2?.value) {
            setStreet2((val) => {
                return { ...val, value: address?.street2?.value || '' }
            })
        }

        if (address?.street3?.value) {
            setStreet3((val) => {
                return { ...val, value: address?.street3?.value || '' }
            })
        }

        if (address?.city?.value) {
            setCity((val) => {
                return { ...val, value: address?.city?.value || '' }
            })
        }

        if (address?.state?.value) {
            setState((val) => {
                return { ...val, value: address?.state?.value || '' }
            })
        }

        if (address?.country?.value) {
            setCountry((val) => {
                return { ...val, value: address?.country?.value || '' }
            })
        }

        if (address?.postalCode?.value) {
            setPostalCode((val) => {
                return { ...val, value: address?.postalCode?.value || '' }
            })
        }

        if (address?.googleAddress) {
            setGoogleAddress(address?.googleAddress)
        }
    }, [address])

    const handleGoogle = (value: any) => {
        const data = value?.googleAddress?.data

        setCompanyName((val) => {
            return { ...val, value: data?.companyName }
        })
        setAddressType((val) => {
            return { ...val, value: data?.addressType === 'RESIDENTIAL' ? 'RESIDENTIAL' : 'COMMERCIAL' }
        })

        setStreet((val) => {
            return { ...val, value: data?.streetAddress }
        })

        setStreet2((val) => {
            return { ...val, value: data?.streetAddress2 }
        })

        setCity((val) => {
            return { ...val, value: data?.city }
        })

        setState((val) => {
            return { ...val, value: data?.state }
        })

        setCountry((val) => {
            return { ...val, value: data?.country?.name }
        })

        setPostalCode((val) => {
            return { ...val, value: data?.postalCode }
        })
    }

    const googleAddressField: FieldConfig[] = [
        {
            name: 'googleAddress',
            labelName: 'Address',
            schema: z.any(),
            type: 'autoCompleteWithAddressBookGoogle',
            values: googleAddress,
            layoutConfig: { xs: 12, sm: 12 },
            addressSearch: {
                countries: countries,
            },
            order: 0,
        },
    ]

    const addressField: FieldConfig[] = [


        {
            name: 'googleAddress',
            schema: z.any(),
            hide: street?.hide || false,
            type: 'injectComponent', /// TODO: change to custom Form
            injectComponent: (
                <FormSimple
                    hideForm={true}
                    nameID={`${nameID}-google`}
                    fields={googleAddressField}
                    layoutConfig={{ spacing: 3 }}
                    onChange={{validatedData: handleGoogle}}
                    //resetForm={resetForm}
                    debouncer={{ wait: 1 }}
                />
            ),
            layoutConfig: { xs: 12, sm: 12, ...street?.layoutConfig },
            order: street?.order ? street?.order : 1,
        },
        {
            name: 'addressType',
            labelName: addressType?.label || t('Address type'),
            schema: addressType?.required
                ? z
                      .string()
                      .nullable()
                      .refine(
                          (value) => {
                              if (value === null) {
                                  return false // Reject null values
                              }
                              return value.length > 2
                          },
                          { message: `${t('select between')} ${t('Residential')} ${t('or')} ${t('Commercial')}` },
                      )
                : z.any(),
            required: addressType?.required || false,
            hide: addressType?.hide || false,
            type: 'toggleButton',
            toggles: {
                first: { value: 'RESIDENTIAL', label: t('Residential') },
                second: { value: 'COMMERCIAL', label: t('Commercial') },
            },
            values: addressType?.value ? addressType?.value : 'COMMERCIAL',
            layoutConfig: {
                xs: 12,
                md: isHalfScreen ? 12 : 3,
                lg: isHalfScreen ? 7 : 3,
                xl: isHalfScreen ? 6 : 3,
                ...addressType?.layoutConfig,
            },
            order: addressType?.order ? addressType?.order : 2,
        },

        {
            name: 'companyName',
            labelName: companyName?.label || t('Company name'),
            schema: companyName?.required
                ? companyName?.schema ||
                  validationMinQtdSchema(
                      `${t('Company name')} ${t('field is required')}`,
                      `${t('Company name')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),
            hide: companyName?.hide || false,
            required: companyName?.required || false,
            type: 'textOutlined',
            values: companyName?.value,
            layoutConfig: {
                xs: 12,
                md: isHalfScreen ? 12 : 3,
                lg: isHalfScreen ? 5 : 3,
                xl: isHalfScreen ? 6 : 3,
                ...companyName?.layoutConfig,
            },
            order: companyName?.order ? companyName?.order : 3,
        },

        {
            name: 'street',
            labelName: street?.label || t('Street'),
            schema: street?.required
                ? street?.schema ||
                  validationMinQtdSchema(
                      `${t('Street')} ${t('field is required')}`,
                      `${t('Street')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),
            hide: street?.hide || false,
            required: street?.required || false,
            type: 'textOutlined',
            values: street?.value,
            layoutConfig: { xs: 12, sm: isHalfScreen ? 12 : 6, ...street?.layoutConfig },
            order: street?.order ? street?.order : 4,
        },
        {
            name: 'street3',
            labelName: street3?.label || undefined,
            schema: street3?.required
                ? street3?.schema ||
                  validationMinQtdSchema(
                      `${t('Street')} ${t('field is required')}`,
                      `${t('Street')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),
            hide: street3?.hide || false,
            required: street3?.required ? street3?.required : false,
            type: 'textOutlined',
            values: street3?.value,
            layoutConfig: { xs: 12, sm: 12, ...street3?.layoutConfig },
            order: street3?.order ? street3?.order : 5,
        },
        {
            name: 'street2',
            labelName: street2?.label || t('Apt'),
            schema: street2?.required
                ? street2?.schema || validationMinQtdSchema(`${t('Apt')} ${t('field is required')}`, null, null)
                : z.any(),
            hide: street2?.hide || false,
            required: street2?.required || false,
            type: 'textOutlined',
            values: street2?.value,
            layoutConfig: { xs: 12, sm: 3, ...street2?.layoutConfig },
            order: street2?.order ? street2?.order : 6,
        },
        {
            name: 'city',
            labelName: city?.label || t('City'),
            schema: city?.required
                ? city?.schema ||
                  validationMinQtdSchema(
                      `${t('City')} ${t('field is required')}`,
                      `${t('City')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),
            hide: city?.hide || false,
            required: city?.required || false,
            type: 'textOutlined',
            values: city?.value,
            layoutConfig: { xs: 12, sm: 6, ...city?.layoutConfig },
            order: city?.order || 7,
        },
        {
            name: 'state',
            labelName: state?.label || t('Prov/St'),
            schema: state?.required ? state?.schema || validationMinQtdSchema(`${t('Prov/St')} ${t('field is required')}`) : z.any(),
            hide: state?.hide || false,
            required: state?.required || false,
            type: 'textOutlined',
            values: state?.value,
            layoutConfig: { xs: 12, sm: 3, ...state?.layoutConfig },
            order: state?.order ? state?.order : 8,
        },

        {
            name: 'country',
            labelName: country?.label || t('Country'),
            hide: country?.hide || false,
            required: country?.required || false,
            schema: z.string().min(1, { message: `${t('Country')}  ${t('field is required')}` }),
            type: 'select',
            select: {
                data: countries,
                size: 'small',
            },
            values: country?.value,
            layoutConfig: { xs: 12, sm: 6, ...country?.layoutConfig },
            order: country?.order || 9,
        },

        {
            name: 'postalCode',
            labelName: postalCode?.label || t('Postal code'),
            schema: postalCode?.required
                ? postalCode?.schema ||
                  validationMinQtdSchema(
                      `${t('Postal code')} ${t('field is required')}`,
                      `${t('Postal code')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),
            type: 'textOutlined',
            hide: postalCode?.hide || false,
            required: postalCode?.required || false,
            values: postalCode?.value,
            layoutConfig: { xs: 12, sm: 6, ...postalCode?.layoutConfig },
            order: postalCode?.order || 10,
        },
        {
            name: 'contactName',
            labelName: contactName?.label || t('Contact name'),
            schema: contactName?.required
                ? contactName?.schema ||
                  validationMinQtdSchema(
                      `${t('Contact name')} ${t('field is required')}`,
                      `${t('Contact name')} ${t('must be at least qtd characters long')}`,
                      2,
                  )
                : z.any(),

            hide: contactName?.hide || false,
            required: contactName?.required || false,
            type: 'textOutlined',
            values: contactName?.value,
            layoutConfig: { xs: 12, sm: isHalfScreen ? 6 : 4, ...contactName?.layoutConfig },
            order: contactName?.order ? contactName?.order : 11,
        },

        {
            name: 'contactPhone',
            labelName: contactPhone?.label || t('Phone number'),
            schema: contactPhone?.required
            ? contactPhone?.schema ||
              validationMinQtdSchema(
                  `${t('Phone number')} ${t('field is required')}`,
                  `${t('Phone number')} ${t('must be at least qtd characters long')}`,
                  5,
              )
            : z.any(),
            hide: contactPhone?.hide || false,
            required: contactPhone?.required || false,
            type: 'phoneNumberField',

            values: contactPhone?.value,
            phoneNumber: {
                userCountry: userCountry,
                type: 'textOutlined',
                size: 'medium',
            },
            layoutConfig: {
                xs: 12,
                sm: isHalfScreen ? (profile === 'only-phone' ? 4 : 6) : profile === 'only-phone' ? 3 : 3,
                ...contactPhone?.layoutConfig,
            },
            order: contactPhone?.order ? contactPhone?.order : 12,
        },

        {
            name: 'contactPhoneExtension',
            labelName: contactPhoneExtension?.label || t('Ext.'),
            schema: contactPhoneExtension?.required ? contactPhoneExtension?.schema || z.string() : z.any(),
            hide: contactPhoneExtension?.hide || false,
            required: contactPhoneExtension?.required || false,
            type: 'numberField',
            size: 'small',
            values: contactPhoneExtension?.value,
            layoutConfig: { xs: 12, sm: 2, ...contactPhoneExtension?.layoutConfig },
            order: contactPhoneExtension?.order ? contactPhoneExtension?.order : 13,
        },
        {
            name: 'contactEmails',
            schema: contactEmails?.required
                ? contactEmails?.schema ||
                  z
                      .string()
                      .array()
                      .min(1, { message: `${t('Contact emails')} ${t('must be at least one email')}` })

                      .optional()
                : z.any(),
            //defaultValue: [],
            hide: contactEmails?.hide || false,
            required: contactEmails?.required || false,
            type: 'multipleValues',
            multipleValues: {
                schema: z
                    .string()

                    .email()
                    .min(5, { message: `${t('Contact emails')} ${t('must be at least qtd characters long').replace('≤≤qtd≥≥', '5')}` })
                    .nullable()
                    .optional(),
                name: 'email',
                type: 'textOutlined',
                label: contactEmails?.label || t('Contact emails'),
                placeholder: contactEmails?.placeholder || t('Enter an email address'),
            },
            values: contactEmails?.value,
            layoutConfig: {
                xs: 12,
                sm: isHalfScreen ? (profile === 'only-emails' ? 12 : 10) : profile === 'only-emails' ? 12 : 3,
                ...contactEmails?.layoutConfig,
            },
            order: contactEmails?.order ? contactEmails?.order : 14,
        },
    ]

    const onChange = (value: any) => {
        console.log('value:',value)
        getFormValues?.(value)
    }
    const handleOnError = (object: OnError) => {
        getFormOnError?.(object)
    }
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <FormSimple
                    nameID={`${nameID}-address`}
                    fields={addressField}
                    layoutConfig={{ spacing: 3 }}
                    onChange={{validatedData: onChange}}
                    onError={handleOnError}
                    debouncer={{ wait: 1 }}
                    onSubmit={{
                        button: {
                            title: '',
                            hidden: true,
                        },
                        forceSubmit: forceSubmit,
                        fc: () => {}
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AddressForm
