import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import BannerDesktop from '@/app/ui-new/pages/website/components/Banner/Desktop/BannerDesktop'
import BannerMobile from '@/app/ui-new/pages/website/components/Banner/Mobile/BannerMobile'
import CarriersCarouselMobile from '@/app/ui-new/pages/website/components/CariersCarousel/Mobile/CarriersCarouselMobile'
import CarriersCarouselDesktop from '@/app/ui-new/pages/website/components/CariersCarousel/Desktop/CarriersCarouselDesktop'
import { parcelCarriers, freightCarriers } from '@/app/ui-new/pages/website/components/CariersCarousel/helper'
import DevelopedByDesktop from '@/app/ui-new/pages/website/components/DevelopedBy/Desktop/DevelopedByDesktop'
import DevelopedByMobile from '@/app/ui-new/pages/website/components/DevelopedBy/Mobile/DevelopedByMobile'
import TestimonialsCarouselDesktop from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Desktop/TestimonialsCarouselDesktop'
import TestimonialsCarouselMobile from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Mobile/TestimonialsCarouselMobile'
import OldVsNewWayMobile from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/OldVsNewWayMobile'
import OldVsNewWayDesktop from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/OldVsNewWayDesktop'
import CarouselDesktop from '@/app/ui-new/pages/website/components/Carousel/Desktop/CarouselDesktop'
import CarouselMobile from '@/app/ui-new/pages/website/components/Carousel/Mobile/CarouselMobile'
import { stepCards } from '@/app/ui-new/pages/website/components/Carousel/helper'
import VideoHolderDesktop from '@/app/ui-new/pages/website/components/VideoHolder/Desktop/VideoHolderDesktop'
import FlipCardsDesktop from '@/app/ui-new/pages/website/components/FlipCards/Desktop/FlipCardsDesktop'
import FlipCardsMobile from '@/app/ui-new/pages/website/components/FlipCards/Mobile/FlipCardsMobile'
import NewEfficiencyZeroFrictionDesktop
    from '@/app/ui-new/pages/website/components/NewEfficiencyZeroFriction/Desktop/NewEfficiencyZeroFrictionDesktop'
import QuoteFasterShipSmarterDesktop
    from '@/app/ui-new/pages/website/components/QuoteFasterShipSmarter/Desktop/QuoteFasterShipSmarterDesktop'
import QuoteFasterShipSmarterMobile from '@/app/ui-new/pages/website/components/QuoteFasterShipSmarter/Mobile/QuoteFasterShipSmarterMobile'
import VideoHolderMobile from '@/app/ui-new/pages/website/components/VideoHolder/Mobile/VideoHolderMobile'
import NewEfficiencyZeroFrictionMobile
    from '@/app/ui-new/pages/website/components/NewEfficiencyZeroFriction/Mobile/NewEfficiencyZeroFrictionMobile'
import { hooks } from 'front-chat-sdk'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './index.i18n'
import { Helmet } from 'react-helmet'

/* SEO */
import SEO from '@/app/ui/seo'

const Website: React.FunctionComponent<Props> = () => {
    const isMobile = useMediaQuery('(max-width: 1230px)')
    const { t } = useI18n(i18n)

    hooks.useFrontChat('d0d79aa5a8fa420947b18833551d56d8')

    return (
        <>
            {/* SEO (START) */}
            <SEO
                pageTitle={t('Page Title')}
                pageDescription={t('Meta Description')}
                pageCanonical='https://lazr.io'
            />
            {/* SEO (END) */}

            {/*
            <Helmet>
                <title>{t('Page Title')}</title>
                <meta charSet="utf-8" />
                <meta name="description" content={t('Meta Description')} />
            </Helmet>
            */}

            {isMobile ? <BannerMobile/> : <BannerDesktop/>}
            {isMobile ? <CarriersCarouselMobile
                freightCarriers={freightCarriers}
                parcelCarriers={parcelCarriers}
                primaryLabel={'100+'}
                secondaryLabel={t('Trusted carriers are on Lazr')}/> :
                <CarriersCarouselDesktop
                    freightCarriers={freightCarriers}
                    parcelCarriers={parcelCarriers}
                    scrollableListWidth={900}
                    primaryLabel={'100+'}
                    secondaryLabel={t('Trusted carriers are on Lazr')}/>}
            {isMobile ? <VideoHolderMobile/> : <VideoHolderDesktop/>}
            {isMobile ? <OldVsNewWayMobile/> : <OldVsNewWayDesktop/>}
            {isMobile ? <CarouselMobile stepCards={stepCards}/> : <CarouselDesktop stepCards={stepCards}/>}
            {isMobile ? <FlipCardsMobile/> : <FlipCardsDesktop/>}
            {isMobile ? <NewEfficiencyZeroFrictionMobile/> : <NewEfficiencyZeroFrictionDesktop/>}
            {isMobile ? <TestimonialsCarouselMobile/> :
                <TestimonialsCarouselDesktop/>}
            {isMobile ? <DevelopedByMobile/> : <DevelopedByDesktop/>}
            {isMobile ? <QuoteFasterShipSmarterMobile/> : <QuoteFasterShipSmarterDesktop/>}
        </>
    )
}

export interface Props {}

export default Website
