import {
    Typography as MuiTypography,
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing as MuiSpacing } from '@material-ui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';

export const Typography = styled(styled(MuiTypography)(MuiSpacing))<{$fontSize?: number, $fontWeight?: number, $color?: string}>`
    font-size: ${(props): number => props.$fontSize ?? 16}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    color: ${(props): string => props.$color ?? props.theme.palette.black.main};
    display: inline-block;
    white-space: nowrap;
`

export const ButtonIcon = styled(styled(FontAwesomeIcon)(MuiSpacing))<{$color?: string}>`
    margin-right: 2px;
    margin-top: 4px;
    color: ${(props): string => props.$color ?? props.theme.palette.black.main};
`

export const Tooltip = styled(styled(MuiTooltip)(MuiSpacing))<TooltipProps>`
    + .lzr-popper {
        position: ${(props): string | undefined => props.$position || 'absolute'};
    }
    + .lzr-popper > .lzr-tooltip {
        padding: 16px;
        color: ${(props): string | undefined => props.color || '#ffffff'};
        background-color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
        width: ${(props): string | undefined => props.$width};
        border-radius: 4px;
        font-size: ${(props): string => props.theme.typography.body2.fontSize};
        font-weight: ${(props): string => props.theme.typography.body2.fontWeight};
        white-space: normal;
    }
    + .lzr-popper > div > span[class*='MuiTooltip-arrow-'] {
        color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
    }
    + .lzr-popper > .lzr-tooltip a {
        color: ${(props): string | undefined => props.color || '#ffffff'};
    }
`


export interface TooltipProps extends MuiTooltipProps {
    $bgColor?: string
    $width?: string
    $position?: string
}