import MDEditor from "@uiw/react-md-editor"
import FormSimple, { FieldConfig, SchemaField } from "../FormSimple"
import React, { useEffect, useState } from "react"
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import { z } from "zod"
import { flattenSchema } from "../helpers"
import i18n from './MarkdownForm.i18n';
import { useI18n } from "@/app/ui/components/hooks/I18n"
import { Typography } from "../FormSimple.styled"
import { borderColor } from "polished"



interface Props {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const MarkdownForm: React.FunctionComponent<Props> = ({ field, name, control, setValue, getValues, trigger, debouncedHandleOnChange, errors, error }) => {
    const { t } = useI18n(i18n);

    useEffect(() => {
        setValue(field.name, field.values)
        debouncedHandleOnChange?.()
    }, [field.values])

    return <Controller
        name={name ? name : field.name}
        control={control}
        defaultValue={field.values || ''}
        render={({ field: { onChange, value } }) => {
            return <>
                {field?.markdown?.title && <div>{field?.markdown?.title}</div>}
                <div style={errors[field.name] && { border: 'solid 1px #B71C1C', padding: '1px', borderRadius: '3px' }}>
                    <MDEditor height={'100vh'} value={value} data-color-mode="light"  onChange={
                        (e) => {
                          
                            onChange(e)
                            setValue(field.name, e, { shouldValidate: false })
                            // Trigger validation on change
                            trigger(field.name).then(() => {
                                const isFieldValid = !errors[field.name]
                                if (isFieldValid && onChange) {
                                    onChange(e)
                                    setValue(field.name, e, {
                                        shouldValidate: true,
                                    })
                                }
                                debouncedHandleOnChange?.({[field.name]: e})
                            })

                        }


                        // setMarkdown

                    } />
                </div>
                {errors[field.name] && (
                    <Typography variant='caption' color='error'>
                        {errors[field.name]?.message}
                    </Typography>
                )}
            </>
        }}
    />
}

export default MarkdownForm