import React, { useEffect } from 'react'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import Tooltip from '../../Tooltip/Tooltip'
import { QuestionIcon, FontAwesomeIcon, TextButton, Typography, Badge } from './ButtonTextWithBadgeForm.styled'
import { Grid } from '@material-ui/core'

export interface ButtonTextWithBadgeProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface ButtonTextWithBadgeFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
    onClick?: (value?: any) => void
}

const ButtonTextWithBadgeForm: React.FC<ButtonTextWithBadgeFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    onClick,
}) => {
    const { type, tooltip, spaceForError, subLabel, disabled, required, size, phoneNumber, startIcon, badgeCount } =
        field

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(field.name, field.values)
        debouncedHandleOnChange?.({[field.name]: field.values})
    }, [field.values])

    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field }) => {
                const errorField = error ? error : errors?.[field.name]
                return (
                    <>
                        <Badge badgeContent={badgeCount} color={errorField ? 'red' : '#000'}>
                            <TextButton
                                error={errorField}
                                disabled={disabled}
                                type={'button'}
                                size={size || 'small'}
                                onClick={(e: any) => {
                                    onClick?.()
                                }}
                            >
                                <Grid container>
                                    {/* {startIcon && <Grid item xs={12} md={1}>
                                        <Typography error={errorField}>{ startIcon }</Typography>
                                    </Grid>}
                                    <Grid item xs={12} md={startIcon ? 11 : 12}>
                                        <Typography error={errorField}>{ field.value }</Typography>
                                    </Grid> */}
                                        {startIcon && <Typography error={errorField}>{ startIcon }</Typography>}
                                        <Typography error={errorField}>{ field.value }</Typography>
                                </Grid>
                            </TextButton>
                        </Badge>
                        {tooltip &&
                            <Tooltip {...tooltip} >
                                <QuestionIcon>
                                    <FontAwesomeIcon icon={tooltip?.icon ? tooltip?.icon : ['far', 'question-circle']} />
                                </QuestionIcon>
                            </Tooltip>
                        }
                    </>
                    
                )
            }}
        />
    )
}

export default ButtonTextWithBadgeForm
