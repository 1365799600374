import React, { ReactChild, ReactElement, useEffect, useState } from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './HandlingUnitForm.i18n'
import type { FieldConfig, ResetForm } from '@/app/ui-new/components/Form/FormSimple'
import { z } from 'zod' // Import Zod
import FormSimple from '@/app/ui-new/components/Form/FormSimple'
import type { Pickup } from '@/app/model'
import { Grid, Typography } from '@material-ui/core'
import ToggleButtonGroup from '@/app/ui-new/components/ToggleButton/ToggleButtonGroup'
import ToggleButton from '@/app/ui-new/components/ToggleButton/ToggleButton'
import { DimensionUnit, WeightUnit } from '@lazr/enums'
import type { PickupUnit } from '@/app/model/Pickup'
import type { UuidV4 } from '@lazr/openapi-client'

interface PickupUnitIdOptional extends Omit<PickupUnit, 'id'> {
    id?: UuidV4
}
export interface HandlingUnitFormProps {
    resetForm?: ResetForm
    nameID?: string
    fields?: {
        quantity?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        length?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        width?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        height?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        weight?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        totalWeight?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
        averageWeight?: { hide?: boolean; label?: string; layoutConfig?: any; subLabel?: string }
    }
    headerForm?: any
    isAverage?: boolean
    handleOnChange?: (value: any) => void
    onError?: (value: any) => void
    defaultInfo?: any
    hideForm?: boolean
    forceSubmit?: boolean
}

const HandlingUnitForm: React.FunctionComponent<HandlingUnitFormProps> = ({ resetForm, nameID, fields, headerForm, isAverage = true, handleOnChange, onError, defaultInfo, hideForm=false, forceSubmit=false }) => {
    const { t } = useI18n(i18n)
    const [isImperial, setIsImperial] = useState(true)

    const [quantitySubLabel, setQuantitySubLabel] = useState(fields?.quantity?.subLabel ? fields?.quantity?.subLabel : t('Parcels'))
    const [lengthSubLabel, setLengthSubLabel] = useState(
        fields?.length?.subLabel ? fields?.length?.subLabel : isImperial ? t('Inches') : t('Centimeters'),
    )
    const [widthSubLabel, setWidthSubLabel] = useState(
        fields?.width?.subLabel ? fields?.width?.subLabel : isImperial ? t('Inches') : t('Centimeters'),
    )
    const [heightSubLabel, setHeightSubLabel] = useState(
        fields?.height?.subLabel ? fields?.height?.subLabel : isImperial ? t('Inches') : t('Centimeters'),
    )
    const [weigthSubLabel, setWeightSubLabel] = useState(
        fields?.weight?.subLabel ? fields?.weight?.subLabel : isImperial ? t('Pounds') : t('Kilograms'),
    )
    const [totalWeightSubLabel, setTotalWeightSubLabel] = useState(
        fields?.totalWeight?.subLabel ? fields?.totalWeight?.subLabel : isImperial ? t('Pounds') : t('Kilograms'),
    )
    const [averageWeightSubLabel, setAverageWeightSubLabel] = useState(
        fields?.length?.subLabel ? fields?.length?.subLabel : isImperial ? t('Pounds') : t('Kilograms'),
    )

    const [quantity, setQuantity] = useState(defaultInfo?.quantity ?? '')
    const [length, setLength] = useState(defaultInfo?.length ?? '')
    const [width, setWidth] = useState(defaultInfo?.width ?? '')
    const [height, setHeight] = useState(defaultInfo?.height ?? '')
    const [weight, setWeight] = useState(defaultInfo?.weight ?? '')
    const [weightUnit, setWeightUnit] = useState<WeightUnit>(defaultInfo?.weightUnit ?? (isImperial ? WeightUnit.LB : WeightUnit.KG))
    const [dimentionUnit, setDimentionUnit] = useState<DimensionUnit>(defaultInfo?.dimentionUnit ?? (isImperial ? DimensionUnit.IN : DimensionUnit.CM))
    const [totalWeight, setTotalWeight] = useState<number | string>(defaultInfo?.totalWeight ?? '')
    const [averageWeight, setAverageWeight] = useState<number | string>(defaultInfo?.averageWeight ?? '')
    //const validationMoreSizeSchema = validationMoreSizeSchema(t('String must contain at least 3 character(s)'))
    const [measurament, setMeasurement] = useState<string>(defaultInfo?.measurament ?? '')
  //  const [consolidateData, setConsolidateData] = useState<Pickup['units']>()

    const fieldsWithAutoTotalWeight: FieldConfig[] = [
        {
            name: 'weight',
            labelName: fields?.weight?.label ? fields?.weight?.label : t('Weight'),
            schema: fields?.weight?.hide
                ? z.any()
                : z.string().refine(
                      (val) => Number(val) > 0,
                      (val) => ({ message: ' ' }),
                  ),
            type: 'numberField',
            size: 'medium',
            subLabel: weigthSubLabel,
            values: weight,
            hide: fields?.weight?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.weight?.layoutConfig },
        },
        {
            name: 'totalWeight',
            labelName: fields?.totalWeight?.label ? fields?.totalWeight?.label : t('Total weight'),
            schema: z.any(),
            type: 'numberFieldBlue',
            size: 'medium',
            disabled: true,
            subLabel: totalWeightSubLabel,
            values: totalWeight as string,
            hide: fields?.totalWeight?.hide && !fields?.weight?.hide && !fields?.quantity?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.totalWeight?.layoutConfig },
        },
    ]

    const fieldsWithAutoAverageWeight: FieldConfig[] = [
        {
            name: 'weight',
            labelName: '',
            schema: z.any(),
            type: 'hiddenText',
            size: 'medium',
            values: averageWeight as string,
            hide: fields?.weight?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 0, ...fields?.weight?.layoutConfig },
        },
        {
            name: 'totalWeight',
            labelName: fields?.totalWeight?.label ? fields?.totalWeight?.label : t('Total weight'),
            schema: fields?.totalWeight?.hide
                ? z.any()
                : z.string().refine(
                      (val) => Number(val) > 0,
                      (val) => ({ message: ' ' }),
                  ),
            type: 'numberField',
            size: 'medium',
            subLabel: totalWeightSubLabel,
            values: totalWeight as string,
            hide: fields?.totalWeight?.hide && !fields?.weight?.hide && !fields?.quantity?.hide,
            layoutConfig: {
                id: 'totalWeight',
                style: { alignContent: 'end' },
                xs: 12,
                sm: 2,
                lg: 2,
                xl: 2,
                ...fields?.totalWeight?.layoutConfig,
            },
        },
        {
            name: 'averageWeight',
            labelName: fields?.averageWeight?.label ? fields?.averageWeight?.label : t('Average weight'),
            schema: z.any(),
            type: 'numberFieldBlue',
            size: 'medium',
            disabled: true,
            subLabel: averageWeightSubLabel,
            values: averageWeight as string,
            hide: fields?.averageWeight?.hide && !fields?.weight?.hide && !fields?.quantity?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.weight?.layoutConfig },
        },
    ]
    const fieldsAuto = (isAverage: boolean) => {
        if (isAverage) {
            return fieldsWithAutoAverageWeight
        }
        return fieldsWithAutoTotalWeight
    }

    const fieldsInside: FieldConfig[] = [
        {
            name: 'unit',
            schema: z.any(),
            type: 'hiddenText',
            values: measurament,
        },
        {
            name: 'quantity',
            labelName: fields?.quantity?.label ? fields?.quantity?.label : t('Quantity'),
            schema: fields?.quantity?.hide
                ? z.any()
                : z.string().refine(
                      (val) => Number(val) > 0,
                      (val) => ({ message: ' ' }),
                  ),
            type: 'numberField',
            size: 'medium',
            values: quantity,
            hide: fields?.quantity?.hide,
            subLabel: quantitySubLabel,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.quantity?.layoutConfig },
        },
        {
            name: 'length',
            labelName: fields?.length?.label ? fields?.length?.label : t('Length'),
            schema: fields?.length?.hide
                ? z.any()
                : z
                      .string()
                      .refine(
                          (val) => Number(val) > 0,
                          (val) => ({ message: ' ' }),
                      )
                      .refine(
                          (val) => Number(val) < (isImperial ? 84 : 214),
                          (val) => ({ message: `${t('Length needs to be smaller than')} ${isImperial ? `${84}"` : `${213.36} cm`}` }),
                      ),
            type: 'numberField',
            size: 'medium',
            subLabel: lengthSubLabel,
            values: length,
            hide: fields?.length?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.length?.layoutConfig },
        },
        {
            name: 'width',
            labelName: fields?.width?.label ? fields?.width?.label : t('Width'),
            schema: fields?.width?.hide
                ? z.any()
                : z.string().refine(
                      (val) => Number(val) > 0,
                      (val) => ({ message: ' ' }),
                  ),
            type: 'numberField',
            size: 'medium',
            subLabel: widthSubLabel,
            values: width,
            hide: fields?.width?.hide,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.width?.layoutConfig },
        },
        {
            name: 'height',
            labelName: fields?.height?.label ? fields?.height?.label : t('Height'),
            schema: fields?.height?.hide
                ? z.any()
                : z
                      .string()
                      .refine(
                          (val) => Number(val) > 0,
                          (val) => ({ message: ' ' }),
                      )
                      .refine(
                          (val) => Number(val) < (isImperial ? 96 : 244),
                          (val) => ({ message: `${t('Height needs to be smaller than')} ${isImperial ? `${96}"` : `${243.84} cm`}` }),
                      ),
            type: 'numberField',
            size: 'medium',
            subLabel: heightSubLabel,
            values: height,
            hide: fields?.height?.hide ? true : false,
            layoutConfig: { style: { alignContent: 'end' }, xs: 12, sm: 2, lg: 2, xl: 2, ...fields?.height?.layoutConfig },
        },
        ...fieldsAuto(isAverage),
    ]

    const isInteger = (num: number) => {
        return num % 1 === 0
    }

    const handleOnChangeInside = (value: any) => {
        const hideWeight = fields?.weight?.hide ? true : false
        const hideQuantity = fields?.quantity?.hide ? true : false
        const hideTotalWeight = fields?.totalWeight?.hide ? true : false
        value.weightUnit = isImperial ? "LB" : "KG"
        if (isAverage === false) {
            if (!hideWeight && !hideQuantity) {
                setTotalWeight(value.quantity * value.weight)
            }
            if (hideQuantity && !hideWeight) {
                setTotalWeight(value.weight)
            }
        } else {
            if (!hideTotalWeight && !hideQuantity) {
                const calcAverage = value.totalWeight / value.quantity

                setAverageWeight(isInteger(calcAverage) ? calcAverage : calcAverage.toFixed(2))
            }
            if (hideQuantity && !hideTotalWeight) {
                setAverageWeight(value.totalWeight)
            }
        }
  
        handleOnChange?.(value);
    }

    const handleChangeImperialMetric = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
        const isNotMetric = value !== 'metric'
        if (isNotMetric) {
            setIsImperial(true)
        } else {
            setIsImperial(false)
        }
        setMeasurement(isNotMetric ? `${DimensionUnit.IN}/${WeightUnit.LB}` : `${DimensionUnit.CM}/${WeightUnit.KG}`)
        setWeightUnit(isNotMetric ? WeightUnit.LB : WeightUnit.KG)
        setDimentionUnit(isNotMetric ? DimensionUnit.IN : DimensionUnit.CM)
        setLengthSubLabel(isNotMetric ? t('Inches') : t('Centimeters'))
        setWidthSubLabel(isNotMetric ? t('Inches') : t('Centimeters'))
        setHeightSubLabel(isNotMetric ? t('Inches') : t('Centimeters'))
        setWeightSubLabel(isNotMetric ? t('Pounds') : t('Kilograms'))

        return
    }

    return (
        <Grid container direction='row'>
            <Grid item container sm={3} align-content='center'>
                <Grid item xs={5} align-content='center'>
                    <Typography variant='h6' style={{ marginBottom: '15px' }}>
                        {t('Measurements')}
                    </Typography>
                </Grid>
                <Grid item xs={8} align-content='center'>
                    <ToggleButtonGroup
                        value={isImperial ? 'imperial' : 'metric'}
                        exclusive
                        onChange={handleChangeImperialMetric}
                        aria-label={t('Dimension unit selector')}
                    >
                        <ToggleButton value='imperial' aria-label='imperial' withSelectedIcon>
                            {t('in/lb')}
                        </ToggleButton>
                        <ToggleButton value='metric' aria-label='metric' withSelectedIcon>
                            {t('cm/kg')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Grid item sm={9}>
                <FormSimple
                    nameID={`${nameID}-form`}
                    fields={fieldsInside}
                    headerForm={headerForm}
                    layoutConfig={{ spacing: 4 }}
                    onChange={{validatedData: handleOnChangeInside}}
                    onError={onError}
                    resetForm={resetForm}
                    debouncer={{ wait: 1 }}
                    hideForm={true}
                    onSubmit={{
                        button: {
                            title: '',
                            hidden: true,
                        },
                        forceSubmit: forceSubmit,
                        fc: () => {}
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default HandlingUnitForm
