import React, { ReactElement, useEffect, useState } from "react"
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import { FieldConfig } from "../FormSimple"
import { Autocomplete } from "@material-ui/lab"
import { TextField as EmailTextField } from './EmailAutoComplete.styled'

export interface EmailAutoCompleteProps {
    namePrefix: string
    control: Control<FieldValues>
    errors: any
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    data: any
    // getOptionSelected: (option: any, value: any) => boolean
    // getOptionLabel: (option: any) => string
    // renderOption: any
    defaultValue?: any
    values?: any
    helperText?: string
}

const EmailAutoComplete: React.FunctionComponent<EmailAutoCompleteProps> = ({
    namePrefix,
    control,
    errors,
    data,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    // getOptionSelected,
    // getOptionLabel,
    // renderOption,
    defaultValue,
    values,
    helperText,
}) => {
    const [ inputValue, setInputValue ] = useState('')
    const [ emails, setEmails ] = useState<string[] | null>(values ?? [])
    const blurHandler = (currentInputValue: string) => {
        if (!currentInputValue || emails?.indexOf(currentInputValue) !== -1) {
            return
        }
        setEmails([ ...emails, currentInputValue ])
        setInputValue('')
    }
    useEffect(() => {
        setValue(namePrefix, emails, { shouldValidate: false })
    }, [emails])
    return (
        <Controller
            name={namePrefix}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => {
                return (
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={data}
                        value={emails ?? []}
                        freeSolo
                        onChange={(_event, newValue) => {
                            field.onChange(newValue)
                            setEmails(newValue as string[])
                            setValue(field.name, newValue, { shouldValidate: false })
                            // Trigger validation on change
                            trigger(field.name).then(() => {
                              const isFieldValid = !errors[field.name]
                              if (isFieldValid && field.onChange) {
                                field.onChange(newValue)
                                setValue(field.name, newValue, { shouldValidate: false })
                                debouncedHandleOnChange({[field.name]: newValue})
                              }
                            })
                          }}
                        renderInput={(params): ReactElement => (
                            <EmailTextField
                                className='billing-contact-emails'
                                onBlur={(e) => blurHandler(e.target.value.trim())}
                                onChange={(e) => {
                                    setInputValue(e.target.value)
                                }}
                                onKeyPress={(ev) => {
                                    if (ev.key === ' ' || ev.key === ',') {
                                        blurHandler(inputValue)
                                        ev.preventDefault()
                                    }}}
                                error={!!errors.billingContactEmails}
                                helperText={errors[field.name]?.message ?? (helperText ?? `Press enter between each different email addresses`)}
                                {...params}
                                variant="filled"
                                label="Email Addresses"
                                placeholder="Email addresses"
                                $hasError={!!errors[field.name]}
                            />
                        )}
                    />
                )
            }}
        />
    )
}

export default EmailAutoComplete