import { 
    Typography, 
    Tooltip as MuiTooltip,
    TypographyProps, 
    Grid as MuiGrid 
} from "@material-ui/core";
import { spacing as MuiSpacing } from '@material-ui/system'
import styled from "styled-components";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { IconButton } from '@/app/ui-new/components/IconButton/IconButton'
import { ButtonCardCheckboxProps, ButtonCardCheckboxColor } from "../GroupButton/GroupButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type StyledTypographyProps = TypographyProps & {fontSize?: string, fontWeight?: number}

export const StyledSubTitleTypography: React.FC<StyledTypographyProps> = styled(Typography)<StyledTypographyProps>`
    font-size: ${(props):string => props.fontSize ?? '1.125rem'};
    font-weight: ${(props):number => props.fontWeight ?? 400};
`

const getBackgroundColor = (props: any, type: 'light' | 'dark') => {
    switch (props.bcolor) {
        case ButtonCardCheckboxColor.OUTLINE:
            return props.theme.palette.white.main;
        case ButtonCardCheckboxColor.GRAY:
            return props.theme.palette.neutral100.main;
        case ButtonCardCheckboxColor.PURPLE:
            return type === 'dark' ? '#6512de' : '#6512decc';
        case ButtonCardCheckboxColor.GREEN:
            return type === 'dark' ? props.theme.palette.primary.main : props.theme.palette.accentPrimary700.main
        default: return 'red'
    }
}

const getFontColor = (props: any) => {
    switch (props.bcolor) {
        case ButtonCardCheckboxColor.OUTLINE:
        case ButtonCardCheckboxColor.GRAY:
            return props.theme.palette.primary.main;
        default: return props.theme.palette.white.main;
    }
}

export const BasicButtonCheckbox = styled(PrimaryButton)<{bcolor?: ButtonCardCheckboxProps['color']}>`
    background-color: ${(props): string => getBackgroundColor(props, 'dark')};
    color: ${(props): string => getFontColor(props)};
    text-wrap: white-space;
    padding: 10px;
    margin: 0;
    height: auto;
    width: 100%;
    &:hover {
        background-color: ${(props): string => getBackgroundColor(props, 'light')};
    }
`

export const GridButtonCheckbox = styled(styled(MuiGrid)(MuiSpacing))`
`


export const StyledIconBasicButtonCheckbox = styled(IconButton)`
    width: 20px;
    height: 20px;
    border: none;
    background: #00000033;

    &:hover {
        background: #00000033!important;
        border: none;

        svg path {
            fill: none;
        }
    }
    &:focus {
        outline: none;
        outline-offset: 0;
        border-color: ${(props): string =>  props.theme.palette.neutral500.main};
    }
`

export const Tooltip = styled(styled(MuiTooltip)(MuiSpacing))`
    margin-left: ${(props): string => props.theme.spacing(2)}px;
`

export const StyledIcon = styled(styled(FontAwesomeIcon)(MuiSpacing))``