import React, { ReactNode, ReactNodeArray, Children } from 'react'
import { renderToString } from 'react-dom/server'
import {
    Box,
    Typography,
} from './MoreLess.styled'

export const MoreLess = ({ text, truncateAtChar: truncatedLinesCount }: MoreLessProps) => {
    const [ more, setMore ] = React.useState(false)
    let truncatedText
    const regex = /<br>(?=(?:\s*<[^>]*>)*$)|(<br>)|<[^>]*>/gi;  // To strip HTML tags from child nodes.

    if (typeof text === 'string') {
        truncatedText = text
    } else {
        truncatedText = renderToString(text as any).replace(regex, (x,y) => y ? ' & ' : '')
    }

    return (
        <Box>
            <Typography>{!more ? `${truncatedText.slice(0, truncatedLinesCount)}...` : text}
                <span onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setMore(!more)
                }}>
                    {more ? ' less' : ' more'}
                </span>
            </Typography>
        </Box>
    )
}

export interface MoreLessProps{
    text: string | React.ReactNode
    truncateAtChar: number
}
