import moment from 'moment'
import {
    ACCEPTED,
    APPROVED,
    CUSTOM_QUOTE_COMPLETED,
    CUSTOM_QUOTE_REQUESTED,
    DRAFT,
    IN_REVISION,
    LAST30DAYS,
    LAST7DAYS,
    LASTMONTH,
    LASTYEAR,
    REVISED,
    SENT_TO_AGENT,
    THISMONTH,
    THISYEAR,
    TODAY,
    YESTERDAY,
} from '../redux/constants'
import {
    BLOCK_AND_BRACE_CODE,
    INVOICE_STATUS_MAPPING,
    ORDER_STATUS_MAPPING, ORGANIZATION_ROLE_MAPPING,
    RFQ_STATUS_MAPPING,
    SHIPMENT_IDENTIFIER_TYPE,
} from './constants'
import postalCodes from 'postal-codes-js'
import { Charge } from '../pages/orders/Details/Sections/components/CostCharges'
import {
    CurrencyRate,
    EventInfo,
    OrderInfo,
    OrderInfoQuote,
    OrderInfoRfqAccessorial,
    OrderListItem,
    OrderListItemQuote,
    Organization,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqHandlingUnitAccessorial,
    SearchRfq,
    User,
} from '../../model'
import {
    AccessorialLocationType,
    AccessorialType,
    ClientProfitabilityStats,
    Country,
    Currency,
    DimensionUnit,
    EquipmentType,
    EventType,
    InvoiceStatus,
    OrderStatus,
    PackageType,
    RfqStatus,
    ShipmentIdentifierType,
    TrackingUpdateStatus,
    TransportType,
} from '@lazr/openapi-client'
import { convertDimensionToImperial, formatCurrency } from '@lazr/utilities'
import { UserEventNotification } from '../../model/UserEventNotification'
import { AddressAttributes, BillingAddressAttributes, SaveableAddress } from '../../model/Address'
import { orderBy } from 'lodash'
import { PaymentTypeOption } from '@lazr/enums'

export const requiresClientAction = (status: string): boolean => [
    DRAFT,
    REVISED,
    CUSTOM_QUOTE_COMPLETED,
].includes(status)

export const requiresAgentAction = (status: string): boolean => [
    SENT_TO_AGENT,
    CUSTOM_QUOTE_REQUESTED,
    IN_REVISION,
    ACCEPTED,
    APPROVED,
].includes(status)

export const isAccessorialUsed = (
    accessorials: OrderInfoRfqAccessorial[] | SearchRfqAccessorial[] | undefined,
    accessorialCode: string,
): boolean => (
    !!accessorials?.some((accessorial: { accessorial: { code: string } }) => accessorial.accessorial.code === accessorialCode)
)

export const formatAccessorials = (accessorials: {
    accessorial: {
        name: string
    }
}[]): string =>
    accessorials.map((quoteAccessorial) => quoteAccessorial.accessorial.name).join(', ')

export const formatProductIndicator = ((
    productIndicatorIsUsmca: boolean | null,
    productIndicatorFda: boolean | null,
    documentIndicatorTextile: boolean | null,
): string => {
    const productIndicatorArray = []
    if (productIndicatorIsUsmca) {
        productIndicatorArray.push('USMCA')
    }
    if (productIndicatorFda) {
        productIndicatorArray.push('FDA (Food)')
    }
    if (documentIndicatorTextile) {
        productIndicatorArray.push('Textile')
    }
    if (productIndicatorArray.length === 0){
        productIndicatorArray.push('None')
    }

    return productIndicatorArray.join(', ')
})

export const formatDocumentIndicator = ((
    documentIndicatorUsmca: boolean | null,
    documentIndicatorFda: boolean | null,
    documentIndicatorFcc: boolean | null,
    senderIsProducerIndicator: boolean | null,
): string => {
    const documentIndicatorArray = []
    if (documentIndicatorUsmca) {
        documentIndicatorArray.push('USMCA Certificate')
    }
    if (documentIndicatorFda) {
        documentIndicatorArray.push('FDA2877')
    }
    if (documentIndicatorFcc) {
        documentIndicatorArray.push('FCC740')
    }
    if (senderIsProducerIndicator) {
        documentIndicatorArray.push('Sender is producer')
    }
    if (documentIndicatorArray.length === 0){
        documentIndicatorArray.push('None')
    }

    return documentIndicatorArray.join(', ')
})

export const formatZone = (city?: string | null, state?: string | null, country?: string | null): string => {
    const zones: |string[] = []

    if (city) {
        zones.push(city)
    }
    if (state) {
        zones.push(state)
    }
    if (country) {
        zones.push(country)
    }

    return zones.join(', ')
}

export const getAutomaticExcessiveLengthInFeet = (length: number, lengthUnit: DimensionUnit): number | null => {
    const correctUnitLength = convertDimensionToImperial(length, lengthUnit || DimensionUnit.IN).dimension

    if (correctUnitLength > 143) {
        let feet = Math.ceil(correctUnitLength / 12)
        feet = feet > 30 ? 30 : feet

        return feet
    }

    return null
}

export const shouldForceCustomQuoteForEquipmentType = (equipmentTypeId?: EquipmentType | null): boolean =>
    equipmentTypeId === EquipmentType.FLATBED
    || equipmentTypeId === EquipmentType.REEFER
    || equipmentTypeId === EquipmentType.SPECIALIZED

export const shouldForceCustomQuoteForTransportType = (transportTypeId?: TransportType): boolean =>
    transportTypeId === TransportType.FTL
    || transportTypeId === TransportType.OTHER

export const shouldForceCustomQuoteForCargo = (cargo: Partial<SearchRfqHandlingUnit>[], organizationName?: string, transportType?:TransportType): boolean =>
    cargo.some((handlingUnit) => {
        const length = convertDimensionToImperial(handlingUnit.length || 0, handlingUnit.lengthUnit || DimensionUnit.IN).dimension
        const width = convertDimensionToImperial(handlingUnit.width || 0, handlingUnit.widthUnit || DimensionUnit.IN).dimension
        const height = convertDimensionToImperial(handlingUnit.height || 0, handlingUnit.heightUnit || DimensionUnit.IN).dimension

        const numberOfPallets = cargo.reduce((totalQty, currentHandlingUnit) =>
            totalQty + (currentHandlingUnit.quantity ?? 0), 0)

        return width > 240
            || height > 102
            // || length > 144 This was moved to backend to only apply to RR
            || (width > 101 && length > 101)
            || handlingUnit.packageType === PackageType.OTHER
            || (organizationName === 'Pro-Label' && numberOfPallets >= 15 && transportType === TransportType.LTL)
    })

export const shouldForceCustomQuoteForLiftgateMaxHeight = (rfq: Partial<SearchRfq>): boolean => {
    if (!rfq.accessorials || !rfq.handlingUnits) {
        return false
    }

    return rfq.accessorials.some((acc) => acc.accessorial.code === 'LGPU' || acc.accessorial.code === 'LDEL') &&
        rfq.handlingUnits.some((hu) => hu.height && hu.height > 96)
}

export const shouldForceCustomQuoteForAccessorials = (
    accessorials: SearchRfqAccessorial[],
    cargoAccessorials: SearchRfqHandlingUnitAccessorial[],
): boolean => cargoAccessorials
    .concat(accessorials)
    .some((rfqAccessorial) => rfqAccessorial.accessorial.forceCustomQuote)

export const shouldForceCustomQuoteForBlockAndBrace = (
    accessorials: SearchRfqAccessorial[],
    cargo: Partial<SearchRfqHandlingUnit>[],
): boolean => {
    const containsBlockAndBrace = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === BLOCK_AND_BRACE_CODE)

    const containsForklift = cargo.some((handlingUnit) => handlingUnit.packageType === PackageType.FORKLIFT)

    return containsBlockAndBrace && !containsForklift
}
export const shouldForceCustomQuoteForInsideResidentialAccessorials = (
    accessorials: SearchRfqAccessorial[]): boolean => {
    const containsResidentialPuAcc = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === 'RESPU')
    const containsResidentialDelAcc = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === 'RESDEL')
    const containsInsidePuAcc = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === 'INPU')
    const containsInsideDelAcc = accessorials
        .some((rfqAccessorial) => rfqAccessorial.accessorial.code === 'INDEL')
  
    return (containsResidentialPuAcc && containsInsidePuAcc) || (containsResidentialDelAcc && containsInsideDelAcc)
}

export const checkIfNightlyHours = (open?: string | null, close?: string | null): boolean => {
    if (!open || !close) {
        return false
    }
    const openTime = moment(`${moment().format('YYYY-MM-DD')}T${open}`)
    const closeTime = moment(`${moment().format('YYYY-MM-DD')}T${close}`)

    return openTime.isAfter(closeTime)
}

export const getAccessorialHelperText = (type: AccessorialType, locationType?: AccessorialLocationType): string => {
    if (type === AccessorialType.COMMODITY) {
        return 'Handling unit accessorial'
    } else if (type === AccessorialType.SERVICE) {
        return 'Service level accessorial'
    } else if (locationType === AccessorialLocationType.ORIGIN) {
        return 'Accessorials that apply to the pick-up location'
    } else if (locationType === AccessorialLocationType.DESTINATION) {
        return 'Accessorials that apply to the delivery location'
    }

    return ''
}

export const formatChargeText = (chargeList: Charge[], code: string, description: string): string | null => {
    const matchingChargeDefinition = chargeList.find((chargeDefinition) => chargeDefinition.code === code)
    if (!matchingChargeDefinition?.name) {
        return null
    }
    if (!description) {
        return matchingChargeDefinition.name
    }

    return `${matchingChargeDefinition.name} (${description})`
}

export const validatePostalCode = (country: Country, postalCode: string): boolean => {
    const result = postalCodes.validate(country, postalCode)
    if (typeof result === 'boolean') {
        return result
    }

    return false
}

export const getUserRoleName = (user: User | null, userOrganization: Organization | null): string => {
    if (!user || !userOrganization){
        return 'Unknown'
    }

    return ORGANIZATION_ROLE_MAPPING[user.permissions.organizationRole.role]
}

export const getCurrencyRatePairFromList = (
    currencyRateList: CurrencyRate[],
    sourceCurrency: Currency,
    targetCurrency: Currency,
): CurrencyRate => {
    const currencyRate = currencyRateList.find((item) =>
        item.sourceCurrency === sourceCurrency && item.targetCurrency === targetCurrency)
    if (!currencyRate) {
        throw new Error('source/target pair does not exist')
    }

    return currencyRate
}

export const getOrderSellingPrice = (
    order: OrderInfo | OrderListItem,
    userCurrency: Currency,
    selectedQuote?: OrderInfoQuote | OrderListItemQuote | null,
): number | null => {
    let sellingPrice
    if (order?.rfq?.paymentType !== PaymentTypeOption.COLLECT && order.priceReviews && order.priceReviews.length > 0) {
        const latestPriceReview = order.priceReviews.sort(
            (a, b) => (a.createdAt > b.createdAt ? -1 : 1))[0]

        sellingPrice = ((userCurrency === Currency.CAD) ? latestPriceReview.totalCad : latestPriceReview.totalUsd) ?? 0
    }
    else if (selectedQuote) {
        sellingPrice = ((userCurrency === Currency.CAD) ? selectedQuote.costTotalCad : selectedQuote.costTotalUsd) ?? 0
    }
    else {
        sellingPrice = null
    }

    return sellingPrice
}

export const formatEventType = (eventInfo: EventInfo, user: User | null,
    userCurrency: Currency, omitOrderNumber = true): string => {
    const eventType: EventType = eventInfo.type
    const data: { [ key: string ]: any } = eventInfo.data
    const orderNumber: string = data.refs.orderNumber ?? ''
   
    let invoiceNumber = ''
    let documentName  = ''
    let fromStatus = ''
    let toStatus = ''
    let trackingUpdateType = ''
    let quoteCurrency =  Currency.CAD
    let quoteTotalPrice = 0
    let formattedQuoteTotalPrice = ''
    let description = ''
    let apiKeyName = ''
    let streetAddress =  ''
    let streetAddress2 =  ''
    let city = ''
    let stateOrProvince = ''
    let country = ''
    let postalOrZipCode = ''
    let shortFullName = ''
    let generatedSummaryOfOldFieldsAndValues = ''
    let generatedSummaryOfNewFieldsAndValues = ''
    let organizationName = ''
    let marketplaceStepName = ''
    switch (eventType) {
        case  EventType.ADDRESS_ADDED:
            streetAddress = data.refs.streetAddress ?? ''
            streetAddress2 = data.refs.streetAddress2 ?? ''
            city = data.refs.city ?? ''
            stateOrProvince = data.refs.stateOrProvince ?? ''
            country = data.refs.country ?? ''
            postalOrZipCode = data.refs.postalOrZipCode ?? ''

            description =  `Added new address: ${streetAddress2 ? streetAddress2 + ', ' : ''}${streetAddress},` +
                ` ${city}, ${stateOrProvince}, ${country}, ${postalOrZipCode}`
            break
        case EventType.ADDRESS_EDITED: {
            const oldStreetAddress: string = data.refs.oldStreetAddress ?? ''
            const oldStreetAddress2: string = data.refs.oldStreetAddress2 ?? ''
            const oldCity: string = data.refs.oldCity ?? ''
            const oldStateOrProvince: string = data.refs.oldStateOrProvince ?? ''
            const oldCountry: string = data.refs.oldCountry ?? ''
            const oldPostalOrZipCode: string = data.refs.oldPostalOrZipCode ?? ''

            const newStreetAddress: string = data.refs.newStreetAddress ?? ''
            const newStreetAddress2: string = data.refs.newStreetAddress2 ?? ''
            const newCity: string = data.refs.newCity ?? ''
            const newStateOrProvince: string = data.refs.newStateOrProvince ?? ''
            const newCountry: string = data.refs.newCountry ?? ''
            const newPostalOrZipCode: string = data.refs.newPostalOrZipCode ?? ''
            description = 'Changed address "'
            if (oldStreetAddress2 !== '')
            {
                description += `${oldStreetAddress2} `
            }
            if (oldStreetAddress !== '')
            {
                description += `${oldStreetAddress},`
            }
            if (oldCity !== '')
            {
                description += `${oldCity},`
            }
            if (oldStateOrProvince !== '')
            {
                description += `${oldStateOrProvince},`
            }
            if (oldCountry !== '')
            {
                description += `${oldCountry},`
            }
            if (oldPostalOrZipCode !== '')
            {
                description += `${oldPostalOrZipCode},`
            }
            description += `" to "${newStreetAddress2 ? newStreetAddress2 + ', ' : ''}${newStreetAddress}, ${newCity},` +
                ` ${newStateOrProvince}, ${newCountry}, ${newPostalOrZipCode}"`
            break
        }
        case EventType.ADDRESS_REMOVED:
            streetAddress = data.refs.streetAddress ?? ''
            streetAddress2 = data.refs.streetAddress2 ?? ''
            city = data.refs.city ?? ''
            stateOrProvince = data.refs.stateOrProvince ?? ''
            country = data.refs.country ?? ''
            postalOrZipCode = data.refs.postalOrZipCode ?? ''

            description = `Removed address: ${streetAddress2 ? streetAddress2 + ', ' : ''}${streetAddress},` +
                ` ${city}, ${stateOrProvince}, ${country}, ${postalOrZipCode}`
            break
        case EventType.API_KEY_DISABLED:
            apiKeyName = data.refs.apiKeyName ?? ''
            description = `Disabled API key: ${apiKeyName}`
            break
        case EventType.API_KEY_ENABLED:
            apiKeyName = data.refs.apiKeyName ?? ''
            description = `Enabled API key: ${apiKeyName}`
            break
        case EventType.API_KEY_GENERATED:
            apiKeyName = data.refs.apiKeyName ?? ''
            description = `Generated API key: ${apiKeyName}`
            break
        case EventType.API_KEY_REMOVED:
            apiKeyName = data.refs.apiKeyName ?? ''
            description = `Removed API key: ${apiKeyName}`
            break
        case EventType.ORDER_BILLING_CURRENCY_CHANGED: {
            const fromCurrency: string = data.fromCurrency ?? ''
            const toCurrency: string = data.toCurrency ?? ''
            description = `Changed the order billing currency from ${fromCurrency} to ${toCurrency}`
            break
        }
        case EventType.ORGANIZATION_REGISTERED:
            organizationName = data.refs.organizationName ?? ''
            description = `Registered the organization "${organizationName}"`
            break
        case EventType.ORGANIZATION_APPROVED:
            organizationName = data.refs.organizationName ?? ''
            description = `Approved the organization "${organizationName}"`
            break
        case EventType.ORGANIZATION_DEACTIVATED:
            organizationName = data.refs.organizationName ?? ''
            description = `Deactivated the organization "${organizationName}"`
            break
        case EventType.ORGANIZATION_DELETED:
            organizationName = data.refs.organizationName ?? ''
            description = `Deleted the organization "${organizationName}"`
            break
        case EventType.SEARCH_CREATED: {
            const transportType: string = data.refs.transportType ?? ''
            const originStreetAddress: string = data.refs.originStreetAddress ?? ''
            const originStreetAddress2: string = data.refs.originStreetAddress2 ?? ''
            const originCity: string = data.refs.originCity ?? ''
            const originStateOrProvince: string = data.refs.originStateOrProvince ?? ''
            const originCountry: string = data.refs.originCountry ?? ''
            const originPostalOrZipCode: string = data.refs.originPostalOrZipCode ?? ''
            const destinationStreetAddress: string = data.refs.destinationStreetAddress ?? ''
            const destinationStreetAddress2: string = data.refs.destinationStreetAddress2 ?? ''
            const destinationCity: string = data.refs.destinationCity ?? ''
            const destinationStateOrProvince: string = data.refs.destinationStateOrProvince ?? ''
            const destinationCountry: string = data.refs.destinationCountry ?? ''
            const destinationPostalOrZipCode: string = data.refs.destinationPostalOrZipCode ?? ''

            description = `Started a ${transportType} search for
            "${originStreetAddress2 ? originStreetAddress2 + ', ' : ''}${originStreetAddress}, ${originCity}, ${originStateOrProvince},` +
                ` ${originCountry}, ${originPostalOrZipCode}"
            to "${destinationStreetAddress2 ? destinationStreetAddress2 + ', ' : ''}${destinationStreetAddress}, ${destinationCity},` +
                ` ${destinationStateOrProvince},
             ${destinationCountry}, ${destinationPostalOrZipCode}"`
            break
        }
        case EventType.USER_ACTIVATED:
            shortFullName = data.refs.shortFullName ?? ''
            description = `Activated the user ${shortFullName}`
            break
        case EventType.USER_CONFIRMATION_CODE_RESENT:
            description =  'Requested resending the confirmation code'
            break
        case EventType.USER_DEACTIVATED:
            shortFullName = data.refs.shortFullName ?? ''
            description = `Deactivated the user ${shortFullName}`
            break
        case EventType.USER_INVITED:
            shortFullName = data.refs.shortFullName ?? ''
            description = `Created the user ${shortFullName}`
            break


        case EventType.USER_PROFILE_EDITED:
            generatedSummaryOfOldFieldsAndValues = data.refs.generatedSummaryOfOldFieldsAndValues ?? ''
            generatedSummaryOfNewFieldsAndValues = data.refs.generatedSummaryOfNewFieldsAndValues ?? ''
            description = 'Edited the profile'
            if (generatedSummaryOfOldFieldsAndValues !== '') {
                description += `from ${generatedSummaryOfOldFieldsAndValues}`
            }
            description += ` to ${generatedSummaryOfNewFieldsAndValues}`
            break
        case EventType.CURRENCY_EXCHANGE_RATE_CHANGED: {
            const  source: string = data.source
            const  target: string = data.target
            const  lazrRate: string = data.lazrRate
            description = `Changed the ${lazrRate} exchange rate from ${source} to ${target}`
            break
        }
        case EventType.ORDER_ABORTED:
            description = `Aborted ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += `  #${orderNumber}`
            }
            break
        case EventType.ORDER_ACCEPTED:
            description = `Accepted ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_BILL_OF_LADING_ADDED:
            description = 'Added the BOL'
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_BILL_OF_LADING_GENERATED:
            description = 'Generated the BOL'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_LABEL_ADDED:
            description = 'Added the Labels'
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_LABEL_GENERATED:
            description = 'Generated the Labels'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_COMMERCIAL_INVOICE_ADDED:
            description = 'Added the Commercial Invoice'
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_COMMERCIAL_INVOICE_GENERATED:
            description = 'Generated the Commercial Invoice'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_LOAD_TENDER_ADDED:
            description = 'Added the Load Tender'
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_LOAD_TENDER_GENERATED:
            description = 'Generated the Load Tender'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_ETD_UPLOADED:
            documentName  = data.refs.documentName ?? ''
            description = `Uploaded "${documentName}" via ETD`
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_BOOKED:
            description = `Booked ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_CANCELLED:
            description = `Cancelled ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_CLOSED:
            description = `Closed ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_CUSTOM_QUOTE_ADDED:
            quoteCurrency = userCurrency
            quoteTotalPrice = userCurrency === Currency.CAD ? data.costTotalCad : data.costTotalUsd
            description = 'Added a custom quote'
            if (!isNaN(quoteTotalPrice)) {
                formattedQuoteTotalPrice = formatCurrency(quoteTotalPrice, quoteCurrency, user?.locale.code)
                description += ` of ${formattedQuoteTotalPrice}`
            }
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_DECLINED:
            description = `Declined ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_DISPATCHED:
            description = `Dispatched ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.PICKUP_SCHEDULED:
            description = `Pickup scheduled for ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_DOCUMENT_ADDED:
            documentName  = data.refs.documentName ?? ''
            description = `Added the document "${documentName}" to ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_DOCUMENT_FETCHED:
            documentName  = data.refs.documentName ?? ''
            description = `Fetched the document "${documentName}" from ${omitOrderNumber ? 'the' : ''} order `
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_DOCUMENT_REMOVED:
            documentName  = data.refs.documentName ?? ''
            description = `Removed the document "${documentName}" from ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_DRAFTED:
            description = 'Saved the order as a draft'
            if (!omitOrderNumber) {
                description = `Saved order #${orderNumber} as a draft`
            }
            break
        case EventType.ORDER_DRAFT_SUBMITTED:
            description = `Submitted ${omitOrderNumber ? 'the' : ''} draft order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_EDITED:
            generatedSummaryOfOldFieldsAndValues = data.refs.generatedSummaryOfOldFieldsAndValues ?? ''
            generatedSummaryOfNewFieldsAndValues = data.refs.generatedSummaryOfNewFieldsAndValues ?? ''
            description = `Edited ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            if (generatedSummaryOfOldFieldsAndValues !== '') {
                description += ` from "${generatedSummaryOfOldFieldsAndValues}`
            }
            description += ` to "${generatedSummaryOfNewFieldsAndValues}"`
            break
        case EventType.ORDER_INVOICED:
            invoiceNumber = data.refs.invoiceNumber ?? ''

            description = `Generated invoice #${invoiceNumber} on ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description +=  `#${orderNumber}`
            }
            break
        case EventType.ORDER_MARKED_AS_REVISED:
            description = 'Marked the order as "Revised"'
            if (!omitOrderNumber) {
                description = `Marked order #${orderNumber} as "Revised"`
            }
            break
        case EventType.ORDER_OPENED:
            description = `Opened ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_PRIVATE_NOTES_SAVED:
            description = 'Saved a private note'
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_QUOTE_SELECTED:
            quoteCurrency = userCurrency
            quoteTotalPrice = quoteCurrency ===  Currency.CAD ? data.quoteTotalPriceCad : data.quoteTotalPriceUsd

            description = 'Selected a new quote'
            if (data.refs.carrierDisplayName) {
                const carrierDisplayName: string = data.refs.carrierDisplayName
                description += ` with the carrier ${carrierDisplayName}`
            }

            if (!isNaN(quoteTotalPrice)) {
                formattedQuoteTotalPrice = formatCurrency(quoteTotalPrice, quoteCurrency, user?.locale.code)
                description += ` at ${formattedQuoteTotalPrice}`
            }

            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_PRICE_REVIEWED:
            description = `Reviewed the price of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.PRICE_REVIEW_DELETED:
            description = `Removed the price review of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_REJECTED:
            description = `Rejected ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_RFQ_STATUS_CHANGED:
            fromStatus = data.fromStatus ? RFQ_STATUS_MAPPING[data.fromStatus as RfqStatus] : ''
            toStatus = data.toStatus ? RFQ_STATUS_MAPPING[data.toStatus as RfqStatus] : ''
            description = `Changed status from "${fromStatus}" to "${toStatus}"`
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_SHIPMENT_CARRIER_NUMBER_SET: {
            const shipmentIdentifierType: ShipmentIdentifierType = data.refs.shipmentIdentifierType ?? ''
            const shipmentIdentifierValue: string = data.refs.shipmentIdentifierValue ?? ''
            description = `Added or changed ${SHIPMENT_IDENTIFIER_TYPE[shipmentIdentifierType]} to ${shipmentIdentifierValue}`
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        }
        case EventType.ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_SET: {
            const estimatedDeliveryDate: string = data.estimatedDeliveryDate ?
                moment(data.estimatedDeliveryDate as string).format('MMM D, YYYY at HH:mm a') : ''
            description = `Set the estimated delivery date to ${estimatedDeliveryDate}`
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        }
        case EventType.ORDER_SHIPMENT_TRACKING_ENGAGED:
            description = 'Engaged the automatic shipment tracking updates'
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_SHIPMENT_TRACKING_UPDATE_ADDED:
            trackingUpdateType = data.refs.trackingUpdateType ?? ''
            description = `Added shipment tracking update "${trackingUpdateType}"`
            if (!omitOrderNumber) {
                description += ` to order #${orderNumber}`
            }
            break
        case EventType.ORDER_SHIPMENT_TRACKING_UPDATE_REMOVED:
            trackingUpdateType = data.refs.trackingUpdateType ?? ''
            description = `Removed shipment tracking update "${trackingUpdateType}"`
            if (!omitOrderNumber) {
                description += ` from order #${orderNumber}`
            }
            break
        case EventType.ORDER_STATUS_CHANGED:
            fromStatus =  data.fromStatus ? ORDER_STATUS_MAPPING[data.fromStatus as OrderStatus] : ''
            toStatus = data.toStatus ? ORDER_STATUS_MAPPING[data.toStatus as OrderStatus] : ''
            description = `Changed status from "${fromStatus}" to "${toStatus}"`
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_SUBMITTED:
            description = `Submitted ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.USER_AUTHENTICATED:
            description = 'Signed in'
            break
        case EventType.USER_AVATAR_CHANGED:
            description = 'Changed avatar'
            break
        case EventType.USER_LOGGED_OUT:
            description = 'Signed out'
            break
        case EventType.USER_PASSWORD_CHANGED:
            description = 'Changed password'
            break
        case EventType.USER_PASSWORD_CHANGE_REQUESTED:
            description = 'Requested a password change'
            break
        case EventType.USER_REGISTERED:
            description = 'Registered'
            break
        case EventType.USER_SIGN_UP_CONFIRMED:
            description = 'Confirmed sign up'
            break
        case EventType.ORDER_SHIPMENT_AND_PICKUP_CANCELLED: {
            const type: ShipmentIdentifierType = data.type ?? ''
            const value : string = data.value ?? ''

            description = `Cancelled shipment and pickup ${SHIPMENT_IDENTIFIER_TYPE[type]} ${value} `
            if (!omitOrderNumber) {
                description += `of order #${orderNumber}`
            }
            break
        }
        case EventType.ORDER_INVOICE_STATUS_CHANGED:
            invoiceNumber = data.refs.invoiceNumber ?? ''
            fromStatus = data.fromStatus ? INVOICE_STATUS_MAPPING[data.fromStatus as InvoiceStatus] : ''
            toStatus = data.toStatus ? INVOICE_STATUS_MAPPING[data.toStatus as InvoiceStatus] : ''
            description = `Changed status of invoice #${invoiceNumber} from "${fromStatus}" to "${toStatus}"`
            if (!omitOrderNumber) {
                description += ` on order #${orderNumber}`
            }
            break
        case EventType.ORDER_INVOICE_EDITED:
            invoiceNumber = data.refs.invoiceNumber ?? ''
            generatedSummaryOfOldFieldsAndValues = data.refs.generatedSummaryOfOldFieldsAndValues ?? ''
            generatedSummaryOfNewFieldsAndValues = data.refs.generatedSummaryOfNewFieldsAndValues ?? ''
            description = `Edited the invoice #${invoiceNumber} of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            if (generatedSummaryOfOldFieldsAndValues !== '') {
                description += ` from "${generatedSummaryOfOldFieldsAndValues}`
            }
            description += ` to "${generatedSummaryOfNewFieldsAndValues}"`
            break
        case EventType.ORDER_INVOICE_PAYMENT_ADDED:
            invoiceNumber = data.refs.invoiceNumber ?? ''
            description = `The payment was added to the invoice #${invoiceNumber}`
            break
        case EventType.ORDER_INVOICE_SENT:
            invoiceNumber = data.refs.invoiceNumber ?? ''
            description = `Sent the invoice #${invoiceNumber} of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_CLIENT_COMPLETED:
            description = `Completed ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_THREE_PL_COMPLETED:
            description = `Completed ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.EDI_ORDER_ACCEPTED:
            description = 'EDI order was accepted'
            break
        case EventType.EDI_ORDER_ADDED:
            description = 'EDI order was received'
            break
        case EventType.EDI_ORDER_CANCELLED:
            description = 'EDI order was cancelled'
            break
        case EventType.EDI_ORDER_DECLINED:
            description = 'EDI order was declined'
            break
        case EventType.EDI_ORDER_EXPIRED:
            description = 'EDI order has expired'
            break
        case EventType.EDI_ORDER_INVOICE_ADDED:
            description = `EDI invoice was added with a total amount of ${String(data.currency)} ${String(data.amount)}`
            break
        case EventType.EDI_ORDER_REVISION_ADDED:
            description = `EDI order revision (${String(data.revision)}) was received`
            break
        case EventType.EDI_ORDER_TRACKING_UPDATE_ADDED:
            description = `EDI order tracking update of "${String(data.newStatus)}" was added`
            break
        case EventType.EDI_ORDER_TRANSFERRED:
            description = 'EDI order was transferred'
            break
        case EventType.ORDER_INVOICE_GENERATED:
            description = 'Downloaded the invoice'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_QUOTE_CHANGED:
            description = 'Changed quote information'
            if (!omitOrderNumber) {
                description += ` for order #${orderNumber}`
            }
            break
        case EventType.ORDER_TRANSPORT_INVOICE_DOCUMENT_ADDED:
        case EventType.ORDER_INVOICE_SUPPORT_DOCUMENT_ADDED:
            documentName  = data.refs.documentName ?? ''
            description = `Added the document "${documentName}" to ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break

        case EventType.MARKETPLACE_STEP_NAVIGATION_RECORDED:
            marketplaceStepName = data.refs.stepName
            description = `Changed marketplace step to ${marketplaceStepName}`
            break
        case EventType.ORDER_INVOICE_CSV_BATCH_ADDED:
            documentName  = data.refs.fileName ?? ''
            description = `Added the invoice csv batch "${documentName}"`
            break
        case EventType.ORDER_INVOICE_CSV_BATCH_PROCESSED:
            documentName  = data.refs.fileName ?? ''
            description = `Processed the invoice csv batch "${documentName}"`
            break
        case EventType.ORDER_PENDING_PRICE_REVIEW_EDITED:
            description = `Edited the pending price review of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
        case EventType.ORDER_PENDING_PRICE_REVIEW_REJECTED:
            description = `Rejected the pending price review of ${omitOrderNumber ? 'the' : ''} order`
            if (!omitOrderNumber) {
                description += ` #${orderNumber}`
            }
            break
    }

    return description
}
export  const cutString = (initValue: string, maxCharacters: number): {
    isCut: boolean
    result: string
} => {
    let resultValue: string
    let isStringCut = false
    if (initValue.length > maxCharacters) {
        resultValue = `${initValue.substr(0, maxCharacters)}...`
        isStringCut = true
    } else {
        resultValue = initValue
    }

    return {
        isCut: isStringCut,
        result: resultValue,
    }
}

export const roundCurrency = (num: number): number => Math.round(num * 100) / 100

export const statsToCsv = (stats?: any[]): string => {
    if (!stats) {
        return ''
    }

    const keys = `"${Object.keys(stats[0]).join('","')}"`

    const values = stats.map((item) => {
        const columns = []

        for (const [ key, value ] of Object.entries(item)) {
            columns.push(String(value).replace(/"/g, '""'))
        }

        return `"${columns.join('","')}"`
    }).join('\n')

    return [ keys, values ].join('\n')
}

export const clientProfitabilityStatsToCsv = (stats?: ClientProfitabilityStats): string => {
    if (!stats) {
        return ''
    }

    const keys = `"Client","Type","${stats.columns.map((col) => `${col.year}-${col.month}-${col.day}`).join('","')}"`

    const values = []

    for (const [ client, periods ] of Object.entries(stats.data)) {
        let rowValues: (string | number)[] = [ client, 'Net Profit' ]

        stats.columns.forEach((column) => {
            const rowKey = `${column.year}-${column.month}-${column.day}`
            rowValues.push((periods.breakdown[rowKey].netProfit).toFixed(2))
        })

        values.push(`"${rowValues.join('","')}"`)

        rowValues = [ client, 'Lazr Cost' ]

        stats.columns.forEach((column) => {
            const rowKey = `${column.year}-${column.month}-${column.day}`
            rowValues.push((periods.breakdown[rowKey].lazrCost).toFixed(2))
        })

        values.push(`"${rowValues.join('","')}"`)

        rowValues = [ client, 'Selling' ]

        stats.columns.forEach((column) => {
            const rowKey = `${column.year}-${column.month}-${column.day}`
            rowValues.push((periods.breakdown[rowKey].selling).toFixed(2))
        })

        values.push(`"${rowValues.join('","')}"`)

        rowValues = [ client, 'Lazr Share' ]

        stats.columns.forEach((column) => {
            const rowKey = `${column.year}-${column.month}-${column.day}`
            rowValues.push((periods.breakdown[rowKey].lazrShare).toFixed(2))
        })

        values.push(`"${rowValues.join('","')}"`)
    }

    return [ keys, values.join('\n') ].join('\n')
}

export const downloadFile = (data: string, fileName: string, mimeType = 'octet/stream'): void => {
    const a = document.createElement('a')

    const blob = new Blob([ data ], { type: mimeType })
    const url = window.URL.createObjectURL(blob)

    a.setAttribute('style', 'display: none')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName)

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}

export const getStartAndEndDatesFromPeriod = (
    period: string,
): {
    startDate: string
    endDate: string
} => {
    switch (period) {
        case YESTERDAY:
            return {
                startDate: moment().subtract(1, 'days').startOf('day').toISOString(),
                endDate: moment().subtract(1, 'days').endOf('day').toISOString(),
            }
        case LAST7DAYS:
            return {
                startDate: moment().subtract(6, 'days').startOf('day').toISOString(),
                endDate: moment().endOf('day').toISOString(),
            }
        case LAST30DAYS:
            return {
                startDate: moment().subtract(29, 'days').startOf('day').toISOString(),
                endDate: moment().endOf('day').toISOString(),
            }
        case THISMONTH:
            return {
                startDate: moment().startOf('month').toISOString(),
                endDate: moment().endOf('month').toISOString(),
            }
        case LASTMONTH:
            return {
                startDate: moment().subtract(1, 'months').startOf('month').toISOString(),
                endDate: moment().subtract(1, 'months').endOf('month').toISOString(),
            }
        case THISYEAR:
            return {
                startDate: moment().startOf('year').toISOString(),
                endDate: moment().endOf('year').toISOString(),
            }
        case LASTYEAR:
            return {
                startDate: moment().subtract(1, 'years').startOf('year').toISOString(),
                endDate: moment().subtract(1, 'years').endOf('year').toISOString(),
            }
        case TODAY:
        default:
            return {
                startDate: moment().startOf('day').toISOString(),
                endDate: moment().endOf('day').toISOString(),
            }
    }
}

export const getUserEventNotificationMessageAndLink = (
    event: UserEventNotification,
): {
    message: string
    linkTo: string
    timeAgo: string
} => {
    let ediOrderId = ''
    let orderId = ''
    let orderNumber = ''
    let message = ''
    let linkTo = ''
    let consolidationNumber = ''
    let invoiceId = ''
    let invoiceNumber = ''

    switch (event.eventType) {
        case EventType.ORDER_RFQ_STATUS_CHANGED:
            orderId = String(event.eventData.refs?.orderId)
            orderNumber = String(event.eventData.refs?.orderNumber)
            linkTo = `/order/${orderId}`
            switch (event.eventData.toStatus) {
                case RfqStatus.ABORTED:
                    message = `Order #${orderNumber} was aborted`
                    break
                case RfqStatus.ACCEPTED:
                    message = `Order #${orderNumber} revision was accepted`
                    break
                case RfqStatus.BOOKED:
                    message = `Order #${orderNumber} was booked`
                    break
                case RfqStatus.CANCELLED:
                    message = `Order #${orderNumber} was cancelled`
                    break
                case RfqStatus.CARRIER_DISPATCHED:
                    message = `Order #${orderNumber} carrier was dispatched`
                    break
                case RfqStatus.DECLINED:
                    message = `Order #${orderNumber} was declined by the 3PL`
                    break
                case RfqStatus.EXPIRED:
                    message = `Order #${orderNumber} has expired`
                    break
                case RfqStatus.REJECTED:
                    message = `Order #${orderNumber} revision was rejected`
                    break
                case RfqStatus.REVISED:
                    message = `Order #${orderNumber} was revised`
                    break
                case RfqStatus.SUBMITTED:
                    message = `Order #${orderNumber} was submitted`
                    break
            }
            break
        case EventType.ORDER_DISPATCHED:
            orderId = String(event.eventData.refs?.orderId)
            orderNumber = String(event.eventData.refs?.orderNumber)
            linkTo = `/order/${orderId}`
            message = `Order #${orderNumber} carrier was dispatched`
            break
        case EventType.ORDER_SHIPMENT_TRACKING_UPDATE_ADDED:
            orderId = String(event.eventData.refs?.orderId)
            orderNumber = String(event.eventData.refs?.orderNumber)
            linkTo = `/order/${orderId}`
            switch (event.eventData.trackingUpdateStatus) {
                case TrackingUpdateStatus.DELIVERED:
                    message = `Order #${orderNumber} shipment was delivered`
                    break
                case TrackingUpdateStatus.NOT_DELIVERED:
                    message = `Order #${orderNumber} shipment was not delivered`
                    break
            }
            break
        case EventType.ORDER_INVOICED:
            invoiceId = String(event.eventData.refs?.invoiceId)
            invoiceNumber = String(event.eventData.refs?.invoiceNumber)
            message = `Invoice #${invoiceNumber} was created`
            linkTo = `/invoice/${invoiceId}`
            break
        case EventType.INVOICE_CONSOLIDATION_ADDED:
            consolidationNumber = String(event.eventData.refs?.number)
            message = `Consolidation #${consolidationNumber} was created`
            linkTo = '/invoices'
            break
        case EventType.EDI_ORDER_ACCEPTED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order was accepted'
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_ADDED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order was received'
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_CANCELLED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order was cancelled'
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_DECLINED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order was declined'
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_EXPIRED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order has expired'
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_INVOICE_ADDED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI invoice was added with a total amount of '
                + `${String(event.eventData.currency)} ${String(event.eventData.amount)}`
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_REVISION_ADDED:
            ediOrderId = String(event.eventEntityId)
            message = `An EDI order revision (${String(event.eventData.revision)}) was received`
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_TRACKING_UPDATE_ADDED:
            ediOrderId = String(event.eventEntityId)
            message = `An EDI order tracking update of "${String(event.eventData.newStatus)}" was added`
            linkTo = `/edi/${ediOrderId}`
            break
        case EventType.EDI_ORDER_TRANSFERRED:
            ediOrderId = String(event.eventEntityId)
            message = 'An EDI order was transferred'
            linkTo = `/edi/${ediOrderId}`
            break
        default:
            message = event.eventType
    }

    return {
        message,
        linkTo,
        timeAgo: moment.min(moment(event.notificationCreatedAt), moment()).fromNow(),
    }
}

export const isAddressComplete = (address: AddressAttributes | SaveableAddress | BillingAddressAttributes): boolean => {
    if (!address.country ||
        !address.city ||
        !address.state ||
        !address.openTime ||
        !address.closeTime ||
        !address.postalCode ||
        !address.addressType ||
        !address.companyName
    ) {
        return false
    }
    if (address.isBilling && (!address.billingContactName ||
        !address.billingContactPhone ||
        !address.billingContactEmails))
    {
        return false
    }

    return !(address.isShipping && (!address.shippingContactName ||
        !address.shippingContactPhone ||
        !address.shippingContactEmails))
}

export const  alternateSort = <T>(array: T[], sortAttribute: string): T[] => {
    // array the input array
    array = orderBy(array, [ sortAttribute ], [ 'asc' ])

    // Split the input array into two halves
    const middleIndex = Math.ceil(array.length / 2)
    const firstHalf = array.slice(0, middleIndex)
    const secondHalf = array.slice(middleIndex)

    // Create a new array with alternating elements from each half
    const result = []
    for (let i = 0; i < Math.min(firstHalf.length, secondHalf.length); i++) {
        result.push(firstHalf[i])
        result.push(secondHalf[i])
    }
    if (firstHalf.length > secondHalf.length) {
        result.push(...firstHalf.slice(secondHalf.length))
    } else if (secondHalf.length > firstHalf.length) {
        result.push(...secondHalf.slice(firstHalf.length))
    }

    return result
}
