// biome-ignore lint/style/useImportType: <explanation>
import React, { ReactElement, useEffect, useState } from 'react'
import { Chip, Grid } from '@material-ui/core'
import { type Control, Controller, type FieldValues, useForm } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import { validate as validateEmail } from 'email-validator'
import TextField from '../../TextField/TextField'

import { Autocomplete } from '@material-ui/lab'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { loadComponent } from '../helpers'

import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './MultipleValuesForm.i18n'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'

const emailSchema = z.string().email()
export const multipleValuesSchema = z.array(emailSchema)

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface MultipleValuesFormProps {
    name?: string
    mainControl: Control<FieldValues>
    setMainValue: (name: string, value: any, options?: any) => void
    getMainValues: (name?: string, options?: any) => void
    mainTrigger: (name: string) => Promise<boolean>
    mainDebouncedHandleSubmit: (value?: any) => void
    field: FieldConfig
    mainErrors?: any
    error?: any
    helperText?: any
}

const MultipleValuesForm: React.FC<MultipleValuesFormProps> = ({
    name,
    field,
    mainControl,
    mainErrors,
    error,
    helperText,
    setMainValue,
    getMainValues,
    mainTrigger,
    mainDebouncedHandleSubmit,
}) => {
    const fieldMultipleValues = field.multipleValues
    if (!fieldMultipleValues) {
        return <></>
    }

    const { t } = useI18n(i18n)

    const schema = z.object({ [fieldMultipleValues.name]: fieldMultipleValues.schema })

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        trigger,
        reset,
        getValues,
        getFieldState,
        setValue,
    } = useForm({
        resolver: zodResolver(schema),
    })
    const mainField = field

    const [inputValue, setInputValue] = useState<string>('')
    const [multipleValues, setMultipleValues] = useState<FieldConfig['values']>(mainField?.values ?? [])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (mainField?.values) {
            const data = mainField?.values
            setMainValue(field.name, Object.values(data))
            setValue(fieldMultipleValues.name, '')
        }
    }, [mainField?.values])

    const handleChange = (value: string[]) => {
        setMultipleValues(value)

        setMainValue(mainField.name, value)
    }

    const handleSubmitData = async (data: any) => {
        const email = data.email
        const arrayEmail: any = multipleValues
        if (Array.isArray(multipleValues) && multipleValues?.indexOf(email) === -1) {
            arrayEmail.push(email)
            setMultipleValues(arrayEmail)
            setValue(fieldMultipleValues.name, '')
            setMainValue(field.name, multipleValues)
            mainDebouncedHandleSubmit?.({[field.name]: multipleValues})
        }
    }
    const DynamicComponent: any = loadComponent(fieldMultipleValues.type)

    return (
        <Grid container justify-content='space-between' spacing={2}>
            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name={fieldMultipleValues.name}
                            control={control}
                            defaultValue={''}
                            render={({ field }) => {
                                const errorField = error ? error : errors?.[fieldMultipleValues.name] ?? mainErrors?.[mainField.name]
                                const helperTextField = helperText
                                    ? helperText
                                    : errors?.[fieldMultipleValues.name]?.message ?? mainErrors?.[mainField.name]?.message

                                return (
                                    <DynamicComponent
                                        {...field}
                                        variant={
                                            fieldMultipleValues.type === 'textOutlined' || fieldMultipleValues.type === 'textSearchBar'
                                                ? 'outlined'
                                                : 'standard'
                                        }
                                        width={'100%'}
                                        type={fieldMultipleValues.type === 'hiddenText' ? 'hidden' : 'text'}
                                        label={fieldMultipleValues.label}
                                        required={mainField.required || false}
                                        fullWidth
                                        // tooltip={tooltip}
                                        placeholder={fieldMultipleValues.placeholder}
                                        error={!!errorField}
                                        helperText={helperTextField}
                                        size={fieldMultipleValues.size || 'small'}
                                        value={field.value ? field.value : ''}
                                        InputProps={null}
                                        onKeyUp={(e: any) => {
                                            const inputValue = e.target.value
                                            if (field.onChange) {
                                                field.onChange(inputValue)
                                                setValue(field.name, inputValue, {
                                                    shouldValidate: false,
                                                })
                                            }

                                            // Trigger validation on change
                                            trigger(field.name).then(() => {
                                                const isFieldValid = !errorField
                                                if (isFieldValid && field.onChange) {
                                                    field.onChange(inputValue)
                                                    setValue(field.name, inputValue, {
                                                        shouldValidate: false,
                                                    })
                                                }
                                            })
                                            if (e.key === 'Enter') {
                                                handleSubmit(handleSubmitData)()
                                            }
                                        }}
                                    />
                                )
                            }}
                        />

                        <Controller
                            name={field.name}
                            control={mainControl}
                            defaultValue={field.defaultValue || []}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Autocomplete
                                        id={`${name}-multiple-${field.name}`}
                                        multiple
                                        value={value ?? null}
                                        options={value ?? null}
                                        getOptionDisabled={(option) => !!(value && option === value?.[0])}
                                        open={false}
                                        forcePopupIcon={false}
                                        onChange={(event, values: string[]) => {
                                            handleChange(values.filter(validateEmail))
                                            onChange(values.filter(validateEmail))
                                            setMainValue(field.name, values.filter(validateEmail), { shouldValidate: false })
                                            // Trigger validation on change
                                            mainTrigger(field.name).then(() => {
                                                const isFieldValid = !mainErrors[field.name]
                                                if (isFieldValid && onChange) {
                                                    onChange(values.filter(validateEmail))
                                                    setMainValue(field.name, values.filter(validateEmail), {
                                                        shouldValidate: false,
                                                    })
                                                }
                                                mainDebouncedHandleSubmit?.()
                                            })
                                        }}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    key={`${name}-${field.name}-chips-${index}`}
                                                    label={option}
                                                    variant='outlined'
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params): ReactElement => (
                                            <>
                                                <TextField
                                                    value={inputValue}
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        readOnly: true,
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={3} style={{ paddingTop: fieldMultipleValues.label ? '31px' : '3px' }}>
                <PrimaryButton
                    fullWidth
                    type='button'
                    size={fieldMultipleValues.size || 'medium'}
                    onClick={handleSubmit(handleSubmitData)}
                    disabled={errors?.[fieldMultipleValues.name] ? true : false}
                    style={
                        errors?.[fieldMultipleValues.name] && {
                            background: 'rgba(0, 0, 0, 0.12)',
                        }
                    }
                >
                    {field?.multipleValues?.button ? field?.multipleValues?.button : t('Add')}
                </PrimaryButton>
            </Grid>
        </Grid>
    )
}

export default MultipleValuesForm
