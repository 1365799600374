import styled from 'styled-components'
import {
    Box as MuiBox,
    Typography as MuiTypography,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const Box = styled(MuiBox)``

export const Typography = styled(styled(MuiTypography)(MuiSpacing))`
    line-height: 1.5rem;

    & span{
        font-weight: 800;
        cursor: pointer;
        :hover {
            // TODO: (GD) Why props.theme.palette.accent1.main is working everywhere but here?
            color: #0000ff;
            text-decoration: underline;
        }
    }
`
