import React, { useState } from "react";
import { Typography, ButtonIcon, Tooltip } from './TextWithTooltip.styled'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { transparentize } from "polished";
import { Theme, useTheme } from "@material-ui/core";
import { AppTheme } from "@/app/ui/definitions/NewDesignTheme";

interface Props {
    text?: string
    tooltip: {
        title: React.ReactNode | string
        icon: IconProp
        color?: string
    } | null
    fontSize?: number
    fontWeight?: number
    color?: string
    ml?: number
}

const TextWithTooltip: React.FunctionComponent<Props> = ({
    text,
    tooltip,
    fontSize,
    fontWeight,
    color,
    ml,
}) => {
    const theme = useTheme<AppTheme & Theme>()
    return <>
        <Typography
            ml={ml ?? -2}
            $fontSize={fontSize} 
            $fontWeight={fontWeight} 
            $color={color}
        >
            {text}
            {tooltip && <Tooltip
                PopperProps={{
                    disablePortal: true,
                    keepMounted: false /* Useful in devtool it leaves the code in the DOM for inspection */,
                }}
                arrow
                enterDelay={300}
                interactive={true}
                classes={{ popper: 'lzr-popper', tooltip: 'lzr-tooltip' }}
                $bgColor={transparentize(0.05, theme.palette.info900.main)}
                $width='600px'
                placement={'top'}
                title={tooltip.title ?? ''}
            >
                <ButtonIcon ml={1} icon={tooltip.icon} $color={tooltip.color} />
            </Tooltip>}
        </Typography>
    </>;
}

export default TextWithTooltip;