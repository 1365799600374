import React, { useEffect } from "react";
import { FlexContainer, ServiceTypography, Switch, FormControlLabel } from './PureToggleButton.styled';
import { FieldConfig } from "../FormSimple";
import { Control, Controller, FieldValues } from "react-hook-form";
import TextWithTooltip from "../../TextWithTooltip/TextWithTooltip";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons-lazr-alias";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface PureCheckboxFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const PureToggleButton: React.FunctionComponent<PureCheckboxFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    useEffect(() => {
        setValue(field.name, field.values ?? false)
    },[field.values])
    return <Controller
        name={name ? name : field.name}
        control={control}
        defaultValue={field.defaultValue || ''}
        render={({ field:controlField }) => {
            return (
                <FlexContainer>
                    <FormControlLabel
                        className="form-control-label"
                        control={
                            <Switch
                                checked={!!controlField.value} 
                                onChange={(e, checked) => {
                                    const value = field.forceStop ? !!controlField.value : checked
                                    if (controlField.onChange) {
                                        controlField.onChange(value)
                                        setValue(controlField.name, value)
                                        debouncedHandleOnChange?.({[field.name]: value})
                                    }
                                    trigger(controlField.name).then(()=> {
                                        if (controlField.onChange) {
                                            controlField.onChange(value)
                                            setValue(controlField.name, value)
                                            debouncedHandleOnChange?.({[field.name]: value})
                                        }
                                    })
                                }} 
                                name={field.name}
                                size='small'
                                $color={field.toggleButton?.color}
                            />}
                        label={null}
                    />
                    {field.toggleButton?.tooltip ? <TextWithTooltip
                                        fontSize={12}
                                        fontWeight={400}
                                        text={field.labelName}
                                        tooltip={{ icon: faCircleQuestion as IconProp, title: field.toggleButton.tooltip.content, color: 'black' }}
                            ></TextWithTooltip> : <ServiceTypography $fontWeight={400} ml={-2}>{field.labelName}</ServiceTypography>}
                </FlexContainer>
            )
        }}
        />
}

export default PureToggleButton;