import React, { useEffect } from "react";
import { Checkbox, FlexContainer, ServiceTypography } from './PureCheckbox.styled';
import { FieldConfig } from "../FormSimple";
import { Control, Controller, FieldValues } from "react-hook-form";
import TextWithTooltip from "../../TextWithTooltip/TextWithTooltip";
import { FormControlLabel } from "@material-ui/core";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons-lazr-alias";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface PureCheckboxFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const PureCheckbox: React.FunctionComponent<PureCheckboxFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    useEffect(() => {
        setValue(field.name, field.values)
    }, [field.values])
    return <Controller
        name={name ? name : field.name}
        control={control}
        defaultValue={field.defaultValue || ''}
        render={({ field:controlField }) => {
            const errorField = error ? error : errors?.[field.name];
            const hasError = !!errorField || field.hasError
            return (
                <>
                    <FlexContainer>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    $error={!!hasError}
                                    disabled={field.disabled}
                                    color="primary"
                                    checked={!!controlField.value} 
                                    onChange={(e, checked) => {
                                        const value = field.forceStop ? !!controlField.value : checked
                                        if (controlField.onChange) {
                                            controlField.onChange(value)
                                            setValue(controlField.name, value)
                                            debouncedHandleOnChange?.({[field.name]: value})
                                        }
                                    }}
                                    name="checkExcessiveLength" 
                                    size={'small'}
                                />
                            }
                            label={<ServiceTypography
                                        $selectedColor={controlField.value && field.checkbox?.selectedColor}
                                        $fontSize={field.checkbox?.fontSize}
                                        $fontWeight={field.checkbox?.fontWeight}
                                        ml={-1}
                                        $error={!!hasError}
                                    >
                                        {field.checkbox?.content}
                                    </ServiceTypography>}
                        />
                        {field.checkbox?.tooltip ? <TextWithTooltip
                                        fontSize={12}
                                        fontWeight={400}
                                        text={field.labelName}
                                        tooltip={{ icon: faCircleQuestion as IconProp, title: field.checkbox.tooltip.content, color: 'black' }}
                            ></TextWithTooltip> : <ServiceTypography $fontWeight={400} ml={-2}>{field.labelName}</ServiceTypography>}
                    </FlexContainer>
                    {field.checkbox?.description && 
                        <ServiceTypography 
                            $error={!!hasError}
                            $fontSize={12} 
                            $fontWeight={400}
                        >
                            {field.checkbox?.description}
                        </ServiceTypography>}
                </>
            )
        }}
        />
}

export default PureCheckbox;