import type {
    BillingTransportType,
    Country,
    Currency,EventType,
    InvoiceCsvStatus,
    OrderStatus,
    PendingPriceReviewStatus,
    Provider,
    RfqStatus,
    ShipmentIdentifierType,
    TransportType,
    UuidV4
} from '@lazr/openapi-client'


export interface PendingPriceReviewAttributes {
    id: UuidV4
    orderId: UuidV4
    rawCad: number
    rawUsd: number
    totalCad: number
    totalUsd: number
    currency: Currency
    charges: PendingPriceReviewChargeAttributes[]
}
export interface PendingPriceReviewChargeAttributes {
    seqNumber: number
    rawCad: number
    rawUsd: number
    totalCad: number
    totalUsd: number
    code: string
    description: string
}
export interface PendingPriceReview {
    id: UuidV4
    orderId: UuidV4
    orderNumber?: string
    orderStatus?: OrderStatus
    clientName?: string
    rfqStatus?: RfqStatus
    rfqAddressId: UuidV4 | null
    rfqTransportType: TransportType
    orderUsdToCadRate?: number
    orderCadToUsdRate?: number
    rawCad: number
    rawUsd: number
    totalCad: number
    totalUsd: number
    currency: Currency
    billingCurrency: Currency
    threePlMarginCad: number
    threePlMarginUsd: number
    transportInvoiceNumber: string
    line: string
    status?: PendingPriceReviewStatus
    charges: InvoiceCsvBatchCharge[]
    selectedQuote?: InvoiceCsvBatchQuote
    provider?: Provider
    createdByUser?: User
    createdAt?: string
    updatedByUser?: User
    rateThreshold?: number
    reason?: string
    latestEvent?: PriceReviewEvent
    mainShipmentIdentifierType: ShipmentIdentifierType | null
    mainShipmentIdentifierValue: string | null
}
export interface InvoiceCsvBatchCarrierInfo {
    id: UuidV4
    name: string
    carrierId: UuidV4
    companyName: string | null
    streetAddressLine1: string
    streetAddressLine2: string
    city: string
    state: string
    country: InvoiceCsvBatchCountry
    postalCode: string
}

interface InvoiceCsvBatchCountry {
    code: Country
    name: string
}
export interface PriceReviewEvent {
    id: UuidV4
    type: EventType
    createdByUser?: User
}
export interface InvoiceCsvBatchQuote {
    id: UuidV4
    costRawCad?: number
    costRawUsd?: number
    costTotalCad: number
    costTotalUsd: number
    isHidden: boolean
    isPriceAdjusted: boolean
    quoteCharges: InvoiceCsvBatchCharge[]
}
export interface InvoiceCsvBatchCharge {
    seqNumber: number
    rawCad: number
    rawUsd: number
    totalCad: number
    totalUsd: number
    code: string
    description: string
}
export interface  InvoiceCsvBatchError {
    line: number
    reason: string
    orderId?: UuidV4
    orderNumber?: string
    shipmentIdentifierType: ShipmentIdentifierType | null
    shipmentIdentifierValue: string | null
    amount: number
}
interface User {
    id: UuidV4
    shortFullName: string
}

export interface PaginationObject {
    total: number,
    currentPage: number,
    rowsPerPage: number
}

export interface MultiPaginationObject {
    pending: PaginationObject
    approve: PaginationObject
    reject: PaginationObject
    error: PaginationObject
}

export interface SelectedStatus {
    all: boolean
    exact: boolean
    under: boolean
    above: boolean
}

export interface InvoiceCsvBatchProps {
    id: string
    number: number
    fileName: string
    provider: Provider
    status: InvoiceCsvStatus
    transportType: BillingTransportType
    pendingPriceReviews: PendingPriceReview[]
    errors: InvoiceCsvBatchError[] 
    accessorials?: InvoiceCsvBatchAccessorial[]
    createdByUser: User
    createdAt: string
    invoiceDate: string
    carrier: InvoiceCsvBatchCarrier
}

export interface InvoiceCsvBatchStatusProps {
    id: string
    number: number
    fileName: string
    provider: Provider
    status: InvoiceCsvStatus
    transportType: BillingTransportType
    pendingList?: ResultPriceReview
    rejectList?: ResultPriceReview
    approveList?: ResultPriceReview
    calculateData?: calculateData
    errors?: ResultPriceReviewError
    accessorials?: InvoiceCsvBatchAccessorial[]
    createdByUser: User
    createdAt: string
    invoiceDate: string
    carrier: InvoiceCsvBatchCarrier
}
export interface InvoiceCsvBatchAccessorial {
    code: string
    name: string
}
export interface InvoiceCsvBatchCarrier {
    id: UuidV4
    name: string
    carrierInfoList?: InvoiceCsvBatchCarrierInfo[]
}

export interface ResultPriceReview {
    data: PendingPriceReview[],
    total: number
}

export interface ResultPriceReviewError {
    data: InvoiceCsvBatchError[],
    total: number
}

interface calculateData {
    errorAmountSum: number
    totalPendingPriceCad: number
    totalPendingPriceUsd: number
    totalSelectedQuoteLzrc: {
        totalCad: number
        totalUsd: number
    }
}

export class InvoiceCsvBatch {
    public id: string
    public number: number
    public fileName: string
    public provider: Provider
    public status: InvoiceCsvStatus
    public transportType: BillingTransportType
    public pendingPriceReviews: PendingPriceReview[]
    public errors: InvoiceCsvBatchError[] | ResultPriceReviewError
    public accessorials?: InvoiceCsvBatchAccessorial[]
    public createdByUser: User
    public createdAt: string
    public invoiceDate: string
    public carrier: InvoiceCsvBatchCarrier
    constructor (props: InvoiceCsvBatchProps) {
        this.id = props.id
        this.number = props.number
        this.fileName = props.fileName
        this.provider = props.provider
        this.status = props.status
        this.transportType = props.transportType
        this.pendingPriceReviews = props.pendingPriceReviews
        this.errors = props.errors
        this.accessorials = props.accessorials
        this.createdByUser = props.createdByUser
        this.createdAt = props.createdAt
        this.invoiceDate = props.invoiceDate
        this.carrier = props.carrier
    }
}

export class InvoiceCsvBatchStatus {
    public id: string
    public number: number
    public fileName: string
    public provider: Provider
    public status: InvoiceCsvStatus
    public transportType: BillingTransportType
    public pendingList?: ResultPriceReview
    public rejectList?: ResultPriceReview
    public approveList?: ResultPriceReview
    public errors?: ResultPriceReviewError
    public calculateData?: calculateData
    public accessorials?: InvoiceCsvBatchAccessorial[]
    public createdByUser: User
    public createdAt: string
    public invoiceDate: string
    public carrier: InvoiceCsvBatchCarrier
    constructor (props: InvoiceCsvBatchStatusProps) {
        this.id = props.id
        this.number = props.number
        this.fileName = props.fileName
        this.provider = props.provider
        this.status = props.status
        this.transportType = props.transportType
        this.pendingList = props.pendingList
        this.rejectList = props.rejectList
        this.approveList = props.approveList
        this.errors = props.errors
        this.calculateData = props.calculateData
        this.accessorials = props.accessorials
        this.createdByUser = props.createdByUser
        this.createdAt = props.createdAt
        this.invoiceDate = props.invoiceDate
        this.carrier = props.carrier
        this.calculateData = props.calculateData
    }
}
