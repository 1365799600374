import { BillingChargeType, DocumentType, ImportExportType, TransportType } from '@lazr/enums'

export const MAILING_SERVICES = [
    TransportType.ENVELOPE,
    TransportType.PAK,
    TransportType.PARCEL,
    TransportType.EXPRESS_BOX,
    TransportType.TUBE,
]

export const FREIGHT_SERVICES = [
    TransportType.LTL,
    TransportType.FTL,
    TransportType.OTHER,
]

export const LAZR_ONLY_SERVICE_CHARGES =
    [ BillingChargeType.LAZR_COVERAGE, BillingChargeType.LZFEE ]
export const BILLING_CHARGES =
    [ BillingChargeType.FREIGHT, BillingChargeType.ITEM,
        BillingChargeType.WGHT, BillingChargeType.ASWGHT,
        BillingChargeType.DIMWGHT, BillingChargeType.EXPEDITE,
        BillingChargeType.NFC, BillingChargeType.MIN, BillingChargeType.DELC ]

export const CLIENT_DOCUMENT_TYPES = [
    DocumentType.BILL_OF_LADING,
    DocumentType.LABEL,
    DocumentType.PROOF_OF_DELIVERY,
    DocumentType.COMMERCIAL_INVOICE,
    DocumentType.CERTIFICATE_OF_INSURANCE,
    DocumentType.AUTHORIZATION_FORM,
    DocumentType.CERTIFICATE_OF_ORIGIN,
    DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
    DocumentType.EXPORT_LICENSE,
    DocumentType.IMPORT_PERMIT,
    DocumentType.ONE_TIME_NAFTA,
    DocumentType.OTHER_CUSTOMS,
    DocumentType.POWER_OF_ATTORNEY,
    DocumentType.PACKING_LIST,
    DocumentType.SED_DOCUMENT,
    DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
    DocumentType.DECLARATION,
    DocumentType.USMCA_CERTIFICATION_OF_ORIGIN,
    DocumentType.USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN,
    DocumentType.PRO_FORMA_INVOICE,
    DocumentType.WAYBILL_DOCUMENT,
]

export const RESIDENTIAL_RELATED_ACCESSORIALS = [ 'RESPU', 'RESDEL', 'RSD', 'RSI' ]
export const RESIDENTIAL_DEL_ACCESSORIAL = 'RESDEL'
export const RESIDENTIAL_PU_ACCESSORIAL = 'RESPU'
export const DOCK_DEL_ACCESSORIAL = 'DOCKDEL'
export const DOCK_PU_ACCESSORIAL = 'DOCKPU'
export const LIFTGATE_ACCESSORIALS = [ 'LGPU', 'LGDEL' ]
export const DEFAULT_ACCESSORIALS = [ 'RESPU', 'RESDEL', 'DOCKPU', 'DOCKDEL' ]

export const PUROLATOR_IMPORT_EXPORT_TYPES = [ ImportExportType.PERMANENT, ImportExportType.TEMPORARY, ImportExportType.REPAIR, ImportExportType.RETURN ]

export const GMX_ORGANIZATION_NAME = 'GMX Worldwide Xpress'

export const FREIGHT_COVERAGE_INSURANCE = 8000
export const MAILING_COVERAGE_INSURANCE = 5000

export const lazrAdmin = [
    'vcollin@lazr.io',
    'ebenoit@lazr.io',
    'mguindon@lazr.io',
    'mbiard@lazr.io',
    'rhori@lazr.io',
    'm.gendron@gosynergie.com',
    'mgendron@lazr.io',
    'c.lariviere@gosynergie.com',
    'cgrenier@lazr.io',
]
export const salesRepresentativeAssignor = [
    ...lazrAdmin,
    'k.dagenais@gosynergie.com',
    'kdagenais@lazr.io'
]

export const accountProfitabilityAccess = [
    ...lazrAdmin,
    'k.dagenais@gosynergie.com',
    'kdagenais@lazr.io',
    'hguerrero@lazr.io',
    'r.rodriguez@gosynergie.com',
    'j.jacob@gosynergie.com',
    'g.abdala@gosynergie.com',
    'mgendron@lazr.io',
    'rrodriguez@lazr.io',
    'jjacob@lazr.io',
    'gabdala@lazr.io',
]

export const customerCreditStatusAndLimitChanger = [
    ...lazrAdmin,
    'across@lazr.io',
    'msuissa@lazr.io',
]

export const FREIGHT_COVERAGE_LIMIT = 200000
export const MAILING_COVERAGE_LIMIT = 25000
