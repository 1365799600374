import React, { useEffect } from 'react'
import {
    CheckboxProps,
    RadioProps,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccessorialCard } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { ButtonCardCheckboxColor } from '../GroupButton/GroupButton'
import { SelectionCard } from './OutlineCheckboxForm.styled'

export interface OutlineCheckboxFormProps {
    name: string
    button: any,
    mainName: string
    title: string | React.ReactNode
    content: string | number
    mainControl: Control<FieldValues>
    control: Control<FieldValues>
    color: ButtonCardCheckboxColor
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    value: any
    selectable: boolean
    isDirty: boolean
    errors?: any
    error?: any
    helperText?: any
    getButton?: (parentId: string, buttonId: string) => void
    isFirst?: boolean
    isLast?: boolean
}



const OutlineCheckboxForm: React.FunctionComponent<OutlineCheckboxFormProps> = ({
    name,
    button,
    mainName,
    title,
    content,
    color,
    mainControl,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    value,
    selectable,
    isDirty,
    getButton,
    isFirst,
    isLast
}) => {
    useEffect(() => {
        setValue(`${mainName}.${name}.value`, !!value, {
            shouldValidate: false,
        })
    }, [value])
    return (
        <Controller
            key={`${mainName}.${name}`}
            name={`${mainName}.${name}.value`}
            control={control}
            render={({ field }) => {
                const errorField = errors?.[field.name]
                return (
                    <SelectionCard
                        $isFirst={isFirst}
                        $isLast={isLast}
                        icon={button.label.icon ? <FontAwesomeIcon icon={button.label.icon} /> : undefined}
                        title={button.label.title}
                        exclusive
                        value={1}
                        error={false}
                        isDeletable={false}
                        checked={!!value}
                        onClick={(e: any) => {
                            const inputValue = !field.value
                            if (field.onChange) {
                                field.onChange(inputValue)
                                setValue(`${field.name}`, inputValue, {
                                    shouldValidate: false,
                                    shouldDirty: true,
                                })
                            }
                            // Trigger validation on change
                            trigger(field.name).then(() => {
                                if (field.onChange) {
                                    field.onChange(inputValue)
                                    setValue(`${field.name}`, inputValue, {
                                        shouldValidate: false,
                                        shouldDirty: true,
                                    })
                                    getButton?.(mainName, name)
                                    debouncedHandleOnChange()
                                }
                            })
                        }}
                    />
                )
            }}
        />
    )
}

type Props = Omit<CheckboxProps & RadioProps, 'title'>

export interface BaseSelectionCardProps extends Props {
    icon?: React.ReactNode
    title?: React.ReactNode
    description?: string | React.ReactNode
    helperText?: React.ReactNode
    helperTextIcon?: React.ReactNode
    checkIcon?: React.ReactNode
    uncheckedIcon?: React.ReactNode
    closeIcon?: React.ReactNode
    hideCheckIcon?: boolean
    exclusive?: boolean
    unclickable?: boolean
    error?: boolean
    onClose?: React.Dispatch<React.SetStateAction<AccessorialCard[]>>
    onTrash?: () => void
    truncateAtChar?: number
}

type ConditionalProps =
    {
        expandOnClick: boolean
        expandedContent: React.ReactNode
        unexpandedWidth: string
        unexpandedHeight: string
        containerUnexpandedHeight?: string
        isDeletable?: boolean
    }
    | {
        expandOnClick?: never
        expandedContent?: never
        unexpandedWidth?: string
        unexpandedHeight?: string
        containerUnexpandedHeight?: string
        isDeletable?: boolean
    }

export type SelectionCardProps = ConditionalProps & BaseSelectionCardProps

export default OutlineCheckboxForm
