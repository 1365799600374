import React from 'react'
import styled, { ThemedStyledProps } from 'styled-components'
import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core'
import { transparentize } from 'polished'

enum State {
    CHECKED,
    ACTIVE,
}

const getThumbSize = (props: ThemedStyledProps<any, any>, state?: State): string => {
    switch (state) {
        case State.CHECKED:
            return `
                    width: ${props.size === 'small' ? 14 : 24}px;
                    height: ${props.size === 'small' ? 14 : 24}px;
                `
        case State.ACTIVE:
            return `
                    width: ${props.size === 'small' ? 17 : 28}px;
                    height: ${props.size === 'small' ? 17 : 28}px;
                `
        default:
            return `
                    width: ${props.size === 'small' ? 10 : 16}px;
                    height: ${props.size === 'small' ? 10 : 16}px;
                `
    }
}

const getTrackStyle = (props: ThemedStyledProps<any, any>): string => {
    if (props.disabled) {
        return ''
    }

    return `background-color: ${props.theme.palette.primary.main};
            opacity: 1;
    `
}

export const StyledSwitch: React.FC<SwitchProps> = styled(MuiSwitch)<SwitchProps>`
        width: ${(props): number => props.size === 'small' ? 36 : 52}px;
        height: ${(props): number => props.size === 'small' ? 20 : 32}px;
        padding: 0px;
        margin: ${(props): string => props.theme.spacing(3)}px;
        overflow: unset;

        & [class*="MuiSwitch-switchBase"]{
            padding: 0px;
            top: ${(props): number => props.size === 'small' ? -3.5 : -4}px;
            left: ${(props): number => props.size === 'small' ? -4 : -4.5}px;
            color: ${(props): number => props.theme.palette.neutral500.main};
            width: ${(props): number => props.size === 'small' ? 27 : 40}px;
            height: ${(props): number => props.size === 'small' ? 27 : 40}px;

            &:hover{
                color: ${(props): number => props.theme.palette.neutral700.main};
                background-color: ${(props): string => transparentize(0.92, props.theme.palette.black.main)};
            }

            &.Mui-checked{
                color: ${(props): number => props.theme.palette.white.main};

                &:hover{
                    color: ${(props): number => props.theme.palette.accentPrimary50.main};
                    background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main)};
                }

                &:active{
                    & [class*="MuiSwitch-thumb"]{
                        color: ${(props): number => props.theme.palette.accentPrimary50.main};
                    }
                }

                & + [class*="MuiSwitch-track"]{
                    ${(props): string => getTrackStyle(props)}
                    border: none;
                }

                & [class*="MuiSwitch-thumb"]{
                    ${(props): string => getThumbSize(props, State.CHECKED)}
                }
            }

            &:active{
                & [class*="MuiSwitch-thumb"]{
                    color: ${(props): number => props.theme.palette.neutral700.main};
                    ${(props): string => getThumbSize(props, State.ACTIVE)}
                }
            }
        }

        & [class*="MuiSwitch-thumb"]{
            ${(props): string => getThumbSize(props)}
        }

        & [class*="MuiSwitch-track"]{
            border-radius: 16px;
            border: 2px solid ${(props): number => props.theme.palette.neutral500.main};
            background-color: ${(props): number => props.theme.palette.neutral100.main};
            opacity: 1;
        }
`
