import styled from 'styled-components'
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Checkbox as MuiCheckbox,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Chip as MuiChip,
  CardHeader as MuiCardHeader,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiSecondaryButton from '../../SecondaryButton/SecondaryButton'

export const ValueTypography = styled(styled(MuiTypography)(MuiSpacing))<{$size?: 'small' | 'medium'}>`
  font-size: ${(props): number => props.$size === 'medium' ? 16 : 12}px;
  font-weight: 500;
`

export const Select = styled(styled(MuiSelect)(MuiSpacing)) <{ size?: 'small' | 'medium', error?: boolean }>`
  width: 100%;
  & [class^='MuiSelect-root'] {
    height: 16px;
    background: white;
  }
  & [class*="MuiSelect-select"]:focus{
        background-color: white;
  }
  & [class*="MuiInputBase-input"] {
    background: white;
  }

  &[class*='MuiFormHelperText-root'][class*='Mui-error'] {
      ${(props): string => props.error ? `color: ${props.theme.palette.error900.main};` : ''}
  }

  &[class*='MuiOutlinedInput-root'][class*='Mui-error'] [class*='MuiOutlinedInput-notchedOutline'] {
      ${(props): string => props.error ? `border-color: ${props.theme.palette.error900.main};` : ''}
  }
`

export const RelativeContainer = styled(styled.div(MuiSpacing))`
    position: relative;
`

export const InputLabel = styled(styled(MuiInputLabel)(MuiSpacing)) <{ $backgroundColor?: string, $size?: string }>`
    position: absolute;
    top: ${(props): number => props.$size === 'medium' ? -8 : -4}px;
    left: -8px;
    background: ${(props): string => props.$backgroundColor ?? 'white'};
    white-space: nowrap;
    z-index: 1;
`

export const Icon = styled(styled(FontAwesomeIcon)(MuiSpacing))``
export const SecondaryButton = styled(styled(MuiSecondaryButton)(MuiSpacing))`
  border: none;
  padding: 0;
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing)) <{ $fontSize?: number, $fontWeight?: number, $color?: string }>`
    font-size: ${(props): number => props.$fontSize ?? 16}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    color: ${(props): string => props.$color ?? props.theme.palette.primary.main};
    display: inline-block;
`

export const ExtraInfoGrid = styled((styled(MuiGrid)(MuiSpacing)))`
  width: 100%;
`