import React, { useEffect, useState } from 'react'
import { TextField, Typography, RelativeContainer, InputLabel, Icon, Tooltip } from './TextOutlinedLabelForm.styled'
import { InputAdornment, useTheme, Theme } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons-lazr-alias'
import { AppTheme } from '@/app/ui/definitions/NewDesignTheme'
import { transparentize } from 'polished'
import TextWithTooltip from '../../TextWithTooltip/TextWithTooltip'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => any
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any,
    force?: boolean,
    isDirty?: boolean
}

const TextOutlinedLabelForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    force,
}) => {

    const { values, defaultValue, labelName, disabled, required, size, InputProps, helperText: textHelperText, layoutConfig, inputType } = field
    const theme = useTheme<AppTheme & Theme>()
    const [isDirty, setIsDirty] = useState(false);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(name ? name : field.name, (values ?? (defaultValue ?? '')).toString())
    }, [values])
    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field: controlField }) => {
                const errorField = error ? error : errors?.[controlField.name]
                const isEmpty = required && !getValues(field.name)
                const hasError = (isDirty && isEmpty) || (force && !!errorField)
                const errorMessage = isEmpty ? 'This field is required' : errorField?.message
                const helperTextField = textHelperText ? textHelperText : ''
                return (
                    <RelativeContainer>
                        <InputLabel $backgroundColor={layoutConfig?.labelColor}>
                            <>
                                {field.textOutlinedForm?.tooltip ? <TextWithTooltip
                                    ml={1}
                                    color={hasError ? theme.palette.error900.main : 'black'}
                                    fontSize={12}
                                    fontWeight={600}
                                    text={labelName}
                                    tooltip={{ icon: field.textOutlinedForm.tooltip.icon, title: field.textOutlinedForm.tooltip.title, color: hasError ? theme.palette.error900.main : 'black' }}
                                /> : 
                                <Typography
                                    $color={hasError ? theme.palette.error900.main : 'black'}
                                    $fontSize={12}
                                    $fontWeight={600}
                                    ml={1}
                                >
                                    {labelName}
                                </Typography>}
                                {field.required && !field.disabled && <Typography
                                    ml={1}
                                    $fontSize={14}
                                    $fontWeight={600}
                                    $color={hasError ? theme.palette.error900.main : 'black'}
                                >*</Typography>}
                            </>
                        </InputLabel>
                        <TextField
                            {...controlField}
                            disabled={disabled}
                            variant={'outlined'}
                            required={required ? true : false}
                            type={inputType ?? 'text'}
                            fullWidth
                            placeholder={field.placeholder}
                            error={!!hasError}
                            helperText={helperTextField}
                            size={size || 'small'}
                            value={controlField.value || ''}
                            InputProps={hasError ? {
                                endAdornment: <InputAdornment position='end'>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                            keepMounted: false /* Useful in devtool it leaves the code in the DOM for inspection */,
                                        }}
                                        arrow
                                        interactive={true}
                                        classes={{ popper: 'lzr-popper', tooltip: 'lzr-tooltip' }}
                                        $bgColor={transparentize(0.05, theme.palette.info900.main)}
                                        placement={'top'}
                                        title={errorMessage ?? ''}
                                    >
                                        <Icon icon={faCircleExclamation as IconProp} $color={hasError ? theme.palette.error900.main : 'black'} />
                                    </Tooltip>
                                </InputAdornment>
                            } : InputProps}
                            // onKeyDown={(e) => {
                            //     if (field.textOutlinedForm?.forceStop) {
                            //         e.preventDefault();
                            //     }
                            // }}
                            onChange={(e: any) => {
                                const inputValue = field.forceStop ? controlField.value : e.target.value
                                setIsDirty(true)
                                if (controlField.onChange) {
                                    controlField.onChange(inputValue)
                                    setValue(controlField.name, inputValue, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleOnChange?.({[field.name]: inputValue})
                                }

                                // Trigger validation on change
                                trigger(controlField.name).then(() => {
                                    const isFieldValid = !errorField
                                    if (isFieldValid && controlField.onChange) {
                                        controlField.onChange(inputValue)
                                        setValue(controlField.name, inputValue, {
                                            shouldValidate: false,
                                        })
                                        debouncedHandleOnChange?.({[field.name]: inputValue})
                                    }
                                })
                            }}
                        />
                    </RelativeContainer>
                )
            }}
        />
    )
}

export default TextOutlinedLabelForm
