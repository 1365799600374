import React, { useEffect, useRef, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { GridSize, Divider, Typography, Tooltip } from '@material-ui/core'
import { Grid, BasicButton, StyledSubTitleTypography } from './GroupButton.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormSimple, { FieldConfig } from '../FormSimple'
import { Currency } from '@lazr/openapi-client'
import { StyledIconBasicCoverageButton } from '@/app/ui-new/pages/marketplace/Results/components/ResultCardContent/ResultCardContent.styled'
import { Controller, useForm, Control, FieldValues } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { isDirty, z } from 'zod'
import ButtonCheckboxForm, { ButtonCheckboxFormProps } from '../ButtonCheckboxForm/ButtonCheckboxForm'
import { debounce } from 'lodash'
import { removeNullOrUndefinedKeys, reorderButton } from '../helpers'
import { useDebouncedCallback } from '@/app/ui-new/pages/hooks/useDebouncedCallback'
import CardCheckboxForm from '../CardCheckboxForm/CardCheckboxForm'
import { deepCopy } from '@lazr/utilities'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './GroupButton.i18n'
import OutlineCheckboxForm from '../OutlineCheckboxForm/OutlineCheckboxForm'
import Badge from '@/app/ui-new/components/Badge/Badge'

export enum ButtonCardCheckboxType {
    PRICE,
    TEXT,
    COMPONENT,
}

export enum ButtonCardCheckboxLayoutType {
    BUTTON,
    CARD,
    OUTLINE,
}

export enum ButtonCardCheckboxColor {
    OUTLINE,
    GRAY,
    GREEN,
    PURPLE,
}

export interface ButtonCardCheckboxProps {
    id: string
    color: ButtonCardCheckboxColor
    hide: boolean
    selectable: boolean
    disabled?: boolean
    schema?: z.ZodTypeAny
    label: {
        title: string | React.ReactNode
        tooltip?: {
            icon?: IconProp
            content: string | React.ReactNode
            layoutConfig?: { xs: boolean | GridSize | undefined }
        },
        icon?: IconProp
    }
    content: {
        value: string | number
    }
    defaultValue: any
    subLabel?: string | React.ReactNode
    layoutConfig?: { xs: boolean | GridSize | undefined }
    tooltip?: { icon?: IconProp; content: string | React.ReactNode; layoutConfig?: { xs: boolean | GridSize | undefined } }
    order?: number
    // triggerComponent?: Dialog
    formSimple?: FieldConfig[]
}

export interface GroupButtonLayoutGroupProps {
    name: string
    label?: {
        title: string | React.ReactNode
        tooltip?: {
            icon: IconProp
            content: string | React.ReactNode
        }
    }
    hideDivider?: boolean,
    schema?: z.ZodTypeAny
    buttons: ButtonCardCheckboxProps[]
}

export interface GroupButtonLogicProps {
    config?: {
        multipleSelection?: boolean
    }
    data: GroupButtonLogicGroupProps[]
}

export interface GroupButtonLogicGroupProps {
    id: string
    combination: string[]
    single: boolean
    buttons: string[]
}

export interface GroupButtonsProps {
    type: ButtonCardCheckboxType
    currency?: Currency
    layoutConfig?: any
    layoutType: ButtonCardCheckboxLayoutType
    layoutGroup: GroupButtonLayoutGroupProps[]
    logicGroup: GroupButtonLogicProps
    extraInfo?: React.ReactNode | string
}

export interface GroupButtonsFormProps {
    schema: z.ZodTypeAny
    control: Control<FieldValues>
    errors: any
    setValue: (name: string, value: any, options?: any) => void
    name: string
    debouncedHandleOnChange: (value?: any) => void
    defaultValue?: any
    values?: any
    trigger?: (name: string) => Promise<boolean>
    isDirty?: boolean
    groupButtons?: GroupButtonsProps
    setCustomErrors?: any
}

const GroupButton: React.FunctionComponent<GroupButtonsFormProps> = (props) => {
    const { t } = useI18n(i18n)
    const layouts = props.groupButtons?.layoutGroup
    const logics = props.groupButtons?.logicGroup
    const layoutType = props.groupButtons?.layoutType
    const layoutSchema = z.object(
        layouts?.reduce((acc: any, layout) => {
            const buttonSchemas = layout.buttons.reduce((buttonAcc: any, button) => {
                // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
                return { ...buttonAcc, [button.id]: button.schema }
            }, {})
            // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
            return { ...acc, [layout.name]: z.object({ schema: layout.schema ?? z.any(), buttons: z.object(buttonSchemas) }) }
        }, {}),
    )

    const {
        handleSubmit,
        control,
        formState: { errors: layoutErrors, isDirty, isValid: buttonValid },
        trigger,
        register,
        reset,
        getValues,
        getFieldState,
        setValue,
        clearErrors,
        setError,
    } = useForm({ resolver: zodResolver(props.schema) })
    const createButtonObject = (buttonIds: any, layoutGroups: any, existingDefaults: any, data: any) => {
        // Initialize the result object with the existing default values
        // Iterate over the layout groups
        const values = Object.entries(getValues())
        const result: any = {}


        for (const group of layoutGroups) {
            const groupObj: any = {}

            // Iterate over the buttons within the layout group
            for (const button of group.buttons) {
                const form = data?.[group.name]?.buttons?.[button.id].formSimple
                    ? { formSimple: data?.[group.name]?.buttons?.[button.id].formSimple }
                    : undefined

                // Check if the button ID is in the provided button IDs array
                if (buttonIds.includes(button.id)) {
                    // Determine the value to use (default to existing default value or button.defaultValue)
                    const value = existingDefaults?.[group.name]?.[button.id] || button.defaultValue || button.id
                    const groupButton = values.find((group2) => group2[0] === group.name)
                    const proved = groupButton?.[1]?.buttons?.[button.id]?.proved
                    // Add or update the button ID and value in the group object
                    groupObj[button.id] = { value, proved, ...(form ? form : undefined) }
                }
            }
            // Update or add the group object in the result if it has buttons
            if (Object.keys(groupObj).length > 0) {
                result[group.name] = groupObj
            }
        }
        return result
    }

    const findLayoutGroupWithFormSimple = (layoutGroups: any, groupName: string, buttonId: string) => {
        for (const group of layoutGroups) {
            if (group.name === groupName) {
                for (const button of group.buttons) {
                    if (button.id === buttonId) {
                        // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
                        return button.hasOwnProperty('formSimple')
                    }
                }
            }
        }
        return false
    }

    const handleSubmitData = async (data: any) => {
        if (isDirty) {
            let values = getValues()
            const buttons = Object.entries(getValues())
                .filter(([objKey, objValues]) => objValues)
                .flatMap(([key, values]) => {
                    const valButton = Object.entries(values.buttons)
                        .filter(([objKey, objValues]: any) => {
                            return objValues.value
                        })
                        .map(([objKey, objValues]) => objKey)
                    return valButton
                })
                .filter((arr) => arr.length > 0)
            const error: any = []
            props.setValue(props.name, createButtonObject(buttons, layouts, props.values?.['group-button'], data))
            let hasProvedTrue = false

            values = getValues()

            // biome-ignore lint/complexity/noForEach: <explanation>
            Object.entries(values).forEach(async ([layoutKey, layoutValues]: [string, any]) => {
                // biome-ignore lint/complexity/noForEach: <explanation>
                Object.entries(layoutValues.buttons).forEach(async ([buttonKey, buttonValues]: [string, any]) => {
                    clearErrors(`${layoutKey}.buttons.${buttonKey}`)
                    if (
                        buttonValues.value &&
                        buttonValues.proved === false &&
                        findLayoutGroupWithFormSimple(layouts, layoutKey, buttonKey)
                    ) {
                        hasProvedTrue = true
                        error.push({
                            [`${layoutKey}`]: {
                                buttons: {
                                    [`${buttonKey}`]: {
                                        type: 'manual',
                                        message: t('Please submit the form before proceeding'),
                                    },
                                },
                            },
                        })
                        setError(`${layoutKey}.buttons.${buttonKey}`, {
                            type: 'manual',
                            message: t('Please submit the form before proceeding'),
                        })
                    }
                })
            })

            props.setCustomErrors?.(deepCopy(error))
            //await trigger()
            if (!hasProvedTrue) {
                props.debouncedHandleOnChange?.()
            }
        }
    }

    const debouncerMilliseconds = 0.3
    const debouncedHandleOnChange = useDebouncedCallback(handleSubmit(handleSubmitData), debouncerMilliseconds)

    useEffect(() => {
        const lay: any = []
        layouts?.map((layout) => {
            const buttonVal: any = []
            layout.buttons
                .filter((button) => !button.hide)
                .map((button) => {
                    buttonVal.push(button.id)
                })
            lay.push(buttonVal)
            setValue(`${layout.name}.schema`, buttonVal)
        })
    }, [layouts, setValue])

    const updateButtonSchema: ButtonCheckboxFormProps['getButton'] = (parentId: string, buttonId: string) => {
        // Get all buttons inside the layout from the button you clicked.
        const buttons = Object.entries(getValues(parentId))
            .filter(([key, value]) => value)
            .map(([key]) => key)

        // set Value on the control using the buttons you click
        setValue(`${parentId.split('.')[0]}.schema`, buttons)

        //Get all values from the control
        const AllLayoutAndButtonsData = getValues()

        //Get all buttons and create an array with only the buttons key (ID)
        const allButtonKeys = Object.values(AllLayoutAndButtonsData).reduce((acc, layout) => {
            const buttonKeys = Object.keys(layout.buttons)
            // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
            return [...acc, ...buttonKeys]
        }, [])

        const multipleSelection = logics?.config?.multipleSelection

        //Get the logic buttons from the button you clicked
        const currentLogic = logics?.data.filter((logic) => logic.buttons.includes(buttonId))?.[0]

        //Thow erro in case someone create some miss data
        if (!currentLogic) {
            throw new Error(`Button Id ${buttonId} doesn't exist in any logic group`)
        }

        const incompatibleLogics = logics?.data.filter(
            (logic) => logic.id !== currentLogic.id && !logic.combination.includes(currentLogic.id),
        )
        const incompatibleButtonIds = multipleSelection
            ? incompatibleLogics.flatMap((logic) => logic.buttons)
            : [...incompatibleLogics.flatMap((logic) => logic.buttons), ...currentLogic.buttons].filter(
                  (buttonSelection) => buttonSelection !== buttonId,
              )
        if (incompatibleButtonIds.length) {
            allButtonKeys
                .filter((arrayButtonId: string) => incompatibleButtonIds.includes(arrayButtonId))
                .map((incompatibleButtonId: string) => {
                    Object.entries(AllLayoutAndButtonsData).map(([key, values]) => {
                        const layoutButton = Object.keys(values.buttons).includes(incompatibleButtonId)
                        if (layoutButton) {
                            if (getValues(`${key}.buttons.${incompatibleButtonId}.value`)) {
                                setValue(`${key}.buttons.${incompatibleButtonId}.value`, false)
                            }
                        }
                    })
                })

            Object.entries(getValues()).map(([key, values]) => {
                setValue(
                    `${key}.schema`,
                    Object.entries(values.buttons)
                        .filter(([objKey, objValues]) => objValues)
                        .map(([objKey, objValues]) => objKey),
                )
            })
        }
    }

    const generateForm = (layout: any, button: any, isFirst?: boolean, isLast?: boolean) => {
        const buttonValues = props.values?.[props.name]?.[layout.name]?.[button.id]
        if (buttonValues && layoutType !== ButtonCardCheckboxLayoutType.CARD) {
            delete buttonValues.formSimple
        }

        let formSimpleUpdate: FieldConfig[] = []
        if (button.formSimple) {
            if (buttonValues?.formSimple) {
                buttonValues?.formSimple?.map((layout: any) => {
                    button.formSimple?.map((button: any) => {
                        if (layout.name === button.name) {
                            button.values = layout.value
                            formSimpleUpdate.push(button)
                        } else {
                            formSimpleUpdate.push(button)
                        }
                    })
                })
            } else {
                formSimpleUpdate = button.formSimple
            }
        }
        switch (layoutType) {
            case ButtonCardCheckboxLayoutType.BUTTON:
                return <ButtonCheckboxForm
                    mainControl={props.control}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    color={button.color}
                    trigger={trigger}
                    debouncedHandleOnChange={debouncedHandleOnChange}
                    title={button.label.title}
                    tooltip={button.label.tooltip}
                    content={button.content.value}
                    name={button.id}
                    mainName={`${layout.name}.buttons`}
                    selectable={button.selectable}
                    value={buttonValues}
                    errors={layoutErrors}
                    isDirty={isDirty}
                    getButton={updateButtonSchema}
                />;
            case ButtonCardCheckboxLayoutType.CARD:
                return <CardCheckboxForm
                    mainControl={props.control}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    color={button.color}
                    trigger={trigger}
                    debouncedHandleOnChange={debouncedHandleOnChange}
                    title={button.label.title}
                    content={button.content.value}
                    mainName={`${layout.name}.buttons`}
                    name={button.id}
                    selectable={button.selectable}
                    value={buttonValues}
                    errors={layoutErrors}
                    error={(layoutErrors as any)?.[layout.name]?.buttons?.[button.id]}
                    isDirty={isDirty}
                    setCustomErrors={props.setCustomErrors}
                    setError={setError}
                    formSimple={
                        formSimpleUpdate?.length > 0 ? formSimpleUpdate : undefined
                    }
                    getButton={updateButtonSchema}
                    isValid={buttonValid}
                />;
            case ButtonCardCheckboxLayoutType.OUTLINE:
                return <OutlineCheckboxForm
                            mainControl={props.control}
                            button={button}
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            color={button.color}
                            trigger={trigger}
                            debouncedHandleOnChange={debouncedHandleOnChange}
                            title={button.label.title}
                            content={button.content.value}
                            mainName={`${layout.name}.buttons`}
                            name={button.id}
                            selectable={button.selectable}
                            value={buttonValues}
                            errors={layoutErrors}
                            error={(layoutErrors as any)?.[layout.name]?.buttons?.[button.id]}
                            isDirty={isDirty}
                            isFirst={isFirst}
                            isLast={isLast}
                            getButton={updateButtonSchema}
                    />;
            default:
                return <></>;
        }                                        
    }

    return (
        <Controller
            key={props.name}
            name={props.name}
            control={props.control}
            render={({ field }) => (
                <>
                    {layouts?.map((layout, index) => {
                        return (
                            <Controller
                                key={layout.name}
                                name={`${layout.name}.schema`}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {layout.label && (
                                            <Grid container item xs={12}>
                                                <Grid item xs={10}>
                                                    <StyledSubTitleTypography variant={'h6'} fontSize='2rem'>
                                                        {layout.label.title}
                                                    </StyledSubTitleTypography>
                                                </Grid>
                                                {layout.label.tooltip && <Grid item xs={'auto'} mt={4}></Grid>}
                                                <Grid item xs={12} my={4}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container>
                                            {reorderButton(layout.buttons)
                                                .filter((button) => !button.hide)
                                                .map((button, index, buttons) => {
                                                    const buttonValues = props.values?.['group-button']?.[layout.name]?.[button.id]
                                                    if (buttonValues && layoutType === ButtonCardCheckboxLayoutType.BUTTON) {
                                                        delete buttonValues.formSimple
                                                    }

                                                    let formSimpleUpdate: FieldConfig[] = []
                                                    if (button.formSimple) {
                                                        if (buttonValues?.formSimple) {
                                                            buttonValues?.formSimple?.map((layout: any) => {
                                                                button.formSimple?.map((button: any) => {
                                                                    if (layout.name === button.name) {
                                                                        button.values = layout.value
                                                                        formSimpleUpdate.push(button)
                                                                    } else {
                                                                        formSimpleUpdate.push(button)
                                                                    }
                                                                })
                                                            })
                                                        } else {
                                                            formSimpleUpdate = button.formSimple
                                                        }
                                                    }
                                                    const isFirst = index === 0;
                                                    const isLast = index === buttons.length - 1
                                                    return (
                                                        <Grid item {...button.layoutConfig} key={button.id}>
                                                            {generateForm(layout, button, isFirst, isLast)}
                                                        </Grid>
                                                    )
                                                })}
                                                {props.groupButtons?.extraInfo}
                                        </Grid>
                                        {props.errors[layout.name] && (
                                            <Typography variant='caption' style={{ color: 'red' }}>
                                                {props.errors[layout.name]?.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        )
                    })}
                    {props.errors[props.name] && (
                        <Typography variant='caption' style={{ color: 'red' }}>
                            {props.errors[props.name]?.message}
                        </Typography>
                    )}
                </>
            )}
        />
    )
}

export default GroupButton
