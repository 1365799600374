import React from 'react'
import { StyledMuiButton } from './PrimaryButton.styled'
import { ButtonProps } from '@material-ui/core'

interface sizes {
    mb: number,
    mt: number,
    ml: number,
    mr: number,
    pb: number,
    pt: number,
    pl: number,
    pr: number
}
const PrimaryButton: React.FunctionComponent<ButtonProps| sizes> = (props) =>
    <StyledMuiButton
        variant="contained"
        {...props}
    >{props.children}
    </StyledMuiButton>

export default PrimaryButton
