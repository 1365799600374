import { IncotermsType, Search, SearchRfq, SearchRfqCustomsDetail, SearchRfqQuote } from '@/app/model'
import { isInternationalOrder } from '@lazr/utilities'
import { DocumentType, Provider } from '@lazr/enums'
import { createCanadaPostSpecificObject } from '@/app/ui/pages/marketplace/components/CanadaPost/helpers'
import { createPurolatorSpecificObject } from '@/app/ui/pages/marketplace/components/Purolator/helpers'
import { createFedexSpecificObject } from '@/app/ui/pages/marketplace/components/Fedex/helpers'
import { createCanparSpecificObject } from '@/app/ui/pages/marketplace/components/Canpar/helpers'
import { createUpsSpecificObject } from '@/app/ui/pages/marketplace/components/Ups/helpers'
import { createRoseRocketSpecificObject } from '@/app/ui/pages/marketplace/components/RoseRocket/helpers'
import { createP44SpecificObject } from '@/app/ui/pages/marketplace/components/P44/helpers'
import { createDhlSpecificObject } from '@/app/ui/pages/marketplace/components/Dhl/helpers'
import { createGlsCanadaSpecificObject } from '@/app/ui/pages/marketplace/components/GlsCanada/helpers'
import { GroupedSelectOptions } from '../../../components/GroupedSelect'
import moment from 'moment'
import { Country as CountryCode, Currency, Currency as CurrencyCode } from '@lazr/openapi-client'


export const createProviderSpecificObject = (order: Partial<Search>, isDraft = false, checkCustomInformation?: string) => {
    if (!isInternationalOrder(order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code)) {
        return  undefined
    }

    const provider = order.rfq?.selectedQuote?.provider
    switch (provider) {
        case Provider.CANADA_POST:
            return createCanadaPostSpecificObject(provider, order, isDraft)

        case Provider.PUROLATOR:
            return createPurolatorSpecificObject(provider, order, isDraft)

        case Provider.FEDEX:
            return createFedexSpecificObject(provider, order, isDraft, checkCustomInformation)

        case Provider.CANPAR:
            return createCanparSpecificObject(provider, order, isDraft)

        case Provider.UPS:
            return createUpsSpecificObject(provider, order, isDraft)

        case Provider.ROSE_ROCKET:
            return createRoseRocketSpecificObject(provider, order, isDraft)

        case Provider.PROJECT_44:
            return createP44SpecificObject(provider, order, isDraft)

        case Provider.DHL:
            return createDhlSpecificObject(provider, order, isDraft)

        case Provider.GLS_CANADA:
            return createGlsCanadaSpecificObject(provider, order, isDraft)

        default:
            return undefined
    }
}

export const formatIncotermsGroupedOptions = (data: IncotermsType[]): GroupedSelectOptions<IncotermsType>[] => {
    const groupedOptions = data.reduce((versions: string[], incotermsType: IncotermsType) => {
        if (!versions.includes(incotermsType.version)){
            versions.push(incotermsType.version)
        }

        return versions
    }, [])

    return groupedOptions.map((group: string) => {
        const groupedOption: GroupedSelectOptions<IncotermsType> = {
            groupName: group,
            options: [],
        }
        data.filter((d: IncotermsType) => d.version === group).forEach((d: IncotermsType) => {
            groupedOption.options.push({
                label: `${d.code} - ${d.name}`,
                value: d,
            })
        })

        return groupedOption
    })
}

export const getBrowserTimezone = () => moment().format('Z')
export type CustomsDocumentsTypeListItem = {
    id: DocumentType
    label: string
}

export const customsDocumentsTypeListItem: CustomsDocumentsTypeListItem[] = [
    {
        id: DocumentType.AUTHORIZATION_FORM,
        label: 'Authorization Form',
    },
    {
        id: DocumentType.CERTIFICATE_OF_ORIGIN,
        label: 'Certificate of Origin',
    },
    {
        id: DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
        label: 'Export Accompanying Document',
    },
    {
        id: DocumentType.EXPORT_LICENSE,
        label: 'Export License',
    },
    {
        id: DocumentType.IMPORT_PERMIT,
        label: 'Import Permit',
    },
    {
        id: DocumentType.ONE_TIME_NAFTA,
        label: 'One Time NAFTA',
    },
    {
        id: DocumentType.OTHER_CUSTOMS,
        label: 'Other Document',
    },
    {
        id: DocumentType.POWER_OF_ATTORNEY,
        label: 'Power Of Attorney',
    },
    {
        id: DocumentType.PACKING_LIST,
        label: 'Packing List',
    },
    {
        id: DocumentType.SED_DOCUMENT,
        label: 'SED Document',
    },
    {
        id: DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
        label: 'Shipper\'s Letter Of Instruction',
    },
    {
        id: DocumentType.DECLARATION,
        label: 'Declaration',
    },
    {
        id: DocumentType.USMCA_CERTIFICATION_OF_ORIGIN,
        label: 'USMCA Certification Of Origin',
    },
    {
        id: DocumentType.USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN,
        label: 'USMCA Commercial Invoice Certification Of Origin',
    },
    {
        id: DocumentType.PRO_FORMA_INVOICE,
        label: 'Pro Forma Invoice',
    },
    {
        id: DocumentType.OTHER_CUSTOMS,
        label: 'Other Customs Document',
    },
]

export const getProviderSpecificUnsupportedEtdDocumentList = (order: Partial<Search>) => {
    if (!isInternationalOrder(order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code)) {
        return  undefined
    }

    const provider = order.rfq?.selectedQuote?.provider
    if (!provider) {
        return undefined
    }

    const uploadedDocument = order.documents?.map((document) => document.type) || []

    if (!uploadedDocument.length) {
        return undefined
    }

    // From the uploadedDocument, return the ETD unsupported base on the provider suported list below.
    const supportedCustomsDocumentTypePerProvider: Record<Provider, DocumentType[]> = {
        [Provider.PROJECT_44]: [],
        [Provider.ROSE_ROCKET]: [],
        [Provider.GLS_CANADA]: [],
        [Provider.MANITOULIN]: [],
        [Provider.THREE_PL]: [],
        [Provider.PUROLATOR]: [],
        [Provider.FEDEX]: [
            DocumentType.COMMERCIAL_INVOICE,
        ],
        [Provider.DHL]: [
            DocumentType.INVOICE_SUPPORT,
            DocumentType.PRO_FORMA_INVOICE,
            DocumentType.CERTIFICATE_OF_ORIGIN,
            DocumentType.COMMERCIAL_INVOICE,
            DocumentType.DECLARATION,
            DocumentType.WAYBILL_DOCUMENT,
            DocumentType.USMCA_CERTIFICATION_OF_ORIGIN,
        ],
        [Provider.NATIONEX]: [],
        [Provider.CANPAR]: [],
        [Provider.DAY_AND_ROSS]: [],
        [Provider.CANADA_POST]: [],
        [Provider.UPS]: [
            DocumentType.AUTHORIZATION_FORM,
            DocumentType.COMMERCIAL_INVOICE,
            DocumentType.CERTIFICATE_OF_ORIGIN,
            DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
            DocumentType.EXPORT_LICENSE,
            DocumentType.IMPORT_PERMIT,
            DocumentType.ONE_TIME_NAFTA,
            DocumentType.POWER_OF_ATTORNEY,
            DocumentType.PACKING_LIST,
            DocumentType.SED_DOCUMENT,
            DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
            DocumentType.DECLARATION,
            DocumentType.OTHER_CUSTOMS,
        ],
        [Provider.SPEEDY]: [],
        [Provider.MIDLAND]: [],
        [Provider.MORNEAU]: [],
        [Provider.AB_COURIER]: [],
    }

    const supportedType = supportedCustomsDocumentTypePerProvider[provider]
    const documentsToDisplay = uploadedDocument.filter((documentType) => !(supportedType).includes(documentType))

    return documentsToDisplay.length ?
        documentsToDisplay.map((documentType) => customsDocumentsTypeListItem.find((document) => document.id === documentType)?.label) :
        undefined
}

const getCustomDetailItemsTotal = (customDetails: SearchRfqCustomsDetail[], selectedQuote: SearchRfqQuote | null, userCurrency: CurrencyCode) => {
    const reduce = (total: number, customDetail: SearchRfqCustomsDetail): number => {
        if (customDetail.quantity && customDetail.unitValue) {
            if (userCurrency === CurrencyCode.USD) {
                return (customDetail.quantity * customDetail.unitValue) + total
            } else if (selectedQuote) {
                return (customDetail.quantity * (customDetail.unitValue * selectedQuote.lazrCadToUsdRate)) + total
            } else {
                return total
            }
        } else {
            return total
        }
    }

    return customDetails.reduce(reduce, 0)
}

export const validateTaxIdRequirementPart = (rfq: Partial<SearchRfq>): boolean =>
    !!rfq?.commercialInvoiceDocumentIndicator && !rfq?.commercialInvoiceUserProvided &&
    !!rfq?.destinationCountry && [ CountryCode.US, CountryCode.BR ].includes(rfq.destinationCountry.code)  &&
    !!rfq?.customsDetails && (getCustomDetailItemsTotal(rfq.customsDetails, rfq?.selectedQuote ?? null, rfq.dutyCurrency?.code ?? Currency.CAD) > 800)
