import styled from 'styled-components'
import {
    Grid as MuiGrid,
    Typography as MuiTypography,
    InputLabel as MuiInputLabel,
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { transparentize } from 'polished'
import MuiTextField from '../../TextField/TextField'


export const TextField = styled(styled(MuiTextField)(MuiSpacing)) <{ $isFocus?: boolean, size?: string, error?: boolean }>`
    & [class^='MuiInputBase-root'].Mui-disabled {
        background: ${(props): string => props.theme.palette.neutral50.main};
        border: 1px solid ${(props): string => props.theme.palette.neutral200.main};
    }
    & label {
        white-space: nowrap;
        top: -6px;
        & .[class^='MuiTypography-body1'] {
            line-height: unset;
        }
    }

    & input {
        font-size: ${(props): number => props.size === 'medium' ? 18 : 14}px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    & [class*='MuiFormHelperText-root'][class*='Mui-error'] {
        ${(props): string => props.error ? `color: ${props.theme.palette.error900.main};` : ''}
    }

    & [class*='MuiOutlinedInput-root'][class*='Mui-error'] [class*='MuiOutlinedInput-notchedOutline']{
        ${(props): string => props.error ? `border-color: ${props.theme.palette.error900.main};` : ''}
    }
`
export const Typography = styled(styled(MuiTypography)(MuiSpacing)) <{ $fontSize?: number, $fontWeight?: number, $color?: string }>`
    font-size: ${(props): number => props.$fontSize ?? 16}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    color: ${(props): string => props.$color ?? props.theme.palette.primary.main};
    display: inline-block;
`

export const RelativeContainer = styled(styled.div(MuiSpacing))`
    position: relative;
`

export const InputLabel = styled(styled(MuiInputLabel)(MuiSpacing)) <{ $backgroundColor?: string }>`
    position: absolute;
    top: -10px;
    left: 4px;
    background: ${(props): string => props.$backgroundColor ?? 'white'};
    white-space: nowrap;
    z-index: 1;
    & p {
        line-height: unset;
    }
`

export const Icon = styled(styled(FontAwesomeIcon)(MuiSpacing))<{$color?: string}>`
    color: ${(props): string => props.$color ?? props.theme.palette.primary.main};
`

export const Tooltip = styled(styled(MuiTooltip)(MuiSpacing))<TooltipProps>`
    + .lzr-popper {
        position: ${(props): string | undefined => props.$position || 'absolute'};
    }
    + .lzr-popper > .lzr-tooltip {
        padding: 16px;
        color: ${(props): string | undefined => props.color || '#ffffff'};
        background-color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
        width: ${(props): string | undefined => props.$width};
        border-radius: 4px;
        font-size: ${(props): string => props.theme.typography.body2.fontSize};
        font-weight: ${(props): string => props.theme.typography.body2.fontWeight};
        white-space: normal;
    }
    + .lzr-popper > div > span[class*='MuiTooltip-arrow-'] {
        color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
    }
    + .lzr-popper > .lzr-tooltip a {
        color: ${(props): string | undefined => props.color || '#ffffff'};
    }
`


export interface TooltipProps extends MuiTooltipProps {
    $bgColor?: string
    $width?: string
    $position?: string
}