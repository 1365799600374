/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['LTL']: 'LTL',
    ['FTL']: 'FTL',
    ['PARCEL']: 'Parcel',
    ['ENVELOPE']: 'Envelope',
    ['PAK']: 'Pak',
    ['EXPRESS_BOX']: 'Express box',
    ['TUBE']: 'Tube',
    ['OTHER']: 'Other',
}
