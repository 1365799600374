// biome-ignore lint/style/useImportType: <explanation>
import React, {  useState } from 'react'
import { loadComponent, getAddressDetails } from '../helpers'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import PlacesAutocomplete, { type PlacesAutocompleteResult } from './PlacesAutocomplete/PlacesAutocomplete'
import { type AddressDetails, formatAddress } from './PlacesAutocomplete/helpers'
import { AccessorialLocationType } from '@lazr/enums'
import { GoogleSearchAddressType, type GoogleAddressSearchOption } from '../../GoogleAddressSearchBar/GoogleAddressSearchBar'


export interface GoogleAddressSearchBarProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface GoogleAddressSearchBarFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const GoogleAddressSearchBarForm: React.FC<GoogleAddressSearchBarFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {

   
    const { type, tooltip, labelName, placeholder, addressSearch, spaceForError, subLabel, disabled, width, required, } = field

    const DynamicComponent: any = loadComponent(type)
    const [showAddressFields, setShowAddressFields] = useState<boolean>(false)
    const countryList = addressSearch?.countries || []

    const handleAutocompleteAddressEditClicked = () => {
        setShowAddressFields(true)
    }

    const handleEnterAddressManualClick = () => {
        setShowAddressFields(true)
        //   onEnterAddressManualClick()
    }
    const googleValues: any = field.values
    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field: { onChange, value } }) => {
                const errorField = error ? error : errors?.[field.name]
                const handleAutocompleteAddressChange = (newValue: PlacesAutocompleteResult): void => {
         
                    const addressDetails: AddressDetails = getAddressDetails(newValue, countryList, false)
               
                    const place = {
                        description: `${formatAddress(addressDetails)}`,
                        isAddressBook: newValue.isAddressBook,
                        type: GoogleSearchAddressType.ADDRESS,
                        mainText: `${formatAddress(addressDetails)}`,
                        data: addressDetails,
                    }

                    setShowAddressFields(false)
                    onChange(place)
                    setValue(field.name, place, { shouldValidate: false })
                    // Trigger validation on change
                    trigger(field.name).then(() => {
                        const isFieldValid = !errors[field.name]
                        if (isFieldValid && onChange) {
                            onChange(place)
                            setValue(field.name, place, {
                                shouldValidate: false,
                            })
                        }
                    })

                    debouncedHandleOnChange?.({[field.name]: place})
                }

                const onAddressAutocompleteChange = (place: GoogleAddressSearchOption | null) => {

                    onChange(place)
                    setValue(field.name, place, { shouldValidate: false })
                    // Trigger validation on change
                    trigger(field.name).then(() => {
                        const isFieldValid = !errors[field.name]
                        if (isFieldValid && onChange) {
                            onChange(place)
                            setValue(field.name, place, {
                                shouldValidate: false,
                            })
                        }
                    })
                }
                return (
                    <PlacesAutocomplete
                        id={`${field.name}PlacesAutoComplete`}
                        variant='outlined'
                        size={'medium'}
                        onSelect={handleAutocompleteAddressChange}
                        value={value ? value : (googleValues?.value|| null)}
                        fixedInputValue={undefined}
                        onChange={onAddressAutocompleteChange}
                        disabled={disabled}
                        locationType={addressSearch?.locationType ? addressSearch?.locationType : AccessorialLocationType.ANY}
                        onEditClick={handleAutocompleteAddressEditClicked}
                        onEnterAddressManuallyClick={handleEnterAddressManualClick}
                        showAddressFields={addressSearch?.showAddressFields ?? true}
                        error={errorField}
                       
                    />
                )
            }}
        />
    )
}

export default GoogleAddressSearchBarForm
