import React, { useEffect, useState } from 'react'
import { loadComponent } from '../helpers'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { FieldConfig } from '../FormSimple'
import { Tooltip, BasicButtonCheckbox, GridButtonCheckbox, StyledIconBasicButtonCheckbox } from './ButtonCheckboxForm.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import { ButtonCardCheckboxColor } from '../GroupButton/GroupButton'

export interface ButtonCardCheckboxProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface ButtonCheckboxFormProps {
    name: string
    mainName: string
    title: string | React.ReactNode
    content: string | number
    tooltip?: any
    mainControl: Control<FieldValues>
    control: Control<FieldValues>
    color: ButtonCardCheckboxColor
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    value: any
    selectable: boolean
    isDirty: boolean
    errors?: any
    error?: any
    helperText?: any
    getButton?: (parentId: string, buttonId: string) => void
}

const ButtonCheckboxForm: React.FC<ButtonCheckboxFormProps> = ({
    name,
    mainName,
    title,
    content,
    tooltip,
    color,
    mainControl,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    value,
    selectable,
    isDirty,
    getButton,
}) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
       
        if(value){
            setValue(`${mainName}.${name}`, value)
        }
     
       // debouncedHandleOnChange?.()
    }, [value])


    return (
        <Controller
            key={`${mainName}.${name}`}
            name={`${mainName}.${name}.value`}
            control={control}
            render={({ field }) => {
                const errorField = errors?.[field.name]
                return (
                    <>
                        <BasicButtonCheckbox
                            key={`${mainName}.${name}.buttons.form`}
                            bcolor={color}
                            onClick={(e: any) => {
                                const inputValue = !field.value
                                if (field.onChange) {
                                    field.onChange(inputValue)
                                    setValue(`${field.name}.value`, inputValue, {
                                        shouldValidate: false,
                                        shouldDirty: true,
                                    })
                                }
                                // Trigger validation on change
                                trigger(field.name).then(() => {
                                    if (field.onChange) {
                                        field.onChange(inputValue)
                                        setValue(`${field.name}.value`, inputValue, {
                                            shouldValidate: false,
                                            shouldDirty: true,
                                        })
                                        getButton?.(mainName, name)
                                        debouncedHandleOnChange({[`${field.name}.value`]: inputValue})
                                    }
                                })
                            }}
                        >
                            <GridButtonCheckbox container alignItems='center' justifyContent='center'>
                                <GridButtonCheckbox container item xs={10}>
                                    <GridButtonCheckbox container alignItems='center' justifyContent='flex-start'>
                                        <Grid item>
                                            {title}
                                        </Grid>
                                        <Grid item>
                                            {
                                                tooltip && 
                                                    <Tooltip
                                                        title={tooltip.content}
                                                        placement='right'
                                                        enterDelay={300}
                                                    >
                                                        <FontAwesomeIcon icon={[ 'far', 'info-circle' ]} size='lg' />
                                                    </Tooltip>
                                            }
                                        </Grid>
                                    </GridButtonCheckbox>
                                    <GridButtonCheckbox item>
                                        <Typography variant='body2' className='basic-coverage'>
                                            {content}
                                        </Typography>
                                    </GridButtonCheckbox>
                                </GridButtonCheckbox>
                                {selectable && (
                                    <GridButtonCheckbox item xs={2} container justifyContent='flex-end'>
                                        {field.value && <FontAwesomeIcon icon={['fas', 'check-circle']} size='lg' />}
                                    </GridButtonCheckbox>
                                )}
                            </GridButtonCheckbox>
                        </BasicButtonCheckbox>
                        {isDirty && errorField && (
                            <Typography variant='caption' style={{ color: 'red' }}>
                                {errorField?.message}
                            </Typography>
                        )}
                    </>
                )
            }}
        />
    )
}

export default ButtonCheckboxForm
