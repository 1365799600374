// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect } from 'react'
import { Controller, type Control, type FieldValues } from 'react-hook-form'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import { Autocomplete } from './AutoCompleteSimple.styled'
import Tooltip, { type CustomTooltipProps } from '../Tooltip/Tooltip'
import { FontAwesomeIcon } from '../IconButton/IconButton.styled'
import {
  GridTooltipFlex,
  GridTooltipIcon,
  QuestionIcon,
} from '../Tooltip/Tooltip.styled'
import { Grid } from '@material-ui/core'

interface AutoCompleteSimpleProps {
  namePrefix: string
  control: Control<FieldValues>
  errors: any
  setValue: (name: string, value: any, options?: any) => void
  getValues: (name?: string, options?: any) => void
  trigger: (name: string) => Promise<boolean>
  debouncedHandleOnChange: (value?: any) => void
  data: any
  getOptionSelected: (option: any, value: any) => boolean
  getOptionLabel: (option: any) => string
  renderInput: any
  renderOption: any
  tooltip?: CustomTooltipProps
  defaultValue?: any
  values?: any
  mt?: number
}

const AutoCompleteSimple: React.FC<AutoCompleteSimpleProps> = ({
  namePrefix,
  control,
  errors,
  data,
  setValue,
  getValues,
  trigger,
  debouncedHandleOnChange,
  getOptionSelected,
  getOptionLabel,
  renderInput,
  renderOption,
  tooltip,
  defaultValue,
  values,
  mt,
}) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={tooltip ? 11 : 12}>
        <Controller
          name={namePrefix}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <Autocomplete
                mt={mt}
                id={namePrefix}
                size='small'
                value={values || null}
                loading={data.length === 0}
                getOptionSelected={getOptionSelected}
                renderOption={renderOption}
                getOptionLabel={getOptionLabel}
                options={data}
                onChange={(_event, newValue) => {
                  field.onChange(newValue)
                  setValue(field.name, newValue, { shouldValidate: false })
                  // Trigger validation on change
                  trigger(field.name).then(() => {
                    const isFieldValid = !errors[field.name]
                    if (isFieldValid && field.onChange) {
                      field.onChange(newValue)
                      setValue(field.name, newValue, { shouldValidate: false })
                      debouncedHandleOnChange({[field.name]: newValue})
                    }
                  })
                }}
                renderInput={renderInput}
              />
            )
          }}
        />
      </Grid>
      {tooltip && (
        <Grid item xs={1} sm={1}>
          <GridTooltipIcon>
            <Tooltip {...tooltip}>
              <QuestionIcon>
                <FontAwesomeIcon
                  icon={
                    tooltip?.icon ? tooltip?.icon : ['far', 'question-circle']
                  }
                />
              </QuestionIcon>
            </Tooltip>
          </GridTooltipIcon>
        </Grid>
      )}
    </Grid>
  )
}

export default AutoCompleteSimple
