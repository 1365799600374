import React from 'react'
import { Controller, Control, FieldValues } from 'react-hook-form'
import KeyboardDatePicker from '../../DatePicker/KeyboardDatePicker'
import moment from 'moment'
import { Typography } from './FromToDateRangePicker.styled'
import LabelledOutline from '../../LabelledOutline/LabelledOutline'
import { GridTooltipFlex, QuestionIcon } from '../../Tooltip/Tooltip.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip, { CustomTooltipProps } from '../../Tooltip/Tooltip'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './FromToDateRangePicker.i18n'
import DateForm from '../DateForm/DateForm'
import LabelledOutlineBox from '../LabelledOutLineBox/LabelledOutLineBox'

interface FromToDateRangePickerProps {
  namePrefix: string
  control: Control<FieldValues>
  
  defaultValue: any
  
  errors: any
  labelName?: string
  additionalDescription?: string
  
  setValue: (name: string, value: any, options?: any) => void
  
  getValues: (name?: string, options?: any) => void
  trigger: (name: string) => Promise<boolean>
  debouncedHandleOnChange: (value?: any) => void
  tooltip?: CustomTooltipProps
  hideOutBox?: boolean
}

const FromToDateRangePicker: React.FC<FromToDateRangePickerProps> = ({
  namePrefix,
  control,
  errors,
  labelName,
  setValue,
  getValues,
  trigger,
  debouncedHandleOnChange,
  additionalDescription,
  tooltip,
  defaultValue,
  hideOutBox,

}) => {
  const { t } = useI18n(i18n)

  return (
    <>
      <LabelledOutlineBox labelName={labelName ? labelName : namePrefix} tooltip={tooltip} hideOutBox={hideOutBox}>
        <DateForm
          name={`${namePrefix}.from`}
          label={t('From')}
          defaultValue={
            defaultValue.from
              ? moment
                  .utc(defaultValue.from)
                  .hours(5)
                  .format('YYYY-MM-DD HH:mm:ss')
              : null
          }
          control={control}
          errors={errors}
          error={!!errors[namePrefix]?.from} // Ensure correct error access
          helperText={errors[namePrefix]?.from?.message}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          debouncedHandleOnChange={debouncedHandleOnChange}
          values={defaultValue.from}
        />

        <DateForm
          name={`${namePrefix}.to`}
          label={t('To')}
          defaultValue={
            defaultValue.to
              ? moment
                  .utc(defaultValue.to)
                  .hours(5)
                  .format('YYYY-MM-DD HH:mm:ss')
              : null
          }

          control={control}
          error={!!errors[namePrefix]?.to} // Ensure correct error access
          helperText={errors[namePrefix]?.to?.message}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          debouncedHandleOnChange={debouncedHandleOnChange}
          values={defaultValue.to}
        />
        
        {errors[`${namePrefix}`] && (
          <Typography variant='caption' color='error'>
            {errors[`${namePrefix}`]?.root?.message}
          </Typography>
        )}
        {additionalDescription && (
          <Typography variant='caption' color='primary'>
            {additionalDescription}
          </Typography>
        )}
      </LabelledOutlineBox>
    </>
  )
}

export default FromToDateRangePicker
