import { Checkbox as MuiCheckbox, Typography as MuiTypography } from "@material-ui/core"
import styled from 'styled-components';
import { spacing as MuiSpacing } from "@material-ui/system";
export const Checkbox = styled(styled(MuiCheckbox)(MuiSpacing))<{$error?: boolean}>`
    &[class*='MuiIconButton-root'] {
        ${(props): string => props.$error ? `color: ${props.theme.palette.error900.main};` : ''}
    }
`

export const ServiceTypography = styled(styled(MuiTypography)(MuiSpacing))<{
    $fontSize?: number,
    $fontWeight?: number,
    $error?: boolean,
    $selectedColor?: string
}>`
    font-size: ${(props): number => props.$fontSize ?? 12}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    line-height: 1.2rem;
    ${(props): string => props.$error ? `color: ${props.theme.palette.error900.main};` : (props.$selectedColor ? `color: ${props.$selectedColor};` : '')}

`

export const FlexContainer = styled(styled.div(MuiSpacing))`
    display: flex;
    align-items: center;
`