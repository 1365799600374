import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { loadComponent } from '../helpers'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import { TextFieldAdornment } from '../../TextFieldAdornment/TextFieldAdornment.styled'
import { TextFieldIconSearch } from '../../TextFieldIcons/TextFieldIcons'
import { type CountryCode, formatIncompletePhoneNumber, parseDigits, getExampleNumber } from 'libphonenumber-js'

import examples from 'libphonenumber-js/examples.mobile.json'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (data?: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const InputForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {

    const { type,values, tooltip, labelName, placeholder, spaceForError, subLabel, disabled, width, required, size, phoneNumber, InputProps } =
        field

    const PhoneNumber = phoneNumber?.userCountry ? getExampleNumber(phoneNumber?.userCountry as CountryCode, examples) : ''
    const PhoneNumberSample = PhoneNumber
        ? formatIncompletePhoneNumber(`${PhoneNumber?.countryCallingCode} ${PhoneNumber?.nationalNumber}`, phoneNumber?.userCountry)
        : null
    const DynamicComponent: any = loadComponent(type)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(name ? name : field.name, field.values)
        debouncedHandleOnChange?.({[field.name]: field.values})
    }, [field.values])

   
    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field }) => {
                const fieldDate = field
                const errorField = error ? error : errors?.[field.name]
                const helperTextField = helperText ? helperText : errors?.[field.name]?.message ?? spaceForError ?? ' '
                const dynamicSubLabel = subLabel ? { subLabel: subLabel } : {}
                return (
                    <DynamicComponent
                        {...field}
                        {...dynamicSubLabel}
                        disabled={disabled}
                        variant={
                            type === 'textOutlined' || phoneNumber?.type === 'textOutlined' || type === 'textSearchBar'
                                ? 'outlined'
                                : 'standard'
                        }
                        required={required ? true : false}
                        width={'100%'}
                        type={type === 'hiddenText' ? 'hidden' : 'text'}
                        label={labelName}
                        fullWidth
                        tooltip={tooltip}
                        placeholder={placeholder ? placeholder : PhoneNumberSample}
                        error={!!errorField}
                        helperText={helperTextField}
                        size={size || 'small'}
                        value={
                            phoneNumber?.userCountry
                                ? formatIncompletePhoneNumber(fieldDate.value, phoneNumber?.userCountry)
                                : fieldDate.value || ''
                        }
                        InputProps={
                            type === 'textSearchBar'
                                ? {
                                      startAdornment: (
                                          <TextFieldAdornment position='start'>
                                              <TextFieldIconSearch />
                                          </TextFieldAdornment>
                                      ),
                                  }
                                : type === 'phoneNumberField'
                                  ? {
                                          inputProps: { maxLength: PhoneNumberSample?.toString().length }, // Assuming phone numbers max length is 15
                                      }
                                  : { inputProps: { maxLength: 300, ...InputProps } }
                        }
                        onKeyUp={(e: any) => {
                            const inputValue = phoneNumber?.userCountry ? parseDigits(e.target.value) : e.target.value

                            if (field.onChange) {
                                field.onChange(inputValue)
                                setValue(field.name, inputValue, {
                                    shouldValidate: false,
                                })
                                debouncedHandleOnChange?.({[field.name]: inputValue})
                            }

                            // Trigger validation on change
                            trigger(field.name).then(() => {
                                const isFieldValid = !errorField
                                if (isFieldValid && field.onChange) {
                                    field.onChange(inputValue)
                                    setValue(field.name, inputValue, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleOnChange?.({[field.name]: inputValue})
                                }
                            })
                        }}
                    />
                )
            }}
        />
    )
}

export default InputForm
