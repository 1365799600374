import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'

import { Control, Controller, FieldValues } from 'react-hook-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import KeyboardDatePicker from '../../DatePicker/KeyboardDatePicker'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './DateForm.i18n'
import type { FieldConfig } from '../FormSimple'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface DateFormProps {
    name: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value?: any) => void
    defaultValue: any
    errors?: any
    error?: any
    helperText?: any
    values: any
    label: string
}

const DateForm: React.FC<DateFormProps> = ({
    name,
    label,
    defaultValue,
    values,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    const { t } = useI18n(i18n)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(name, values ? moment.utc(values).hours(5).format('YYYY-MM-DD HH:mm:ss') : null)
        debouncedHandleOnChange?.({[name]: values ? moment.utc(values).hours(5).format('YYYY-MM-DD HH:mm:ss') : null})
    }, [values])

    return (
        <>
            <Controller
               
                name={name}
                defaultValue={defaultValue ? moment.utc(defaultValue).hours(5).format('YYYY-MM-DD HH:mm:ss') : null}
                control={control}
                render={({ field }) => {
                    const errorField = error ? error : errors?.[field.name]

                    const helperTextField = helperText ? helperText : errors?.[field.name]?.message
                    return (
                        <>
                        <KeyboardDatePicker
                            {...field}
                            key={`KeyboardDatePicker ${name} ${label}`}
                            fullWidth
                            autoOk
                            format='dd-MM-yyyy'
                            placeholder='DD-MM-YYYY'
                            InputLabelProps={{ shrink: true }}
                            error={!!errorField}
                            helperText={helperTextField}
                            margin='dense'
                            label={<Typography variant='h6'>{label}</Typography>}
                            size='small'
                            keyboardIcon={<FontAwesomeIcon icon={['far', 'calendar-day']} className='calendarAndClock' />}
                            value={field.value || null}
                            onChange={(date: any) => {
                                const DatePicker = date ? moment.utc(date).hours(5).format('YYYY-MM-DD HH:mm:ss') : null
                                setValue(field.name, DatePicker, { shouldValidate: false })
                                debouncedHandleOnChange?.({[field.name]: DatePicker})
                                trigger(field.name).then(() => {
                                    setValue(field.name, DatePicker, { shouldValidate: false })
                                    field.onChange(DatePicker)
                                    const isFieldValid = !errorField
                                    if (isFieldValid) {
                                        debouncedHandleOnChange({[field.name]: DatePicker})
                                    }
                                })
                            }}
                        />
                        </>
                    )
                }}
            />
            {/* {errors[field.name] && (
                <Typography variant='caption' color='error'>
                    {errors[field.name]?.message}
                </Typography>
            )} */}
        </>
    )
}

export default DateForm
