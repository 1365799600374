import { 
    Typography, 
    TypographyProps, 
    Grid as MuiGrid 
} from "@material-ui/core";
import { spacing as MuiSpacing } from '@material-ui/system'
import styled from "styled-components";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { ButtonCardCheckboxProps, ButtonCardCheckboxColor } from "./GroupButton";
import StyledSelectionCard from '@/app/ui-new/components/SelectionCard/SelectionCardV3'

export type StyledTypographyProps = TypographyProps & {fontSize?: string, fontWeight?: number}

export const StyledSubTitleTypography: React.FC<StyledTypographyProps> = styled(Typography)<StyledTypographyProps>`
    font-size: ${(props):string => props.fontSize ?? '1.125rem'};
    font-weight: ${(props):number => props.fontWeight ?? 700};
    height: 21px;
`

const getBackgroundColor = (props: any, type: 'light' | 'dark') => {
    switch (props.bcolor) {
        case ButtonCardCheckboxColor.GRAY:
            return props.theme.palette.neutral100.main;
        case ButtonCardCheckboxColor.PURPLE:
            return type === 'dark' ? '#6512de' : '#6512decc';
        case ButtonCardCheckboxColor.GREEN:
            return type === 'dark' ? props.theme.palette.primary.main : props.theme.palette.accentPrimary700.main
        default: return 'red'
    }
}

const getFontColor = (props: any) => {
    switch (props.bcolor) {
        case ButtonCardCheckboxColor.GRAY:
            return props.theme.palette.primary.main;
        default: return props.theme.palette.white.main;
    }
}

export const BasicButton = styled(PrimaryButton)<{bcolor?: ButtonCardCheckboxProps['color']}>`
    background-color: ${(props): string => getBackgroundColor(props, 'dark')};
    color: ${(props): string => getFontColor(props)};
    text-wrap: white-space;
    padding: 10px;
    margin: 0;
    height: auto;
    
    &:hover {
        background-color: ${(props): string => getBackgroundColor(props, 'light')};
    }
`

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``